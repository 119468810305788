import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAlert } from '../hooks/useAlert';
import { fetchAuthSession } from 'aws-amplify/auth';
import { formatFileSize, formatNbQuestions, formatDate, customReactModalStyles } from '../utils';
// UI
import { Flex, useBreakpointValue, BadgeVariations, Breadcrumbs, SelectField, TextField, Menu, MenuButton, MenuItem } from '@aws-amplify/ui-react';
import { Collection, Card, Heading, Text, Button, Placeholder } from '@aws-amplify/ui-react';
// components
import Page from '../components/Page';
import { StorageManager } from '@aws-amplify/ui-react-storage';
import Modal from 'react-modal';
import { studioTheme } from '../ui-components';
import { ThemeProvider } from '@aws-amplify/ui-react';
import FileListRow from '../ui-components/FileListRow';
import MyIcon from '../ui-components/MyIcon';
// GraphQL imports
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import * as subscriptions from '../graphql/subscriptions';
import { GraphQLQuery, GraphQLSubscription } from '@aws-amplify/api';
import { generateClient } from 'aws-amplify/api';
import { ListRfpFilesQuery, DeleteFileQuery, ResponsePresignedGetQuery, ListStrategiesQuery, Strategy, ProcessNewDocumentQuery, UpdateStrategyInput, UpdateStrategyMutation, OnCreateRfpFileSubscription, OnUpdateRfpFileSubscription, QueryDeleteStrategyQuery } from '../API';


export default function Documents() {
  const snackbar = useAlert({ variation: 'success' });
  const navigate = useNavigate();
  const [files, setFiles] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>("");
  const [selectedStrategy, setSelectedStrategy] = useState<string>("");
  const [listStrategy, setListStrategy] = useState<any>(null);
  const [strategyName, setStrategyName] = useState<string>("");
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
  }) as 'small' | 'medium' | undefined;
  const [modalConfirmatonIsOpen, setModalConfirmatonIsOpen] = useState(false);
  const [modalAddDocIsOpen, setModalAddDocIsOpen] = useState(false);
  const [modalAddStrategyIsOpen, setModalAddStrategyIsOpen] = useState(false);
  const [modalUpdateStrategyIsOpen, setModalUpdateStrategyIsOpen] = useState(false);
  const [modalDeleteStrategyIsOpen, setModalDeleteStrategyIsOpen] = useState(false);
  const [addFileIsLoading, setAddFileIsLoading] = useState(false);
  const [addStrategyIsLoading, setAddStrategyIsLoading] = useState(false);
  const [updateStrategyIsLoading, setUpdateStrategyIsLoading] = useState(false);
  const [uploads, setUploads] = useState<any>({});
  const [dateDocument, setDateDocument] = useState<any>(null);
  const API = generateClient();

  useEffect(() => {
    async function fetchFiles() {
      try {

        // Fetch data from the amplify graphql API table Strategy
        const allStrategy = await API.graphql<GraphQLQuery<ListStrategiesQuery>>(
          { query: queries.listStrategies }
        );

        setListStrategy(allStrategy.data?.listStrategies?.items)

        if (allStrategy.data?.listStrategies?.items !== null && allStrategy.data?.listStrategies?.items !== undefined) {
          if (allStrategy.data?.listStrategies?.items[0]?.id) {
            setSelectedStrategy(allStrategy.data?.listStrategies?.items[0].id);
          }
        }

        // Fetch data from the amplify graphql API table RfpFile
        const allFiles = await API.graphql<GraphQLQuery<ListRfpFilesQuery>>(
          { query: queries.listRfpFiles }
        );
        setFiles(allFiles.data?.listRfpFiles?.items);


        // Subscribe to creation of File
        API.graphql<GraphQLSubscription<OnCreateRfpFileSubscription>>(
          { query: subscriptions.onCreateRfpFile }
        ).subscribe({
          next: () => refreshFiles(),
          error: (error) => console.warn(error)
        });

        // Subscribe to update of File
        API.graphql<GraphQLSubscription<OnUpdateRfpFileSubscription>>(
          { query: subscriptions.onUpdateRfpFile }
        ).subscribe({
          next: () => refreshFiles(),
          error: (error) => console.warn(error)
        });

      } catch (error) {
        console.error('Error fetching document data:', error);
      }
    }

    fetchFiles();
  }, []);

  function handleSelectChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setSelectedStrategy(event.target.value);
  }

  async function refreshCategories() {
    try {
      // Fetch data from the amplify graphql API table Strategy
      const allStrategy = await API.graphql<GraphQLQuery<ListStrategiesQuery>>(
        { query: queries.listStrategies }
      );
      setListStrategy(allStrategy.data?.listStrategies?.items)
    } catch (error) {
      console.error('Error fetching categories data:', error);
    }
  }


  async function refreshFiles() {
    try {
      const allFiles = await API.graphql<GraphQLQuery<ListRfpFilesQuery>>(
        { query: queries.listRfpFiles }
      );
      setFiles(allFiles.data?.listRfpFiles?.items);
    } catch (error) {
      console.error('Error fetching document data:', error);
    }
  }


  function navigateToFile(filePath: string): void {
    navigate(`/documents/${filePath}`);
  }


  function deleteFileConfirmation(rfpId: string) {
    setDeleteId(rfpId);
    setModalConfirmatonIsOpen(true);
  }


  async function deleteFile() {
    if (deleteId === "") {
      return;
    }
    setIsLoading(true);
    await API.graphql<GraphQLQuery<DeleteFileQuery>>(
      { query: queries.deleteFile, variables: { rfpId: deleteId } }
    );
    // Fetch data from the amplify graphql API table RfpFile
    const allFiles = await API.graphql<GraphQLQuery<ListRfpFilesQuery>>(
      { query: queries.listRfpFiles }
    );
    setFiles(allFiles.data?.listRfpFiles?.items);
    setIsLoading(false);
  }


  //#######################################################################################################################
  //## MODAL CONFIRMATION
  //#######################################################################################################################
  function closeModalConfirmation() {
    setModalConfirmatonIsOpen(false);
  }
  function getModalConfirmation(modalBool: boolean, callBackFunction: Function) {
    Modal.setAppElement('#root');
    return (
      <Modal
        isOpen={modalBool}
        style={customReactModalStyles}
      >
        <ThemeProvider theme={studioTheme}>
          <Flex direction={"column"} gap="20px">
            <Heading >Are you sure you want to continue?</Heading>
            <Flex direction={"row"} alignItems="space-between">
              <Button onClick={() => { callBackFunction(); closeModalConfirmation(); }}>Yes</Button><Button onClick={() => closeModalConfirmation()}>No</Button>
            </Flex>
          </Flex>
        </ThemeProvider>
      </Modal>);
  }

  //#######################################################################################################################
  //## MODAL Add/Update Strategy
  //#######################################################################################################################
  async function handleNewDocSubmit() {
    try {
      if (Object.keys(uploads)[0] === null || Object.keys(uploads)[0] === undefined) {
        console.log("No file uploaded");
        return;
      }
      if (selectedStrategy === "" || selectedStrategy === null || selectedStrategy === undefined) {
        console.log("No strategy selected");
        return;
      }
      if (dateDocument === "" || dateDocument === null || dateDocument === undefined) {
        console.log("No date selected");
        return;
      }
      const user = await fetchAuthSession();
      await API.graphql<GraphQLQuery<ProcessNewDocumentQuery>>(
        { query: queries.processNewDocument, variables: { key: `${user.identityId}/${Object.keys(uploads)[0]}`, strategy: selectedStrategy, dateDocument: dateDocument } }
      );
      refreshFiles();
      setModalAddDocIsOpen(false);
      snackbar.showAlert('Document currently being processed. List refreshed automatically.', 'success');
    } catch (error) {
      console.error('Error submitting document data:', error);
      snackbar.showAlert(`Error submitting document: ${error}`, 'error');
    }
  }

  function getModalAddDoc() {
    Modal.setAppElement('#root');
    return (
      <Modal
        isOpen={modalAddDocIsOpen}
        style={customReactModalStyles}
      >
        <ThemeProvider theme={studioTheme}>
          <Flex as="form" direction={{ base: 'column', medium: 'column' }} >
            <Heading level={4}>Add a new document</Heading>
            <Flex direction={{ base: 'column' }} >
              <Text>
                Only upload word documents (.docx). The document will be processed and the questions will be extracted based of the heading structure.
              </Text>
              <SelectField
                isRequired={true}
                label="Select a Category"
                value={selectedStrategy}
                onChange={handleSelectChange}
              >
                {listStrategy?.map((strategy: Strategy, index: number) => (
                  <option key={index} value={strategy.id}>{strategy.name}</option>
                ))}
              </SelectField>
              <TextField
                isRequired={true}
                label="Document's date"
                errorMessage="There is an error"
                type="date"
                value={dateDocument}
                onChange={(e) => { setDateDocument(e.target.value) }}
              />
            </Flex>

            <StorageManager
              acceptedFileTypes={['.pdf', '.docx']}
              accessLevel="protected"
              maxFileCount={1}
              onUploadError={(error, { key }) => {
                console.log("error upload:" + error);
              }}          
              onUploadSuccess={({ key }) => {
                if (key === null || key === undefined) {
                  return;
                }                
                setUploads((prevFiles: any) => {
                  return {
                    ...prevFiles,
                    [key]: {
                      status: 'success',
                    }
                  }
                });
              }}
            />

            <Flex direction={"row"} justifyContent="flex-end">
              <Button isLoading={addFileIsLoading} isDisabled={addFileIsLoading} variation="primary" onClick={() => { setAddFileIsLoading(true); handleNewDocSubmit(); setAddFileIsLoading(false); }}>Update</Button>
              <Button isDisabled={addFileIsLoading} variation="link" onClick={() => setModalAddDocIsOpen(false)}>cancel</Button>
            </Flex>
          </Flex>

        </ThemeProvider>
      </Modal>);
  }


  async function addStrategy() {
    try {
      if (strategyName === "" || strategyName === null || strategyName === undefined) {
        return;
      }
      await API.graphql({
        query: mutations.createStrategy, variables:{ input: { name: strategyName } } });      
      refreshCategories();
    } catch (error) {
      console.error('Error fetching categories data:', error);
    }
  }


  function getModalAddStrategy() {
    Modal.setAppElement('#root');
    return (
      <Modal
        isOpen={modalAddStrategyIsOpen}
        style={customReactModalStyles}
      >
        <ThemeProvider theme={studioTheme}>
          <Flex as="form" direction={{ base: 'column', medium: 'column' }} >
            <Heading level={4}>Add a new Category</Heading>
            <Flex direction={{ base: 'column' }} >
              <TextField
                isRequired={true}
                label="Category name"
                errorMessage="There is an error"
                onChange={(e) => setStrategyName(e.target.value)}
              />
            </Flex>
            <Flex direction={"row"} justifyContent="flex-end">
              <Button isLoading={addStrategyIsLoading} isDisabled={addStrategyIsLoading} variation="primary" onClick={() => { setAddStrategyIsLoading(true); addStrategy(); setModalAddStrategyIsOpen(false); setAddStrategyIsLoading(false); }}>Add</Button>
              <Button isDisabled={addStrategyIsLoading} variation="link" onClick={() => setModalAddStrategyIsOpen(false)}>cancel</Button>
            </Flex>
          </Flex>
        </ThemeProvider>
      </Modal>);
  }


  async function updateStrategy() {
    try {
      if (strategyName === "" || strategyName === null || strategyName === undefined
        || selectedStrategy === "" || selectedStrategy === null || selectedStrategy === undefined) {
        return;
      }

      // Search for the strategy selectStrategy in listStrategy
      const strategyToUpdate = listStrategy?.find((strategy: Strategy) => strategy.id === selectedStrategy);
      if (strategyToUpdate === null || strategyToUpdate === undefined) {
        return;
      }
      const updateStrat: UpdateStrategyInput = { id: selectedStrategy, name: strategyName, _version: strategyToUpdate._version }
      await API.graphql<GraphQLQuery<UpdateStrategyMutation>>({
        query: mutations.updateStrategy,
        variables: { input: updateStrat }
      });
      refreshCategories();
    } catch (error) {
      console.error('Error updating categories data:', error);
    }
  }

  function getModalUpdateStrategy() {
    Modal.setAppElement('#root');
    return (
      <Modal
        isOpen={modalUpdateStrategyIsOpen}
        style={customReactModalStyles}
      >
        <ThemeProvider theme={studioTheme}>
          <Flex as="form" direction={{ base: 'column', medium: 'column' }} >
            <Heading level={4}>Update Category</Heading>
            <Flex direction={{ base: 'column' }}  >
              <SelectField
                isRequired={true}
                label="Select a Category"
                value={selectedStrategy}
                onChange={handleSelectChange}
              >
                {listStrategy?.map((strategy: Strategy, index: number) => (
                  <option key={index} value={strategy.id}>{strategy.name}</option>
                ))}
              </SelectField>
              <TextField
                isRequired={true}
                label="Type new name"
                errorMessage="There is an error"
                onChange={(e) => setStrategyName(e.target.value)}
              />
            </Flex>
            <Flex direction={"row"} justifyContent="flex-end">
              <Button isLoading={updateStrategyIsLoading} isDisabled={updateStrategyIsLoading} variation="primary" onClick={() => { setUpdateStrategyIsLoading(true); updateStrategy(); setModalUpdateStrategyIsOpen(false); setUpdateStrategyIsLoading(false); }}>Update</Button>
              <Button isDisabled={updateStrategyIsLoading} variation="link" onClick={() => setModalUpdateStrategyIsOpen(false)}>cancel</Button>
            </Flex>
          </Flex>
        </ThemeProvider>
      </Modal>);
  }

  async function deleteStrategy() {
    try {
      if (selectedStrategy === "" || selectedStrategy === null || selectedStrategy === undefined) {
        return;
      }
      // Search for the strategy selectStrategy in listStrategy
      const strategyToUpdate = listStrategy?.find((strategy: Strategy) => strategy.id === selectedStrategy);
      if (strategyToUpdate === null || strategyToUpdate === undefined) {
        return;
      }
      const response = await API.graphql<GraphQLQuery<QueryDeleteStrategyQuery>>(
        { query: queries.queryDeleteStrategy, variables: { strategyId: selectedStrategy } }
      );
      if (response.data?.queryDeleteStrategy) {
        if (response.data?.queryDeleteStrategy.deleted === true && response.data?.queryDeleteStrategy.result !== null && response.data?.queryDeleteStrategy.result !== undefined) {
          snackbar.showAlert(response.data?.queryDeleteStrategy.result, 'success');
        } else if (response.data?.queryDeleteStrategy.result !== null && response.data?.queryDeleteStrategy.result !== undefined) {
          snackbar.showAlert(response.data?.queryDeleteStrategy.result, 'error');
        } else {
          snackbar.showAlert("Error while trying to delete the category", 'error');
        }
      }
      refreshCategories();
    } catch (error) {
      console.error('Error updating categories data:', error);
    }
  }

  function getModalDeleteStrategy() {
    Modal.setAppElement('#root');
    return (
      <Modal
        isOpen={modalDeleteStrategyIsOpen}
        style={customReactModalStyles}
      >
        <ThemeProvider theme={studioTheme}>
          <Flex as="form" direction={{ base: 'column', medium: 'column' }} >
            <Heading level={4}>Delete Category</Heading>
            <Flex direction={{ base: 'column' }}  >
              <SelectField
                isRequired={true}
                label="Select a Category"
                value={selectedStrategy}
                onChange={handleSelectChange}
              >
                {listStrategy?.map((strategy: Strategy, index: number) => (
                  <option key={index} value={strategy.id}>{strategy.name}</option>
                ))}
              </SelectField>
            </Flex>
            <Flex direction={"row"} justifyContent="flex-end">
              <Button isLoading={updateStrategyIsLoading} isDisabled={updateStrategyIsLoading} variation="primary" onClick={() => { setUpdateStrategyIsLoading(true); deleteStrategy(); setModalDeleteStrategyIsOpen(false); setUpdateStrategyIsLoading(false); }}>Delete</Button>
              <Button isDisabled={updateStrategyIsLoading} variation="link" onClick={() => setModalDeleteStrategyIsOpen(false)}>cancel</Button>
            </Flex>
          </Flex>
        </ThemeProvider>
      </Modal>);
  }

  function getStatusVariation(status: string): BadgeVariations {
    switch (status) {
      case "PROCESSING":
        return "warning";
      case "PROCESSED":
        return "success";
      case "EXTRACTED":
        return "success";
        default:
        return "warning";
    }
  }

  async function DownloadFile(s3Key: string | undefined | null) {
    console.log(s3Key);
    if (s3Key === null || s3Key === undefined) {
      return;
    }
    const link = await API.graphql<GraphQLQuery<ResponsePresignedGetQuery>>(
      { query: queries.responsePresignedGet, variables: { objectName: s3Key } }
    );
    if (link?.data?.responsePresignedGet !== null && link?.data?.responsePresignedGet !== undefined) {
      window.location.href = link?.data?.responsePresignedGet;
    }
  }


  return (
    <Page title="Documents">
      <snackbar.AlertComponent />
      {getModalConfirmation(modalConfirmatonIsOpen, deleteFile)}
      {getModalAddDoc()}
      {getModalAddStrategy()}
      {getModalUpdateStrategy()}
      {getModalDeleteStrategy()}
      <Flex gap="20px" direction={"column"}>
        <Breadcrumbs
          items={[
            {
              href: '/',
              label: 'Home',
            },
            {
              href: '/documents',
              label: 'Documents',
            }
          ]}
        />
        <Card>
          <Flex direction="column" alignItems="flex-start">
            <Heading level={4}>RFP Documents</Heading>
            <Text
              variation="primary"
              as="p"
            >
              Add or delete RFP documents from your knowledge database. Select a document below to view or edit related RFP questions.
            </Text>
            <Flex direction="row" alignItems="flex-start">
              <Button isLoading={isLoading} isDisabled={isLoading} variation="primary" onClick={() => { setUploads({}); setModalAddDocIsOpen(true) }}>Add Document</Button>
              <Menu
                trigger={
                  <MenuButton variation="link">
                    Category
                  </MenuButton>
                }
              >
                <MenuItem onClick={() => setModalAddStrategyIsOpen(true)}>Add</MenuItem>
                <MenuItem onClick={() => setModalUpdateStrategyIsOpen(true)}>Update</MenuItem>
                <MenuItem onClick={() => setModalDeleteStrategyIsOpen(true)}>Delete</MenuItem>
              </Menu>
            </Flex>
          </Flex>
        </Card>
        <Flex justifyContent="center" alignItems="center" width="100%">
          <Card width={"100%"}>
            {files !== null ? (
              <Collection
                items={files}
                type="list"
                direction="column"
                gap="7px"
                wrap="wrap"
                isPaginated
                itemsPerPage={12}
                isSearchable
                searchPlaceholder="Type to search..."
                searchFilter={(regions, keyword) =>
                  (regions as any).name.toLowerCase().includes(keyword.toLowerCase())
                }
              >
                {(item: any, index: number) => (
                  < FileListRow
                    key={index}
                    breakpoint={breakpointHook}
                    overrides={
                      {
                        FileListRow: {
                          width: "100%"
                        },
                        "FrameFile": { "minWidth": "50%" },
                        "FileText": { "children": `${listStrategy.find((s: { id: string }) => s.id === item.rfpFileStrategyId).name} | ${item.name}`, "onClick": () => navigateToFile(item.id) },
                        "TypeText": { "children": item.type },
                        "StatusBadge": { "children": item.status, "variation": getStatusVariation(item.status) },
                        "SizeText": { "children": formatFileSize(item.size) },
                        "NbQuestionsText": { "children": formatNbQuestions(item.nbQuestions) },
                        "UpdatedText": { "children": formatDate(item.updatedAt) },
                        "FrameButton": {
                          children: (
                            <Menu
                              trigger={
                                <MenuButton variation="link" size="small" padding={0}>
                                  <MyIcon type={"more_vert"} />
                                </MenuButton>
                              }
                            >
                              <MenuItem isDisabled={isLoading} onClick={() => navigateToFile(item.id)}><MyIcon type={"edit"} overrides={{ MyIcon: { color: "white" } }} />View/Edit</MenuItem>
                              <MenuItem isDisabled={isLoading} onClick={() => deleteFileConfirmation(item.id)}><MyIcon type={"delete"} />Delete</MenuItem>
                            </Menu>
                          )
                        },
                        "DownloadIcon": { className: "custom-btn", "onClick": () => DownloadFile(item.s3Key) }
                      }
                    } />
                )}
              </Collection>
            ) : (
              <Placeholder size="large" height={"200px"} />
            )}
          </Card>
        </Flex>
      </Flex >
    </Page >
  );
}
