// import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
// UI
import { Flex} from '@aws-amplify/ui-react';
// components
import Page from '../components/Page';
import NotFoundCard from '../ui-components/NotFoundCard';

export default function Page404() {
  const navigate = useNavigate();  
  return (
    <Page title="404 Page Not Found">
          <Flex justifyContent="center" alignItems="center">
            <NotFoundCard overrides={{ Illustration:  {src: "/Illustration.png" }, Button38802528: { onClick: ()=> navigate("/") }}}  />
          </Flex>
    </Page>
  );
}
