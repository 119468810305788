import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { useAlert } from '../hooks/useAlert';
import { useParams } from 'react-router-dom';
import { Flex, View, Collection, Card, Text, Heading, Button, ButtonGroup, TextAreaField, Breadcrumbs, Badge, Placeholder, Alert, Accordion } from '@aws-amplify/ui-react';
import Page from '../components/Page';
import { formatFileSize, formatNbQuestions, formatDateISO, copyToClipboard, getIsExcludedText, getIsExcludedVariation, customReactModalStyles, editorKey, tinymceConfig, convertResponseToHtml, convertImagePathBeforeSaving } from '../utils';
import RfpItemLayout from '../ui-components/RfpItemLayout';
import Number from '../ui-components/Number';
import Modal from 'react-modal';
import { studioTheme } from '../ui-components';
import { ThemeProvider } from '@aws-amplify/ui-react';
// Html processing
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import parse from 'html-react-parser';
// GraphQL imports
import { generateClient } from 'aws-amplify/api';

import * as queries from '../graphql/queries';
import { GraphQLQuery } from '@aws-amplify/api';
import { CheckQuestionForSimilarityQuery, GetRfpFileQuery, DeleteFileQuery, UpdateQuestionKnowledgeBaseQuery, DeleteQuestionQuery, RemoveQuestionKnowledgeBaseQuery, ResponsePresignedGetQuery, AddBackQuestionKnowledgeBaseQuery, AddQuestionKnowledgeBaseQuery, ListRfpFileItemsQuery } from '../API';
import DownloadIcon from '../ui-components/DownloadIcon';
import MyIcon from '../ui-components/MyIcon';
import MyIconSet from '../ui-components/MyIconSet';


export default function Document() {
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const alert = useAlert({ variation: 'error' });
  const snackbar = useAlert({ variation: 'success' });
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [documentData, setDocumentData] = useState<any>(null);
  const [rfpItems, setRfpItems] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalConfirmatonDeleteIsOpen, setModalConfirmatonDeleteIsOpen] = useState(false);
  const [modalConfirmatonRemoveIsOpen, setModalConfirmatonRemoveIsOpen] = useState(false);
  const [modalFileDeleteConfirmatonIsOpen, setModalFileDeleteConfirmatonIsOpen] = useState(false);
  const [modalConfirmatonAddIsOpen, setModalConfirmatonAddIsOpen] = useState(false);
  const [modalConfirmatonEditIsOpen, setModalConfirmatonEditIsOpen] = useState(false);
  const [deleteQuestionId, setDeleteQuestionId] = useState<string>("");
  const [removeQuestionId, setRemoveQuestionId] = useState<string>("");
  const [removeQuestionIsExcluded, setRemoveQuestionIsExcluded] = useState<boolean>(false);
  const [editQuestionId, setEditQuestionId] = useState<string>("");
  const [questionValue, setQuestionValue] = useState<string>("");
  const [answerValue, setAnswerValue] = useState<string>("");
  const [rfpItemsLoading, setRfpItemsLoading] = useState<any>(null);
  const API = generateClient();

  useEffect(() => {
    async function fetchAllRfpItems(id: string, nextToken: string | null = null): Promise<any> {
      let result = await API.graphql<GraphQLQuery<ListRfpFileItemsQuery>>({
        query: queries.listRfpFileItems, variables: {
          filter: {
            rfpFileId: {
              eq: id
            }
          },
          nextToken
        }
      });

      if (result?.data?.listRfpFileItems?.nextToken) {
        const nextResult = await fetchAllRfpItems(id, result.data.listRfpFileItems.nextToken);
        // Combine result and nextResult
        if (nextResult.data.listRfpFileItems.items) {
          result.data.listRfpFileItems.items = result.data.listRfpFileItems.items.concat(nextResult.data.listRfpFileItems.items);
        }

      }

      return result;
    }
    async function fetchFiles() {
      try {
        // Fetch data from the amplify graphql API table RfpFile
        let file = await API.graphql<GraphQLQuery<GetRfpFileQuery>>(
          { query: queries.getRfpFile, variables: { id: id } }
        );
        setDocumentData(file.data?.getRfpFile);
        if (!id) {
          return null;
        }
        const rfpItems = await fetchAllRfpItems(id);

        let litems = rfpItems.data?.listRfpFileItems?.items;

        if (litems) {
          litems.sort((a: any, b: any) => {
            if (a !== null && b !== null) {
              if (a?.datetime > b?.datetime) {
                return 1;
              } else if (a?.datetime < b?.datetime) {
                return -1;
              } else {
                return 0;
              }
            }
            else {
              return 0;
            }
          });
        }
        const items = litems

        // record image with presigned url
        let presignedUrlDone: { [key: string]: string } = {};

        if (items) {
          const processItems = async () => {
            const promises = items.map(async (item: any) => {
              const newHtml = await convertResponseToHtml(item.answer, presignedUrlDone, []);
              item.answer = newHtml;
            });

            // Wait for all promises to resolve
            await Promise.all(promises);
          };

          // Call the async function
          processItems().then(() => {
            // All promises have resolved, you can continue with the next lines of code here
            setRfpItems(rfpItems.data?.listRfpFileItems?.items);

            if (rfpItemsLoading === null) {
              setRfpItemsLoading(rfpItems.data?.listRfpFileItems?.items?.map((item: any) => {
                return { id: item.id, isLoading: false };
              }));
            } else {
              setRfpItemsLoading(rfpItems.data?.listRfpFileItems?.items?.map((item: any) => {
                const existingItem = rfpItemsLoading.find((loadingItem: any) => loadingItem.id === item.id);
                return {
                  id: item.id,
                  isLoading: false,
                  similarities: existingItem ? existingItem.similarities : null
                };
              }));
            }
          });
        }

      } catch (error) {
        console.error('Error fetching document data:', error);
      }
    }

    fetchFiles();
  }, [id]);

  async function fetchAllRfpItems(id: string, nextToken: string | null = null): Promise<any> {
    let result = await API.graphql<GraphQLQuery<ListRfpFileItemsQuery>>({
      query: queries.listRfpFileItems, variables: {
        filter: {
          rfpFileId: {
            eq: id
          }
        },
        nextToken
      }
    });

    if (result?.data?.listRfpFileItems?.nextToken) {
      const nextResult = await fetchAllRfpItems(id, result.data.listRfpFileItems.nextToken);
      // Combine result and nextResult
      if (nextResult.data.listRfpFileItems.items) {
        result.data.listRfpFileItems.items = result.data.listRfpFileItems.items.concat(nextResult.data.listRfpFileItems.items);
      }

    }

    return result;
  }

  async function updatePage() {
    try {
      // Fetch data from the amplify graphql API table RfpFile
      let file = await API.graphql<GraphQLQuery<GetRfpFileQuery>>(
        { query: queries.getRfpFile, variables: { id: id } }
      );
      setDocumentData(file.data?.getRfpFile);
      if (!id) {
        return null;
      }
      const rfpItems = await fetchAllRfpItems(id);

      let litems = rfpItems.data?.listRfpFileItems?.items;

      if (litems) {
        litems.sort((a: any, b: any) => {
          if (a !== null && b !== null) {
            if (a?.datetime > b?.datetime) {
              return 1;
            } else if (a?.datetime < b?.datetime) {
              return -1;
            } else {
              return 0;
            }
          }
          else {
            return 0;
          }
        });
      }
      const items = litems


      if (items) {
        // record image with presigned url
        let presignedUrlDone: { [key: string]: string } = {};
        const processItems = async () => {
          const promises = items.map(async (item: any) => {
            const newHtml = await convertResponseToHtml(item.answer, presignedUrlDone, []);
            item.answer = newHtml;
          });
          // Wait for all promises to resolve
          await Promise.all(promises);
        };

        // Call the async function
        processItems().then(() => {
          // All promises have resolved, you can continue with the next lines of code here
          setRfpItems(rfpItems.data?.listRfpFileItems?.items);

          if (rfpItemsLoading === null) {
            setRfpItemsLoading(rfpItems.data?.listRfpFileItems?.items?.map((item: any) => {
              return { id: item.id, isLoading: false };
            }));
          } else {
            setRfpItemsLoading(rfpItems.data?.listRfpFileItems?.items?.map((item: any) => {
              const existingItem = rfpItemsLoading.find((loadingItem: any) => loadingItem.id === item.id);
              return {
                id: item.id,
                isLoading: false,
                similarities: existingItem ? existingItem.similarities : null
              };
            }));
          }

        });
      }

    } catch (error) {
      console.error('Error fetching document data:', error);
    }
  }

  //#######################################################################################################################
  async function DownloadFile() {
    setIsLoading(true);
    const link = await API.graphql<GraphQLQuery<ResponsePresignedGetQuery>>(
      { query: queries.responsePresignedGet, variables: { objectName: documentData?.s3Key } }
    );
    if (link?.data?.responsePresignedGet !== null && link?.data?.responsePresignedGet !== undefined) {
      window.location.href = link?.data?.responsePresignedGet;
    }
    setIsLoading(false);
  }



  function deleteFileConfirmation() {
    setModalFileDeleteConfirmatonIsOpen(true);
  }
  async function DeleteFile() {
    if (id === "") {
      return;
    }
    setIsLoading(true);
    await API.graphql<GraphQLQuery<DeleteFileQuery>>(
      { query: queries.deleteFile, variables: { rfpId: id } }
    );
    setIsLoading(false);
    navigate("/documents");
  }


  function addQuestionConfirmation() {
    setEditQuestionId("");
    setAnswerValue("");
    setQuestionValue("");
    setModalConfirmatonAddIsOpen(true);
  }

  async function addQuestion() {
    if (questionValue === "") {
      return;
    }
    if (editorRef.current) {
      setIsLoading(true);
      await API.graphql<GraphQLQuery<AddQuestionKnowledgeBaseQuery>>(
        {
          query: queries.addQuestionKnowledgeBase, variables: {
            rfpId: id,
            question: questionValue,
            answer: editorRef.current.getContent()
          }
        }
      );
      updatePage();
      setIsLoading(false);
    }
    else {
      console.error("No editorRef value");
      return;
    }
  }

  //#######################################################################################################################

  function editQuestionConfirmation(questionId: string, question: string, answer: string) {
    setEditQuestionId(questionId);
    setQuestionValue(question);
    setAnswerValue(answer);
    setModalConfirmatonEditIsOpen(true);
  }

  async function editQuestion() {
    if (editQuestionId === "") {
      return;
    }
    if (editorRef.current) {
      setIsLoading(true);
      const newHtml = convertImagePathBeforeSaving(editorRef.current.getContent());

      await API.graphql<GraphQLQuery<UpdateQuestionKnowledgeBaseQuery>>(
        {
          query: queries.updateQuestionKnowledgeBase, variables: {
            questionId: editQuestionId,
            question: questionValue,
            answer: newHtml
          }
        }
      );
      updatePage();
      setIsLoading(false);
    }
    else {
      console.error("No editorRef value");
      return;
    }
  }


  function deleteQuestionConfirmation(questionId: string) {
    setDeleteQuestionId(questionId);
    setModalConfirmatonDeleteIsOpen(true);
  }

  async function deleteQuestion() {
    if (deleteQuestionId === "") {
      return;
    }
    setIsLoading(true);
    await API.graphql<GraphQLQuery<DeleteQuestionQuery>>(
      {
        query: queries.deleteQuestion, variables: {
          questionId: deleteQuestionId
        }
      }
    );
    updatePage();
    setIsLoading(false);
  }

  function removeQuestionConfirmation(questionId: string, isExcluded: boolean) {
    setRemoveQuestionId(questionId);
    setRemoveQuestionIsExcluded(isExcluded);
    setModalConfirmatonRemoveIsOpen(true);
  }


  async function removeQuestionFromDB() {
    if (removeQuestionId === "") {
      return;
    }
    setIsLoading(true);
    if (removeQuestionIsExcluded) {
      await API.graphql<GraphQLQuery<AddBackQuestionKnowledgeBaseQuery>>(
        {
          query: queries.addBackQuestionKnowledgeBase, variables: {
            questionId: removeQuestionId
          }
        }
      );
    } else {
      await API.graphql<GraphQLQuery<RemoveQuestionKnowledgeBaseQuery>>(
        {
          query: queries.removeQuestionKnowledgeBase, variables: {
            questionId: removeQuestionId
          }
        }
      );
    }
    updatePage();
    setIsLoading(false);
  }


  //#######################################################################################################################
  function getModalConfirmation(modalBool: boolean, setModal: Function, callBackFunction: Function) {
    Modal.setAppElement('#root');

    return (
      <Modal
        isOpen={modalBool}
        style={customReactModalStyles}
      >
        <ThemeProvider theme={studioTheme}>
          <Flex direction={"column"} gap="20px">
            <Heading >Are you sure you want to continue?</Heading>
            <Flex direction={"row"} alignItems="space-between">
              <Button onClick={() => { callBackFunction(); setModal(false); }}>Yes</Button><Button onClick={() => setModal(false)}>No</Button>
            </Flex>
          </Flex>
        </ThemeProvider>
      </Modal>);
  }

  // SIMILARITY #######################################################################################################################
  function getSimilarityFrame(questionId: string) {
    // if not questionId key in questionId then return null
    if (!(rfpItemsLoading.find((rfpItem: any) => rfpItem.id === questionId).similarities)) {
      return (<View>
        <Button isLoading={rfpItemsLoading.find((rfpItem: any) => rfpItem.id === questionId).isLoading || false} variation='link' onClick={() => { checkSimilarity(questionId) }}> Check for Similar Questions</Button>
      </View>)
    }

    let jsxAlert = (<Alert width="100%" variation="info" hasIcon={true} heading="No significant similarities">
      <Button isLoading={rfpItemsLoading.find((rfpItem: any) => rfpItem.id === questionId).isLoading || false} variation='link' onClick={() => { checkSimilarity(questionId) }}>Check Again</Button>
    </Alert>);

    if (rfpItemsLoading.find((rfpItem: any) => rfpItem.id === questionId).similarities.maxScore > 0.7) {
      jsxAlert = (<Alert width="100%" variation="error" hasIcon={true} heading="Similar questions detected">
        <Button isLoading={rfpItemsLoading.find((rfpItem: any) => rfpItem.id === questionId).isLoading || false} variation='link' onClick={() => { checkSimilarity(questionId) }}>Check Again</Button>
      </Alert>)
    }

    return (<View width="100%">
      {jsxAlert}
      <Accordion.Container allowMultiple>
        <Accordion.Item value="1">
          <Accordion.Trigger>
            {rfpItemsLoading.find((rfpItem: any) => rfpItem.id === questionId).similarities.result}
            <Accordion.Icon />
          </Accordion.Trigger>
          <Accordion.Content>
            <Accordion.Container allowMultiple>
              {rfpItemsLoading.find((rfpItem: any) => rfpItem.id === questionId).similarities.documents.map((item: any, index: number) => (

                <Accordion.Item value={"exp" + index}>
                  <Accordion.Trigger>
                    <Flex direction="column" gap="0rem" >
                      <Flex justifyContent="space-between" alignItems="center">
                        <Text variation="secondary" as="span">
                          {`${item.question}`}
                        </Text>
                      </Flex>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Badge variation="info">{`${formatDateISO(item.datetime)}`}</Badge> <small>{`${item.rfpFile?.name ? item.rfpFile.name : ""}`}</small>
                      </Flex>
                    </Flex>
                    <Accordion.Icon />
                  </Accordion.Trigger>
                  <Accordion.Content>
                    < RfpItemLayout
                      key={index}
                      overrides={
                        {
                          RfpItemLayout: {
                            width: "100%"
                          },
                          ButtonFile: {
                            children: item.rfpFile?.name ? item.rfpFile.name : "File",
                            onClick: () => { navigate(`/documents/${item.rfpFileId}`); },
                          },
                          Question: { children: item.question },
                          Date: { children: formatDateISO(item.datetime) },
                          TextLayout: { children: (<View>{typeof item.answer === 'string' ? parse(item.answer) : ''}</View>) },
                          ButtonCopy: { // Copy to clipboard
                            onClick: () => copyToClipboard(item.question + "\n" + item.answer, snackbar, alert)
                          },
                          ButtonEdit: { // Edit
                            onClick: () => editQuestionConfirmation(item.id, item.question, item.answer), isLoading: isLoading
                          },
                          ButtonRemove: { // Update Knowledge base
                            children: item.isExcluded ? "Add to Search" : "Remove from Search",
                            onClick: () => removeQuestionConfirmation(item.id, item.isExcluded), isLoading: isLoading
                          },
                          // DELETE
                          ButtonDelete: { onClick: () => deleteQuestionConfirmation(item.id), isLoading: isLoading },

                          "StatusBadge": {
                            "children": getIsExcludedText(item.isExcluded),
                            "variation": getIsExcludedVariation(item.isExcluded)
                          }
                        }
                      }
                    />
                  </Accordion.Content>
                </Accordion.Item>
              ))}
            </Accordion.Container>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion.Container>
    </View>
    )
  }

  async function checkSimilarity(questionId: string) {
    let presignedUrlDone: { [key: string]: string } = {};

    rfpItemsLoading.find((rfpItem: any) => rfpItem.id === questionId).isLoading = true;
    setRfpItemsLoading([...rfpItemsLoading]);
    const similarities = await API.graphql<GraphQLQuery<CheckQuestionForSimilarityQuery>>(
      { query: queries.checkQuestionForSimilarity, variables: { questionId: questionId } }
    );
    // Add signed url if any image in document.
    if (similarities.data?.checkQuestionForSimilarity?.documents) {
      const promises = similarities.data?.checkQuestionForSimilarity?.documents.map(async (item: any) => {
        const newHtml = await convertResponseToHtml(item.answer, presignedUrlDone, []);
        item.answer = newHtml;
      });
      // Wait for all promises to resolve
      await Promise.all(promises);
    }
    // Record
    rfpItemsLoading.find((rfpItem: any) => rfpItem.id === questionId).similarities = similarities.data?.checkQuestionForSimilarity;
    rfpItemsLoading.find((rfpItem: any) => rfpItem.id === questionId).isLoading = false;
    setRfpItemsLoading([...rfpItemsLoading]);
    return 1
  }


  //#######################################################################################################################
  function getModalForm(text: string, modalBool: boolean, setModal: Function, callBackFunction: Function) {
    Modal.setAppElement('#root');

    return (
      <Modal
        isOpen={modalBool}
        style={customReactModalStyles}
      >
        <ThemeProvider theme={studioTheme}>
          <Flex direction={"column"} gap="20px" style={{ maxHeight: '90vh', overflowY: 'auto' }}>
            <Heading >{text}</Heading>
            <Flex direction={"column"} alignItems="space-between">
              <Text >Question</Text>
              <TextAreaField
                placeholder="..."
                label=""
                errorMessage="There is an error"
                rows={2}
                onChange={(event) => setQuestionValue(event.target.value)}
                defaultValue={questionValue}
              />
              <Flex className="test" direction={"column"}>
                <Text >Answer</Text>
                <Editor
                  id="editor-id"
                  onInit={(evt, editor) => editorRef.current = editor}
                  apiKey={editorKey}
                  init={tinymceConfig(editQuestionId, "processed")}
                  initialValue={answerValue}
                />
              </Flex>
            </Flex>
            <Flex direction={"row"} alignItems="space-between">
              <Button variation='primary' onClick={() => { callBackFunction(); setModal(false); }}>Save</Button><Button variation='link' onClick={() => setModal(false)}>Cancel</Button>
            </Flex>
          </Flex>
        </ThemeProvider>
      </Modal>);
  }


  return (
    <Page title="Document">

      <Flex gap="20px" direction={"column"}>
        {getModalConfirmation(modalFileDeleteConfirmatonIsOpen, setModalFileDeleteConfirmatonIsOpen, DeleteFile)}
        {getModalConfirmation(modalConfirmatonDeleteIsOpen, setModalConfirmatonDeleteIsOpen, deleteQuestion)}
        {getModalConfirmation(modalConfirmatonRemoveIsOpen, setModalConfirmatonRemoveIsOpen, removeQuestionFromDB)}
        {getModalForm("Edit Question", modalConfirmatonEditIsOpen, setModalConfirmatonEditIsOpen, editQuestion)}
        {getModalForm("Add a new question", modalConfirmatonAddIsOpen, setModalConfirmatonAddIsOpen, addQuestion)}
        <Breadcrumbs
          items={[
            {
              href: '/',
              label: 'Home',
            },
            {
              href: '/documents',
              label: 'Documents',
            },
            {
              href: '/documents/' + id,
              label: documentData?.name,
            }
          ]}
        />
        <snackbar.AlertComponent />
        <alert.AlertComponent />
        <Card>
          <Flex direction="column" alignItems="flex-start">
            <Heading level={4}>Document - {documentData?.name}</Heading>
            <Flex direction="row" alignItems="flex-start">
              <Badge >Category: {documentData?.strategy?.name}</Badge>
              <Badge >Date: {formatDateISO(documentData?.datetime)}</Badge>
            </Flex>
            <Text
              variation="primary"
              as="p"
            >
              Find below the list of questions extracted from this document.
            </Text>
          </Flex>
        </Card>
        <Flex direction={{ base: 'column', medium: 'row' }} alignItems="space-between">
          <Flex direction='row'  >
            <Number overrides={{ Title: { children: "Size" }, Text: { children: formatFileSize(documentData?.size) } }} />
            <Number overrides={{ Title: { children: "Questions" }, Text: { children: formatNbQuestions(documentData?.nbQuestions) } }} />
          </Flex>
          <Card >
            <Text >
              <ButtonGroup>
                <Button onClick={() => DownloadFile()} isLoading={isLoading}><DownloadIcon /> Download</Button>
                {/* <Button onClick={() => EditFile()} isLoading={isLoading}><MyIcon type="edit" /> Edit</Button>  */}
                <Button onClick={() => deleteFileConfirmation()} isLoading={isLoading} size="small" variation='link'><MyIcon type="delete" /> Delete</Button>
                <Button onClick={() => addQuestionConfirmation()} isLoading={isLoading} size="small" variation='link'><MyIconSet type="plus" /> Question</Button>
              </ButtonGroup>
            </Text>
          </Card>
        </Flex>
        <Flex justifyContent="center" alignItems="center" width="100%">
          <Card width={"100%"}>
            {rfpItems !== null ? (
              <Collection

                items={rfpItems}
                type="list"
                direction="column"
                gap="20px"
                wrap="wrap"
                isPaginated
                itemsPerPage={12}
                isSearchable
                searchPlaceholder="Type to search..."
                searchFilter={(regions, keyword) =>
                  (regions as any).question.toLowerCase().includes(keyword.toLowerCase()) || (regions as any).answer.toLowerCase().includes(keyword.toLowerCase())
                }
              >
                {(item: any, index: number) => (
                  < RfpItemLayout
                    key={index}
                    overrides={
                      {
                        ButtonFile: {
                          style: { display: "none" }
                        },
                        RfpItemLayout: {
                          width: "100%"
                        },
                        Question: { children: item.question },
                        Date: { children: formatDateISO(item.datetime) },
                        TextLayout: { children: (<View>{typeof item.answer === 'string' ? parse(item.answer) : ''}</View>) },
                        ButtonCopy: { // Copy to clipboard
                          onClick: () => copyToClipboard(item.question + "\n" + item.answer, snackbar, alert)
                        },
                        ButtonEdit: { // Edit
                          onClick: () => editQuestionConfirmation(item.id, item.question, item.answer), isLoading: isLoading
                        },
                        ButtonRemove: { // Update Knowledge base
                          children: item.isExcluded ? "Add to Search" : "Remove from Search",
                          onClick: () => removeQuestionConfirmation(item.id, item.isExcluded), isLoading: isLoading
                        },
                        // DELETE
                        ButtonDelete: { onClick: () => deleteQuestionConfirmation(item.id), isLoading: isLoading },

                        "StatusBadge": {
                          "children": getIsExcludedText(item.isExcluded),
                          "variation": getIsExcludedVariation(item.isExcluded)
                        },
                        // CHECK SIMILARITY
                        "FrameSimilarity": { children: getSimilarityFrame(item.id) },
                      }
                    }
                  />
                )}
              </Collection>
            ) : (
              <Placeholder size="large" height={"200px"} />
            )}
          </Card>
        </Flex>
      </Flex>
    </Page>
  );
}
