// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const MessageType = {
  "INCOMING": "INCOMING",
  "OUTGOING": "OUTGOING"
};

const { MessageAvatar, RfpFile, RfpFileItem, Strategy, FaqItem, UserQuestion, UserQuestionCompliance, RfpToAnswerFile, RfpToAnswerFileItem, IdCheckResult, ResponseDocuments, ResponseWarning, RfpFileNameType, ResponseDocumentsScore, ResponseSType, ResponseQComplianceType, ResponseQSearchType, ResponseQueryDeleteStrategy, ResponseNewImage, ResponseHtmlType, ResponseProcessType } = initSchema(schema);

export {
  MessageAvatar,
  RfpFile,
  RfpFileItem,
  Strategy,
  FaqItem,
  UserQuestion,
  UserQuestionCompliance,
  RfpToAnswerFile,
  RfpToAnswerFileItem,
  MessageType,
  IdCheckResult,
  ResponseDocuments,
  ResponseWarning,
  RfpFileNameType,
  ResponseDocumentsScore,
  ResponseSType,
  ResponseQComplianceType,
  ResponseQSearchType,
  ResponseQueryDeleteStrategy,
  ResponseNewImage,
  ResponseHtmlType,
  ResponseProcessType
};