import { BadgeVariations } from "@aws-amplify/ui-react";
import axios from 'axios';
import { generateClient } from 'aws-amplify/api';
import * as queries from './graphql/queries';
import { GraphQLQuery } from '@aws-amplify/api';
import { ResponsePresignedPutQuery, ResponsePresignedGetQuery, ResponseDocumentsScore } from './API';

const client = generateClient();


async function convertImagesToBase64(html: string): Promise<string> {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const images = doc.querySelectorAll('img');

  for (let img of Array.from(images)) {
    const url = img.getAttribute('src');
    if (url) {
      const base64Url = await fetchImageAsBase64(url + "&t=1");
      img.setAttribute('src', base64Url);
    }
  }

  return doc.body.innerHTML; // Changed from doc.documentElement.innerHTML
}


async function removeTooltip(html: string): Promise<string> {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  // // remove all tag a with id "sourceIcon"
  // let sourceIcon = doc.getElementById('sourceIcon');
  // while (sourceIcon) {
  //   sourceIcon.remove();
  //   sourceIcon = doc.getElementById('sourceIcon');
  // }
  // remove all tag span with class "tooltipsource"
  let tooltipsource = doc.getElementsByClassName('tooltipsource');
  while (tooltipsource.length > 0) {
    tooltipsource[0].remove();
    tooltipsource = doc.getElementsByClassName('tooltipsource');
  }

  return doc.body.innerHTML; // Changed from doc.documentElement.innerHTML
}

async function fetchImageAsBase64(url: string): Promise<string> {
  const response = await fetch(url);
  const arrayBuffer = await response.arrayBuffer();
  const blob = new Blob([arrayBuffer], { type: 'image/png' });
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export async function copyToClipboard(text: string, snackbar: {
  showAlert: (text: string, newVariation?: BadgeVariations | undefined) => void;
  AlertComponent: () => JSX.Element;
}, alert: {
  showAlert: (text: string, newVariation?: BadgeVariations | undefined) => void;
  AlertComponent: () => JSX.Element;
}) {
  try {
    const textModifiedImage = await convertImagesToBase64(text)
    // console.log(textModifiedImage)
    const textModified = await removeTooltip(textModifiedImage)
    // console.log(textModified)
    const blobInput = new Blob([textModified], { type: 'text/html' });
    const clipboardItemInput = new ClipboardItem({ 'text/html': blobInput });
    navigator.clipboard.write([clipboardItemInput]);
    snackbar.showAlert('Copied to clipboard (Word).', 'success');
  } catch (e) {
    // Handle error with user feedback - "Copy failed!" kind of thing
    console.error(e);
    alert.showAlert(`Could not copy ${e}`, 'error');
  }
};

export function formatFileSize(size: number | null | undefined): string {
  if (size == null) {
    return 'n/a';
  }

  if (size < 1024) {
    return `${size} B`;
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  } else {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  }
}


export function formatNbQuestions(nbQuestions: number | null | undefined): string {
  if (nbQuestions === null) {
    return 'n/a';
  }

  if (nbQuestions === 1) {
    return '1 item';
  }
  else if (nbQuestions === 0) {
    return 'n/a';
  }
  else {
    return `${nbQuestions} items`;
  }
}


export function formatDate(dateString: string | null | undefined): string {
  if (dateString == null) {
    return 'n/a';
  }

  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };
  const formattedDate = date.toLocaleDateString('en-GB', options);
  return formattedDate.replace(/\//g, '.');
}


export function formatDateISO(dateString: string | null | undefined): string {
  if (dateString == null) {
    return 'n/a';
  }
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}


export function getIsExcludedText(isExcluded: boolean | null | undefined): string {
  if (isExcluded === null || isExcluded === undefined) {
    return `IN DATABASE`;
  }

  if (isExcluded === true) {
    return 'EXCLUDED';
  } else {
    return `IN DATABASE`;
  }
}


export function getIsExcludedVariation(isExcluded: boolean | null | undefined): BadgeVariations {
  if (isExcluded === null || isExcluded === undefined) {
    return 'success';
  }

  if (isExcluded === true) {
    return 'error';
  } else {
    return 'success';
  }
}


export const customReactModalStyles = {
  content: {
    width: '90%',
    maxWidth: '1500px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export const customReactSmallModalStyles = {
  content: {
    width: '90%',
    maxWidth: '700px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export const editorKey = 'yn9m01qrco6sb1gvdejeq054pi18ke1ugkhi5ijioqudv8j9';


export function tinymceConfig(docId: String, target: String) {
  return {
    menubar: false,
    plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | align lineheight | tinycomments | checklist numlist bullist indent outdent | link table ImageButton',
    tinycomments_mode: 'embedded',
    setup: function (editor: any) {
      editor.ui.registry.addButton('ImageButton', {
        text: 'Add Image',
        onAction: function (_: any) {
          // console.log("onAction")
          editor.windowManager.open({
            title: 'Upload Image',
            body: {
              type: 'panel',
              items: [
                {
                  type: 'dropzone',
                  name: 'file',
                  label: "Upload an image image here and click on Upload to have it in your text",
                  onchange: function (api: any) {
                    const data = api.getData();
                    const file = data.file[0];
                    api.block('Uploading...');
                    setTimeout(function () {
                      api.unblock();
                      api.setData({
                        file: file,
                        filename: file.name
                      });
                    }, 2000);
                  }
                }
              ]
            },
            buttons: [
              {
                type: 'cancel',
                text: 'Cancel'
              },
              {
                type: 'submit',
                text: 'Upload',
                primary: true
              }
            ],
            onSubmit: async function (api: any) {
              const data = api.getData();
              const file = data.file[0];
              // console.log(file);
              // Generate the S3 signed URL
              const link = await client.graphql<GraphQLQuery<ResponsePresignedPutQuery>>(
                { query: queries.responsePresignedPut, variables: { docId: `${docId}`, target: `${target}` } }
              );
              // console.log(link);
              if (link?.data?.responsePresignedPut !== null && link?.data?.responsePresignedPut !== undefined) {
                // Upload the file to S3
                // console.log("Upload the file to S3");
                await axios.put(link.data.responsePresignedPut, file, {
                  headers: {
                    'Content-Type': 'image/jpeg',
                    'Access-Control-Allow-Origin': '*'
                  }
                });
                // console.log("pngMatch");
                const pngMatch = link.data.responsePresignedPut.match(/\/([^\/]*\/[^\/]*\.png)\?/); // const pngMatch = oldUrl.match(/\/([^\/]*\.png)\?/);
                let pngName = link.data.responsePresignedPut
                if (pngMatch && pngMatch[1]) {
                  pngName = pngMatch[1];
                }
                // console.log({ objectName: `${target}/image/${pngName}` });
                const linkGet = await client.graphql<GraphQLQuery<ResponsePresignedGetQuery>>(
                  { query: queries.responsePresignedGet, variables: { objectName: `${target}/image/${pngName}` } }
                );
                // console.log(linkGet);
                if (linkGet?.data?.responsePresignedGet !== null && linkGet?.data?.responsePresignedGet !== undefined) {
                  const newUrl = linkGet?.data?.responsePresignedGet

                  // Insert the image into the editor
                  // console.log("Insert the image into the editor");
                  editor.insertContent(`&nbsp;<img class="amplify-image" style="object-fit: initial; object-position: 50% 50%;" src="${newUrl}"/>&nbsp;`);
                }

              } else {
                // console.log("Could not import image - Save question first.");
                editor.insertContent(`"Could not import image - Save question first."`);
              }
              // Close the dialog
              api.close();
            }
          });
        }
      });
    },
  }
}


export const upload_to_s3 = async (url: string, file: File | null) => {
  if (!file) {
    throw new Error('No file selected');
  }
  const response = await axios.put(url, file, {
    headers: {
      'Content-Type': file.type,
      'Access-Control-Allow-Origin': '*'
    },
  });

  if (response.status !== 200) {
    throw new Error(`Failed to upload file: ${response.statusText}`);
  }
};


export const convertResponseToHtml = async (text: string, presignedUrlDone: { [key: string]: string },listdocuments: (ResponseDocumentsScore | null)[] | null | undefined) => {
  const response = ReplaceCitationTags(text, listdocuments);
  // Update image path if response if text
  const newHtml = await convertImagePathToSignedUrl(response, presignedUrlDone);
  return newHtml;
}


function ReplaceCitationTags(text: String, listdocuments: (ResponseDocumentsScore | null)[] | null | undefined) {
  const regex = /\s+<span id="rfpItem">\s*(.*?)\s*<\/span>\s+/g;
  const replacedText = text.replace(regex, (match, group1) => {
    // Process group1
    // In listdocuments find item matching group1
    const doc = listdocuments?.find((item: ResponseDocumentsScore | null) => item?.id === group1);
    if (doc !== null && doc !== undefined) {
      // return `<a href="#" id="sourceIcon" title="Source: ${doc?.question}"></a>`;
      return `<span class="tooltipsource">&#x2731;<span class="tooltiptextsource">Source: ${doc?.question}<br/><small>${formatDateISO(doc?.datetime)} - ${doc?.rfpFileName ? doc?.rfpFileName : ""}</small></span></span>`;
    }
    return "";
  });

  return replacedText;
}


export const convertImagePathToSignedUrl = async (text: string, presignedUrlDone: { [key: string]: string }, target: string = 'processed') => {
  const regex = /<img .*?src="(.*?)"/g;
  let match;
  let newHtml = text;

  while ((match = regex.exec(text)) !== null) {
    const oldUrl = match[1];
    const parts = oldUrl.split('/');
    const image_src = parts.slice(-2).join('/');
    if (presignedUrlDone[`${image_src}`]) {
      newHtml = newHtml.replace(oldUrl, presignedUrlDone[`${image_src}`]);
      newHtml = newHtml.replace(/<img/g, `<img class="amplify-image" style="object-fit: initial; object-position: 50% 50%;" `);
      continue;
    }
    const link = await client.graphql<GraphQLQuery<ResponsePresignedGetQuery>>(
      { query: queries.responsePresignedGet, variables: { objectName: `${target}/image/${image_src}` } }
    );
    if (link?.data?.responsePresignedGet !== null && link?.data?.responsePresignedGet !== undefined) {
      const newUrl = link?.data?.responsePresignedGet
      presignedUrlDone[`${image_src}`] = newUrl;
      newHtml = newHtml.replace(oldUrl, newUrl);
      newHtml = newHtml.replace(/<img/g, `<img class="amplify-image" style="object-fit: initial; object-position: 50% 50%;" `);
    }
  }
  return newHtml;
}


export const convertImagePathBeforeSaving = (text: string) => {
  const regex = /<img .*?src="(.*?)"/g;
  let match;
  let newHtml = text;
  while ((match = regex.exec(text)) !== null) {
    const oldUrl = match[1];
    let pngName = oldUrl
    const pngMatch = oldUrl.match(/\/([^\/]*\/[^\/]*\.(png|jpg|jpeg))\?/);
    if (pngMatch && pngMatch[1]) {
      pngName = pngMatch[1];
    }
    newHtml = newHtml.replace(oldUrl, pngName);
    newHtml = newHtml.replace(/<img .*?src=/g, `<img src=`);
  }
  return newHtml;
}