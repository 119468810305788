/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import {
  Button,
  Flex,
  TextField,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
export default function QuestionLayout(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        TextField: {},
        "Frame 441": {},
        PublishButton: {},
        ButtonGroup: {},
        Frame440: {},
        QuestionLayout: {},
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        TextField: {},
        "Frame 441": { height: "127px", shrink: "0", alignSelf: "stretch" },
        PublishButton: { width: "unset", shrink: "1", grow: "1", basis: "0" },
        ButtonGroup: { width: "unset", alignSelf: "stretch" },
        Frame440: {
          direction: "column",
          height: "90px",
          justifyContent: "space-between",
          alignItems: "flex-start",
        },
        QuestionLayout: { width: "481px" },
      },
      variantValues: { breakpoint: "small" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="1015px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="12px 10px 12px 10px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...getOverrideProps(overrides, "QuestionLayout")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame440")}
      >
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="40px"
          justifyContent="space-between"
          alignItems="flex-start"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 441")}
        >
          <TextField
            width="unset"
            height="unset"
            placeholder="Type your RFP question"
            grow="1"
            shrink="1"
            basis="0"
            size="large"
            isDisabled={false}
            labelHidden={true}
            variation="default"
            {...getOverrideProps(overrides, "TextField")}
          ></TextField>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          width="105px"
          height="unset"
          justifyContent="space-between"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "ButtonGroup")}
        >
          <Button
            width="105px"
            height="unset"
            shrink="0"
            size="large"
            isDisabled={false}
            variation="primary"
            children="Generate"
            {...getOverrideProps(overrides, "PublishButton")}
          ></Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
