import { useState, useEffect } from "react";
import { Outlet, useNavigate } from 'react-router-dom'; //  matchPath, useLocation,
// import { Auth } from "aws-amplify";
import { signOut, fetchUserAttributes } from 'aws-amplify/auth';


import {
  Divider,
  Flex,
  Menu,
  MenuButton,
  Button,
  MenuItem,
  useBreakpointValue
} from "@aws-amplify/ui-react";

import {
  AccountDropdown,
  NavBarHeader,
  SideBar,
} from "../../ui-components";

import { slide as BurgerMenu } from "react-burger-menu";


// ----------------------------------------------------------------------

const BUTTON_STYLE =
  "amplify-button amplify-field-group__control amplify-button--link";


// const APP_BAR_MOBILE = 64;
// const APP_BAR_DESKTOP = 64;

// const RootStyle = styled('div')({
//   display: 'flex',
//   minHeight: '100%',
//   overflow: 'hidden'
// });

// const MainStyle = styled('div')(({ theme }) => ({
//   flexGrow: 1,
//   overflow: 'auto',
//   minHeight: '100%',
//   paddingTop: APP_BAR_MOBILE + 24,
//   paddingBottom: theme.spacing(10),
//   [theme.breakpoints.up('lg')]: {
//     paddingTop: APP_BAR_DESKTOP + 24,
//     paddingLeft: theme.spacing(2),
//     paddingRight: theme.spacing(2)
//   }
// }));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const navigate = useNavigate();
  // const { pathname } = useLocation();
  // const match = (path: string) => (path ? !!matchPath({ path, end: false }, pathname) : false);
  const [userName, setUserName] = useState('');

  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
  }) as 'small' | 'medium' | undefined;

  // const [open, setOpen] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);

  useEffect(() => {
    async function fetchUserName() {
      const attributes = await fetchUserAttributes();//Auth.currentAuthenticatedUser();
      // const user = await getCurrentUser();//Auth.currentAuthenticatedUser();
      // console.log(attributes  );
      
      // if attribute is string
      if (attributes.name) {
        setUserName(attributes.name );//.attributes.name);
      }
      else {
        setUserName("no name");
      }
    }
    fetchUserName();
  }, []);

  return (
    <Flex
      direction="column"
      gap="0px"
      height={{
        base: "100%",
        medium: "100vh",
      }}
      minHeight="100vh"
      maxHeight="100vh"
      width="100vw"
      margin="0 auto"
      overflow="auto"
      position={{
        base: "relative",
        small: "relative",
        medium: "sticky",
        large: "sticky",
      }}
      id="scrollableContainer"
    >
      <NavBarHeader
        overrides={{
          NavBarHeader: {
            width: "100%",
            direction: { base: "column-reverse", medium: "row" },
            top: 0,
            // paddingLeft: { base: "0px", medium: "215px" },
            // paddingRight: { base: "0px", medium: "large" },
            backgroundColor: "background.primary",
            style: {
              zIndex: 2,
            },
          },
          "Frame 321": {
            width: "100%",
          },
          FrameMenu: {
            className: BUTTON_STYLE,
            onClick: () => {
              setToggleMenu(!toggleMenu);
            },
          },
          AccountDropdownContainer: {
            children: (
              <Menu
                trigger={
                  <MenuButton variation="link" padding={0}>
                    <AccountDropdown
                      overrides={{
                        ProfileImage: {
                          src: "/ProfileImage1.webp",
                        },
                        label: {
                          children: userName,
                        }
                      }}
                    />
                  </MenuButton>
                }
              >
                <Divider />
                <MenuItem onClick={() => signOut()}>Sign Out</MenuItem>
              </Menu>
            ),
          },
        }}
      />
      <Flex
        direction="row"
        gap="0px"
        height={{
          base: "100%",
          medium: "100%",
        }}
        alignItems="stretch"
      >
        {breakpointHook === 'small' && (
          <BurgerMenu isOpen={toggleMenu} customBurgerIcon={false} onClose={()=> setToggleMenu(false)}>
            <SideBar
              overrides={{
                "Frame 322": { className: BUTTON_STYLE, onClick: () => { navigate("/dashboard"); setToggleMenu(false) } },
                "link39493373": { className: BUTTON_STYLE, onClick: () => { navigate("/documents"); setToggleMenu(false) } },
                "link39493365": { className: BUTTON_STYLE, onClick: () => { navigate("/documents"); setToggleMenu(false) } },
                "find39493379": { className: BUTTON_STYLE, onClick: () => { navigate("/search"); setToggleMenu(false) } },
                "link4037778": { className: BUTTON_STYLE, onClick: () => { navigate("/generate"); setToggleMenu(false) } },
                "link39464878": { className: BUTTON_STYLE, onClick: () => { navigate("/questions"); setToggleMenu(false) } },
                "find": { className: BUTTON_STYLE, onClick: () => { navigate("/help"); setToggleMenu(false) } },
                "linkdoc": { className: BUTTON_STYLE, onClick: () => { navigate("/batch"); setToggleMenu(false) } },
                SideBar: {
                  style: {
                    zIndex: 1,
                  },
                  display: {
                    base: "flex" ,
                  },
                  width: { base: "100%", medium: "auto" },
                  height: { base: "100vh" },
                  position: { base: "absolute", medium: "sticky" },
                  top: { base: "initial", medium: "0px" },
                },
                Logo: {
                  className: "logo",
                  onClick: () => {
                    console.log('router.push("/")');
                  },
                } as any,
                Buttons: {
                  height: "100%",
                  width: {
                    base: "100%",
                    small: "auto",
                  },
                },
                AccountDropdownContainer: {
                  children: (
                    <Button onClick={() => signOut()}>Sign Out</Button>
                  ),
                },
              }}
              height={{
                base: "100%"
              }}
            />
          </BurgerMenu>
        )}
        {breakpointHook === 'medium' && (
          <SideBar
            overrides={{
              "Frame 322": { className: BUTTON_STYLE, onClick: () => { navigate("/dashboard"); setToggleMenu(false) } },
              "link39493373": { className: BUTTON_STYLE, onClick: () => { navigate("/documents"); setToggleMenu(false) } },
              "link39493365": { className: BUTTON_STYLE, onClick: () => { navigate("/documents"); setToggleMenu(false) } },
              "find39493379": { className: BUTTON_STYLE, onClick: () => { navigate("/search"); setToggleMenu(false) } },
              "link4037778": { className: BUTTON_STYLE, onClick: () => { navigate("/generate"); setToggleMenu(false) } },
              "link39464878": { className: BUTTON_STYLE, onClick: () => { navigate("/questions"); setToggleMenu(false) } },
              "find": { className: BUTTON_STYLE, onClick: () => { navigate("/help"); setToggleMenu(false) } },
              "linkdoc": { className: BUTTON_STYLE, onClick: () => { navigate("/batch"); setToggleMenu(false) } },

              SideBar: {
                style: {
                  zIndex: 1,
                },
                display: {
                  base: toggleMenu ? "flex" : "none",
                  medium: "block",
                },
                width: { base: "100%", medium: "auto" },
                height: { base: "100%", medium: "100%" },
                position: { base: "absolute", medium: "sticky" },
                top: { base: "initial", medium: "0px" },
              },
              Logo: {
                className: "logo",
                onClick: () => {
                  console.log('router.push("/")');
                },
              } as any,
              Buttons: {
                height: "100%",
                width: {
                  base: "100%",
                  small: "auto",
                },
              },
              AccountDropdownContainer: {
                children: (
                  <Button onClick={() => signOut()}>Sign Out</Button>
                ),
              },
            }}
            height={{
              base: "100%"
            }}
          />
        )}
        <Flex
          direction="column"
          width={{
            base: "100%",
            small: "100vw",
            medium: "100%",
            large: "100%",
          }}
          className="noBar"
          margin="20px"
          backgroundColor={"background.secondary"}
        >
          <Outlet />
        </Flex>
      </Flex>
    </Flex>
  );
}
