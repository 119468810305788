import { useEffect, useState, useRef } from 'react';
import { useAlert } from '../hooks/useAlert';
import { Badge, Flex, Button, Card, Text, Heading, Accordion, TextAreaField, Breadcrumbs, SelectField, View, Loader } from '@aws-amplify/ui-react';
import Page from '../components/Page';
import { formatDate, formatDateISO, copyToClipboard, getIsExcludedText, getIsExcludedVariation, customReactModalStyles, editorKey, tinymceConfig, convertResponseToHtml, convertImagePathBeforeSaving } from '../utils';
//import DocumentLayout from '../ui-components/DocumentLayout';
import RfpItemLayout from '../ui-components/RfpItemLayout';
import QuestionLayout from '../ui-components/QuestionLayout';
import RfpGeneratedLayout from '../ui-components/RfpGeneratedLayout';
import Modal from 'react-modal';
import { studioTheme } from '../ui-components';
import { ThemeProvider } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import parse from 'html-react-parser';
// GraphQL imports
import { generateClient } from 'aws-amplify/api';


import * as queries from '../graphql/queries';
//import * as mutations from '../graphql/mutations';
import { GraphQLQuery } from '@aws-amplify/api';
import { GraphQLSubscription } from '@aws-amplify/api';

import { OnCreateUserQuestionSubscription, OnUpdateUserQuestionSubscriptionVariables, OnUpdateUserQuestionSubscription, Strategy, GetRfpFileItemQuery, OnUpdateUserQuestionComplianceSubscriptionVariables, OnUpdateUserQuestionComplianceSubscription, GetRfpQuestionResponseQuery, AddBackQuestionKnowledgeBaseQuery, DeleteQuestionQuery, RemoveQuestionKnowledgeBaseQuery, UpdateQuestionKnowledgeBaseQuery, ListStrategiesQuery, GetRfpResponseComplianceCheckQuery, OnCreateUserQuestionSubscriptionVariables, OnCreateUserQuestionComplianceSubscriptionVariables, OnCreateUserQuestionComplianceSubscription, UserQuestion, ResponseDocumentsScore } from '../API';
// Subscription  // https://docs.amplify.aws/lib/graphqlapi/subscribe-data/q/platform/js/#set-up-a-real-time-subscription
import * as subscriptions from '../graphql/subscriptions';


export default function Generate() {
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const navigate = useNavigate();
  const alert = useAlert({ variation: 'error' });
  const snackbar = useAlert({ variation: 'success' });
  const [responseData, setResponseData] = useState<any>();
  const [responseWarnings, setResponseWarnings] = useState<any>({ warnings: [{ "extracted_data": "status", "description": "no check performed" }] });
  //const [responseText, setResponseText] = useState<string | null>(null);
  const [questionRequestedValue, setQuestionRequestedValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingCompliance, setIsLoadingCompliance] = useState<boolean>(false);
  const [deleteQuestionId, setDeleteQuestionId] = useState<string | null | undefined>("");
  const [removeQuestionId, setRemoveQuestionId] = useState<string | null | undefined>("");
  const [removeQuestionIsExcluded, setRemoveQuestionIsExcluded] = useState<boolean | null | undefined>(false);
  const [editQuestionId, setEditQuestionId] = useState<string | null | undefined>("");
  const [answerValue, setAnswerValue] = useState<string | null | undefined>("");
  const [questionValue, setQuestionValue] = useState<string | null | undefined>("");
  const [modalConfirmatonDeleteIsOpen, setModalConfirmatonDeleteIsOpen] = useState(false);
  const [modalConfirmatonRemoveIsOpen, setModalConfirmatonRemoveIsOpen] = useState(false);
  const [modalConfirmatonEditIsOpen, setModalConfirmatonEditIsOpen] = useState(false);
  const [selectedStrategy, setSelectedStrategy] = useState<string>("all");
  const [listStrategy, setListStrategy] = useState<any>(null);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const API = generateClient();

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter' && !isLoading) {
      sendMessage()
    }
  }


  useEffect(() => {
    async function fetchCategories() {
      try {

        // Fetch data from the amplify graphql API table Strategy
        const allStrategy = await API.graphql<GraphQLQuery<ListStrategiesQuery>>(
          { query: queries.listStrategies }
        );
        setListStrategy(allStrategy.data?.listStrategies?.items)

      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    }
    fetchCategories();
  }, []);


  async function refreshQuestions() {
    // Fetch data from the amplify graphql API table RfpFile
    try {

      if (responseData?.documents?.length === 0) {
        return;
      }

      const ids = responseData.documents.map((item: any) => item.id);
      // Fetch data from the amplify graphql API table RfpFile for each id
      const questions: any[] = [];
      for (const id of ids) {
        const question = await API.graphql<GraphQLQuery<GetRfpFileItemQuery>>(
          { query: queries.getRfpFileItem, variables: { id: id } }
        );
        if (question.data?.getRfpFileItem !== null && question.data?.getRfpFileItem !== undefined) {
          questions.push(question.data?.getRfpFileItem);
        }
      }
      // Sort items with same order as ids
      const items = questions?.sort((a, b) => ids.findIndex((id: string) => id === a?.id) - ids.findIndex((id: string) => id === b?.id));

      // ====================== Get Images
      // record image with presigned url
      let presignedUrlDone: { [key: string]: string } = {};

      if (items) {
        const processItems = async () => {
          const promises = items.map(async (item: any) => {
            const newHtml = await convertResponseToHtml(item.answer, presignedUrlDone, []);
            item.answer = newHtml;
          });
          // Wait for all promises to resolve
          await Promise.all(promises);
        };
        // Call the async function
        processItems().then(() => {
          // console.log("questions",questions);
          setResponseData({ ...responseData, documents: questions }) // .data?.listRfpFileItems?.items
        });
      }

      // setResponseData({ ...responseData, documents: questions.data?.listRfpFileItems?.items })
    } catch (error) {
      console.error('Error fetching document data:', error);
    }
  }

  // function buttonCompliance() {
  //   checkCompliance(responseData.answer);
  // }


  async function checkCompliance(response: string | null | undefined) {
    if (typeof response !== 'string') {
      //console.log("no text to check");
      return;
    }
    try {
      const responseWarnings = await API.graphql<GraphQLQuery<GetRfpResponseComplianceCheckQuery>>(
        { query: queries.getRfpResponseComplianceCheck, variables: { msg: response } }
      )
      const requestId = responseWarnings.data?.getRfpResponseComplianceCheck
      const variables: OnCreateUserQuestionComplianceSubscriptionVariables = {
        filter: {
          // Only receive  messages where the correct requestId
          requestId: { eq: requestId }
        }
      }
      const variablesUpdate: OnUpdateUserQuestionComplianceSubscriptionVariables = {
        filter: {
          // Only receive  messages where the correct requestId
          requestId: { eq: requestId }
        }
      }
      setIsLoadingCompliance(true);
      const createSubCompliance = API.graphql<GraphQLSubscription<OnCreateUserQuestionComplianceSubscription>>(
        { query: subscriptions.onCreateUserQuestionCompliance, variables }
      ).subscribe({
        next: ({ data }) => {
          // Stop receiving data updates from the subscription
          createSubCompliance.unsubscribe();
          if (data?.onCreateUserQuestionCompliance?.warnings === null || data?.onCreateUserQuestionCompliance?.warnings === undefined) {
            setResponseWarnings({ warnings: [] })
          } else {
            setResponseWarnings({ warnings: data?.onCreateUserQuestionCompliance?.warnings })
          }
          if (data?.onCreateUserQuestionCompliance?.generationCompleted === true) {
            setIsLoadingCompliance(false);
          }
        },
        error: (error) => console.error(error)
      });
      const updateSubCompliance = API.graphql<GraphQLSubscription<OnUpdateUserQuestionComplianceSubscription>>(
        { query: subscriptions.onUpdateUserQuestionCompliance, variables: variablesUpdate }
      ).subscribe({
        next: ({ data }) => {
          // Stop receiving data updates from the subscription
          if (data?.onUpdateUserQuestionCompliance?.warnings === null || data?.onUpdateUserQuestionCompliance?.warnings === undefined) {
            setResponseWarnings({ warnings: [] })
          } else {
            setResponseWarnings({ warnings: data?.onUpdateUserQuestionCompliance?.warnings })
          }
          if (data?.onUpdateUserQuestionCompliance?.generationCompleted === true) {
            setIsLoadingCompliance(false);
            updateSubCompliance.unsubscribe();
          }
        },
        error: (error) => console.error(error)
      });
      // #############################################################################

    }
    catch (error) {
      console.error('Error generating questions:', error);
      alert.showAlert('Error generating a response');
    }

  }


  const sendMessage = async () => {
    setIsLoading(true);
    setIsGenerating(true);
    if (questionRequestedValue !== '') {
      try {
        // Reset values
        setResponseWarnings({ warnings: [{ "extracted_data": "status", "description": "no check performed" }] });
        setResponseData({ ...responseData, answer: "", question: questionRequestedValue, documents: [] })
        // Fetch data from the amplify graphql API table RfpQuestionResponse
        const responseGenerate = await API.graphql<GraphQLQuery<GetRfpQuestionResponseQuery>>(
          { query: queries.getRfpQuestionResponse, variables: { msg: questionRequestedValue, strategy: selectedStrategy } }
        );
        let response = responseGenerate.data?.getRfpQuestionResponse
        //console.log("response", response);
        const variables: OnCreateUserQuestionSubscriptionVariables = {
          filter: {
            // Only receive  messages where the correct requestId
            requestId: { eq: response }
          }
        }
        const createSub = API.graphql<GraphQLSubscription<OnCreateUserQuestionSubscription>>(
          { query: subscriptions.onCreateUserQuestion, variables }
        ).subscribe({
          next: ({ data }) => {
            // Stop receiving data updates from the subscription
            setIsLoading(false)
            // Start loading wheel text generation
            setIsGenerating(true);
            processResponse(data?.onCreateUserQuestion);

            // console.log(data?.onCreateUserQuestion);
            createSub.unsubscribe();
            if (data?.onCreateUserQuestion?.generationCompleted === true) {
              // Stop loading wheel text generation
              setIsGenerating(false);
              checkCompliance(data?.onCreateUserQuestion?.answer);
              snackbar.showAlert('Generation completed');
            }

          },
          error: (error) => console.error(error)
        });
        const variablesUpdate: OnUpdateUserQuestionSubscriptionVariables = {
          filter: {
            // Only receive  messages where the correct requestId
            requestId: { eq: response }
          }
        }
        const updateSub = API.graphql<GraphQLSubscription<OnUpdateUserQuestionSubscription>>(
          { query: subscriptions.onUpdateUserQuestion, variables: variablesUpdate }
        ).subscribe({
          next: ({ data }) => {
            // Stop receiving data updates from the subscription
            setIsGenerating(true);
            setIsLoading(false);
            //console.log(data?.onUpdateUserQuestion);
            processResponse(data?.onUpdateUserQuestion);
            if (data?.onUpdateUserQuestion?.generationCompleted === true) {
              setIsGenerating(false);
              checkCompliance(data?.onUpdateUserQuestion?.answer);
              updateSub.unsubscribe();
              snackbar.showAlert('Generation completed');
            }
          },
          error: (error) => console.error(error)
        });
        snackbar.showAlert('Working on it...');
      }
      catch (error) {
        console.error('Error generating questions:', error);
        alert.showAlert('Error generating a response');
      }

    }
    // setIsLoading(false);

  };


  // function ReplaceCitationTags(text: String, listdocuments: (ResponseDocumentsScore | null)[] | null | undefined) {
  //   const regex = /<span id="rfpItem">\s*(.*?)\s*<\/span>/g;
  //   const replacedText = text.replace(regex, (match, group1) => {
  //     // Process group1
  //     // In listdocuments find item matching group1
  //     const doc = listdocuments?.find((item: ResponseDocumentsScore | null) => item?.id === group1);
  //     if (doc !== null && doc !== undefined) {
  //       return `<a href="#" id="sourceIcon" title="Source: ${doc?.question}">&#x2731;</a>`;
  //     }
  //     return "";
  //   });

  //   return replacedText;
  // }

  // function test() {

  //   let text = responseData.answer;
  //   let listdocuments = responseData.documents;

  //   const regex = /=\s*(.*?)\s*=/g;
  //   console.log("text", text);
  //   const replacedText = text.replace(regex, (match: any, group1: any) => {
  //     console.log("group1", group1);
  //     console.log("match", match);
  //     console.log("listdocuments", listdocuments);
  //     // Process group1
  //     // In listdocuments find item matching group1
  //     const doc = listdocuments?.find((item: ResponseDocumentsScore | null) => item?.id === group1);
  //     // const doc = listdocuments?.map((item: ResponseDocumentsScore | null) => {
  //     //   if (item?.id === group1) {
  //     //     return item;
  //     //   }
  //     // });
  //     console.log("doc", doc);
  //     if ( doc !== null && doc !== undefined) {
  //       return `<small>Source: ${doc[0]?.question}</small>`;
  //     }
  //     return `=${group1}=`;
  //   });

  //   return replacedText;    
  // }

  async function processResponse(responseGenerate: UserQuestion | null | undefined) {
    if (responseGenerate === null || responseGenerate === undefined) {
      return;
    }
    const isoDate = new Date().toISOString();
    let response = responseGenerate?.answer
    let documents = responseGenerate?.documents
    let presignedUrlDone: { [key: string]: string } = {};

    if (typeof response === 'string') {
      // Remove from response text at end after "@#@"
      let sources = "";
      if (response && response.includes("@#@")) {
        response = response.split("@#@")[0];
        sources = response.split("@#@")[1];
      }
      // Replace citation tags
      //response = ReplaceCitationTags(response, documents);
      // Update image path if response if text
      const newHtml = await convertResponseToHtml(response, presignedUrlDone, documents);
      response = newHtml;
    }


    // Update each document to convert image path to presigned url
    if (documents && Array.isArray(documents)) {
      const processItems = async () => {
        if (documents && Array.isArray(documents)) {
          const promises = documents.map(async (item: any) => {
            const newHtml = await convertResponseToHtml(item.answer, presignedUrlDone, documents);
            item.answer = newHtml;
          });
          // Wait for all promises to resolve
          await Promise.all(promises);
        }
      };
      // Call the async function
      processItems().then(() => {
        setResponseData({ question: questionRequestedValue, answer: response, documents: documents, datetime: isoDate, warnings: [{ "extracted_data": "status", "description": "no check performed" }] }) // .data?.listRfpFileItems?.items
      });
    }

    // Add the message to the 1st place in list of messages in documentData

    const newResponse = { question: questionRequestedValue, answer: response, documents: documents, datetime: isoDate, warnings: [{ "extracted_data": "status", "description": "no check performed" }] }
    // console.log("newResponse",newResponse);
    setResponseData(newResponse);
  }

  //#######################################################################################################################
  function getModalConfirmation(modalBool: boolean, setModal: Function, callBackFunction: Function) {
    Modal.setAppElement('#root');
    return (
      <Modal
        isOpen={modalBool}
        style={customReactModalStyles}
      >
        <ThemeProvider theme={studioTheme}>
          <Flex direction={"column"} gap="20px">
            <Heading >Are you sure you want to continue?</Heading>
            <Flex direction={"row"} alignItems="space-between">
              <Button onClick={() => { callBackFunction(); setModal(false); }}>Yes</Button><Button onClick={() => setModal(false)}>No</Button>
            </Flex>
          </Flex>
        </ThemeProvider>
      </Modal>);
  }


  //#######################################################################################################################
  function getModalForm(text: string, modalBool: boolean, setModal: Function, callBackFunction: Function) {
    Modal.setAppElement('#root');
    return (
      <Modal
        isOpen={modalBool}
        style={customReactModalStyles}
      >
        <ThemeProvider theme={studioTheme}>
          <Flex direction={"column"} gap="20px" style={{ maxHeight: '90vh', overflowY: 'auto' }}>
            <Heading >{text}</Heading>
            <Flex direction={"column"} alignItems="space-between">
              <Text >Question</Text>
              <TextAreaField
                placeholder="..."
                label=""
                errorMessage="There is an error"
                rows={2}
                onChange={(event) => setQuestionValue(event.target.value)}
                defaultValue={questionValue as string}
              />
              <Flex className="test" direction={"column"}>
                <Text >Answer</Text>
                <Editor
                  onInit={(evt, editor) => editorRef.current = editor}
                  apiKey={editorKey}
                  init={tinymceConfig(editQuestionId as String, "processed")}
                  initialValue={answerValue as string}
                />
              </Flex>
            </Flex>
            <Flex direction={"row"} alignItems="space-between">
              <Button variation='primary' onClick={() => { callBackFunction(); setModal(false); }}>Save</Button><Button variation='link' onClick={() => setModal(false)}>Cancel</Button>
            </Flex>
          </Flex>
        </ThemeProvider>
      </Modal>);
  }


  //#######################################################################################################################
  function editQuestionConfirmation(questionId: string | null | undefined, question: string | null | undefined, answer: string | null | undefined) {
    setEditQuestionId(questionId);
    setQuestionValue(question);
    setAnswerValue(answer);
    setModalConfirmatonEditIsOpen(true);
  }

  async function editQuestion() {
    if (editQuestionId === "") {
      return;
    }
    if (editorRef.current) {
      setIsLoading(true);
      const newHtml = convertImagePathBeforeSaving(editorRef.current.getContent());

      await API.graphql<GraphQLQuery<UpdateQuestionKnowledgeBaseQuery>>(
        {
          query: queries.updateQuestionKnowledgeBase, variables: {
            questionId: editQuestionId,
            question: questionValue,
            answer: newHtml //editorRef.current.getContent()
          }
        }
      );
      refreshQuestions();
      setIsLoading(false);
    }
    else {
      console.error("No editorRef value");
      return;
    }
  }

  function deleteQuestionConfirmation(questionId: string | null | undefined) {
    setDeleteQuestionId(questionId);
    setModalConfirmatonDeleteIsOpen(true);
  }

  async function deleteQuestion() {
    if (deleteQuestionId === "") {
      return;
    }
    setIsLoading(true);
    await API.graphql<GraphQLQuery<DeleteQuestionQuery>>(
      {
        query: queries.deleteQuestion, variables: {
          questionId: deleteQuestionId
        }
      }
    );
    refreshQuestions();
    setIsLoading(false);
  }

  function removeQuestionConfirmation(questionId: string | null | undefined, isExcluded: boolean | null | undefined) {
    setRemoveQuestionId(questionId);
    setRemoveQuestionIsExcluded(isExcluded);
    setModalConfirmatonRemoveIsOpen(true);
  }

  async function removeQuestionFromDB() {
    if (removeQuestionId === "") {
      return;
    }
    setIsLoading(true);
    if (removeQuestionIsExcluded) {
      await API.graphql<GraphQLQuery<AddBackQuestionKnowledgeBaseQuery>>(
        {
          query: queries.addBackQuestionKnowledgeBase, variables: {
            questionId: removeQuestionId
          }
        }
      );
    } else {
      await API.graphql<GraphQLQuery<RemoveQuestionKnowledgeBaseQuery>>(
        {
          query: queries.removeQuestionKnowledgeBase, variables: {
            questionId: removeQuestionId
          }
        }
      );
    }
    refreshQuestions();
    setIsLoading(false);
  }


  function handleSelectChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setSelectedStrategy(event.target.value);
  }

  function generateLoadingGenerate() {
    if (isGenerating) {
      return <Loader />;
    }
    return <span />;
  }


  function formatWarnings() {
    let loadingWheel = <span> </span>;
    if (responseWarnings.warnings === null || responseWarnings.warnings === undefined || responseWarnings.warnings.length === 0) {
      return (<Text
        variation="primary"
        as="p"
      ><small>No warnings detected</small></Text>);
    }
    if (isLoadingCompliance) {
      loadingWheel = <Loader />;
    }
    return (<Text
      variation="primary"
      as="p"
    >
      {loadingWheel}
      <ul>
        {responseWarnings.warnings.map((warning: any, index: number) => (
          <li key={index}>
            <i />
            <small><b>{warning.extracted_data}</b> - {warning.description}</small>
          </li>
        ))}
      </ul>
    </Text>);
  }

  return (
    <Page title="Generate">
      <Flex gap="20px" direction={"column"}>
        <Breadcrumbs
          items={[
            {
              href: '/',
              label: 'Home',
            },
            {
              href: '/generate',
              label: 'Generate',
            }
          ]}
        />
        <snackbar.AlertComponent />
        <alert.AlertComponent />
        <Card>
          <Flex direction="column" alignItems="flex-start">
            <Heading level={4}>Generate RFP answers</Heading>
            <Text
              variation="primary"
              as="p"

            >
              Type RFP questions below to get answers from the RFP bot and find similar questions in the knowledge base.
            </Text>
            <SelectField
              label="Select a Category"
              value={selectedStrategy}
              onChange={handleSelectChange}
            >
              <option value="all">All</option>
              {listStrategy?.map((strategy: Strategy, index: number) => (
                <option key={index} value={strategy.id}>{strategy.name}</option>
              ))}
            </SelectField>
          </Flex>
        </Card>
        <QuestionLayout
          overrides={{
            QuestionLayout: {
              width: "100%"
            },
            TextInputEditor: {
              width: "100%",
              height: "10%",
            },
            TextField: {
              value: questionRequestedValue,
              onChange: (event) => setQuestionRequestedValue((event.target as HTMLInputElement).value),
              onKeyDown: (event) => handleKeyDown(event)
            },
            PublishButton: {
              isLoading: isLoading,
              isDisabled: isLoading,
              onClick: () => sendMessage()
            },
          }}
        />
        {getModalConfirmation(modalConfirmatonDeleteIsOpen, setModalConfirmatonDeleteIsOpen, deleteQuestion)}
        {getModalConfirmation(modalConfirmatonRemoveIsOpen, setModalConfirmatonRemoveIsOpen, removeQuestionFromDB)}
        {getModalForm("Edit Question", modalConfirmatonEditIsOpen, setModalConfirmatonEditIsOpen, editQuestion)}
        <Flex direction="column" justifyContent="center" alignItems="center" width="100%" >
          {responseData && <Card width={"100%"}>
            <Flex direction="column" width="100%" >
              < RfpGeneratedLayout
                overrides={
                  {
                    RfpGeneratedLayout: {
                      width: "100%"
                    },
                    Question: { children: responseData.question },
                    Date: { children: formatDate(responseData.datetime) },
                    HtmlLayout: { children: (<View>{generateLoadingGenerate()}{typeof responseData.answer === 'string' ? parse(responseData.answer) : ''}</View>) },
                    Button: {
                      onClick: () => copyToClipboard(responseData.question + "\n" + responseData.answer, snackbar, alert)
                    }
                  }
                }
              />

              <Card variation="elevated">
                {/* <Button onClick={() => test()}>TEST REGEX</Button> */}
                <Heading level={5}>Warnings</Heading>
                {formatWarnings()}
              </Card>
              <Card variation="elevated">
                <Flex direction="column" justifyContent="space-between" alignItems="left">
                  <Heading level={5}>Source documents</Heading>
                  {responseData.documents &&
                    <Accordion.Container allowMultiple>
                      {responseData.documents.map((item: any, index: number) => (
                        <Accordion.Item value={"exp" + index} key={index}>
                          <Accordion.Trigger>
                            <Flex direction="column" gap="0rem" >
                              <Flex justifyContent="space-between" alignItems="center">
                                <Text variation="secondary" as="span">
                                  {`${item.question}`}
                                </Text>
                              </Flex>
                              <Flex justifyContent="flex-start" alignItems="flex-start">
                                <Badge variation="info">{`${formatDateISO(item.datetime)}`}</Badge> <small>{`${item.rfpFileName ? item.rfpFileName : ""}`}</small>
                              </Flex>
                            </Flex>
                            <Accordion.Icon />
                          </Accordion.Trigger>
                          <Accordion.Content>
                            < RfpItemLayout
                              key={index}
                              overrides={
                                {
                                  RfpItemLayout: {
                                    width: "100%"
                                  },
                                  ButtonFile: {
                                    children: item.rfpFileName ? item.rfpFileName : "File",
                                    onClick: () => { navigate(`/documents/${item.rfpFileId}`); },
                                  },
                                  Question: { children: item.question },
                                  Date: { children: formatDateISO(item.datetime) },
                                  TextLayout: { children: (<View>{typeof item.answer === 'string' ? parse(item.answer) : ''}</View>) },
                                  ButtonCopy: { // Copy to clipboard
                                    onClick: () => copyToClipboard(item.question + "\n" + item.answer, snackbar, alert)
                                  },
                                  ButtonEdit: { // Edit
                                    onClick: () => editQuestionConfirmation(item.id, item.question, item.answer), isLoading: isLoading
                                  },
                                  ButtonRemove: { // Update Knowledge base
                                    children: item.isExcluded ? "Add to Search" : "Remove from Search",
                                    onClick: () => removeQuestionConfirmation(item.id, item.isExcluded), isLoading: isLoading
                                  },
                                  // DELETE
                                  ButtonDelete: { onClick: () => deleteQuestionConfirmation(item.id), isLoading: isLoading },

                                  "StatusBadge": {
                                    "children": getIsExcludedText(item.isExcluded),
                                    "variation": getIsExcludedVariation(item.isExcluded)
                                  }
                                }
                              }
                            />
                          </Accordion.Content>
                        </Accordion.Item>
                      ))}
                    </Accordion.Container>
                  }
                </Flex>
              </Card>
            </Flex>
          </Card>
          }
        </Flex>
      </Flex>
    </Page>
  );
}


