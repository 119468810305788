import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Flex, Card, Text, Heading, Breadcrumbs, Placeholder, Button } from '@aws-amplify/ui-react';
import { convertResponseToHtml, customReactModalStyles, convertImagePathBeforeSaving } from '../utils';
import Page from '../components/Page';
import { useAlert } from '../hooks/useAlert';
import parse from 'html-react-parser';
import Modal from 'react-modal';
import { studioTheme } from '../ui-components';
import { ThemeProvider } from '@aws-amplify/ui-react';
//import htmlContent from '/public/test.txt'; // This is the file that contains the document to be highlighted
import {
  useFloating,
  useDismiss,
  useInteractions,
  flip,
  shift,
  inline,
  offset,
  autoUpdate,
  // FloatingArrow,
  computePosition,
  arrow
} from "@floating-ui/react";
import { GraphQLQuery } from '@aws-amplify/api';
import { GetRfpHtmlDocumentQuery, GetRfpToAnswerFileQuery, ProcessHtmlRfpDocumentQuery } from '../API';
import * as queries from '../graphql/queries';
import { generateClient } from 'aws-amplify/api';


// const ARROW_WIDTH = 30;
const ARROW_HEIGHT = 15;

export default function DocumentHighlights() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [documentData, setDocumentData] = useState<any>(null);
  const [htmlContent, setHtmlContent] = useState('');
  const alert = useAlert({ variation: 'error' });
  const snackbar = useAlert({ variation: 'success' });
  const [modalConfirmationSimilarIsOpen, setModalConfirmationSimilarIsOpen] = useState(false);
  const [modalConfirmationGenerateIsOpen, setModalConfirmationGenerateIsOpen] = useState(false);
  const [modalConfirmationSelectedIsOpen, setModalConfirmationSelectedIsOpen] = useState(false);
  const selectableAreaRef = useRef<HTMLDivElement | null>(null);
  //const arrowRef = useRef<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [listQuestions, setListQuestions] = useState<Record<number, string[]>>({});
  const [cleanUpFloating, setCleanUpFloating] = useState<Function[]>([]);


  const { refs, floatingStyles, context } = useFloating({
    placement: "top",
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [inline(), flip(), shift()],
    whileElementsMounted: autoUpdate
  });
  const dismiss = useDismiss(context);
  const { getFloatingProps } = useInteractions([dismiss]);

  const API = generateClient();

  useEffect(() => {
    cleanAllFloatingUI();
    // go through listQuestions and run the getArrowOnElement function for each key
    const cleanups = Object.keys(listQuestions).map((key) => {
      return getArrowOnElement(listQuestions[Number(key)][0], Number(key));
    });
    // convert cleanups from list of list to list of elements
    const cleanupsFlatten = cleanups.flat();
    setCleanUpFloating(cleanupsFlatten);
  }, [listQuestions]); // Depend on listQuestions


  // Function to execute all functions within cleanUpFloating
  function cleanAllFloatingUI() {
    cleanUpFloating.forEach(cleanup => cleanup());
  }


  function getArrowOnElement(element_id: string, key: number) {
    const referenceEls = document.querySelectorAll(`#${element_id}`);
    const floatingEls = document.querySelectorAll(`#floating${key}`);
    const arrowEls = document.querySelectorAll(`#arrow${key}`);

    //const cleanupFunctions = referenceEls.map((referenceEl, i) => {
    const cleanupFunctions = Array.from(referenceEls).map((referenceEl, i) => {
      const floatingEl = floatingEls[i] as HTMLElement;
      const arrowEl = arrowEls[i];
      const cleanup = autoUpdate(referenceEl, floatingEl as HTMLElement, () => {
        computePosition(referenceEl, floatingEl as HTMLElement, {
          placement: "left",
          middleware: [
            offset(ARROW_HEIGHT),
            flip({ padding: 5 }),
            shift({ padding: 5 }),
            arrow({ element: arrowEl })
          ]
        }).then(({ x, y }) => {
          Object.assign(floatingEl.style, {
            left: `${x}px`,
            top: `${y}px`
          });
        });
      });
    
      return cleanup;
    });
    return cleanupFunctions;
  }

  useEffect(() => {

    async function fetchHtml() {
      try {
        // Fetch data from the amplify graphql API table RfpFile
        let file = await API.graphql<GraphQLQuery<GetRfpHtmlDocumentQuery>>(
          { query: queries.getRfpHtmlDocument, variables: { rfpId: id, type: "rfp" } }
        );
        const parser = new DOMParser();
        const doc = parser.parseFromString(file.data?.getRfpHtmlDocument?.html || '', 'text/html');
        let counter = 0;
        const elements = doc.querySelectorAll('*');
        elements.forEach(element => {
          element.id = `qid-${counter++}`;
        });
        let presignedUrlDone: { [key: string]: string } = {};
        const newHtml = await convertResponseToHtml(doc.body.innerHTML, presignedUrlDone, []); // , 'processed-rfp');
        setHtmlContent(newHtml);
        setIsLoading(false);

      } catch (error) {
        console.error('Error fetching document data:', error);
      }
    }
    fetchHtml();
    // console.log("useEffect MAIN");
    async function fetchFile() {
      try {
        // Fetch data from the amplify graphql API table RfpFile
        let file = await API.graphql<GraphQLQuery<GetRfpToAnswerFileQuery>>(
          { query: queries.getRfpToAnswerFile, variables: { id: id } }
        );
        setDocumentData(file.data?.getRfpToAnswerFile);
      } catch (error) {
        console.error('Error fetching document data:', error);
      }
    }
    fetchFile();
    // console.log("fetchFile");
  }, [id]);


  useEffect(() => {
    // console.log("useEffect MouseEvent");
    function handleMouseUp(event: MouseEvent) {

      if (refs.floating.current?.contains(event.target as Element | null)) {
        return;
      }

      setTimeout(() => {
        const selection = window.getSelection();
        const range =
          typeof selection?.rangeCount === "number" && selection.rangeCount > 0
            ? selection.getRangeAt(0)
            : null;

        if (selection?.isCollapsed) {
          setIsOpen(false);
          return;
        }

        if (range) {
          refs.setReference({
            getBoundingClientRect: () => range.getBoundingClientRect(),
            getClientRects: () => range.getClientRects()
          });
          setIsOpen(true);
        }
      });
    }

    function handleMouseDown(event: MouseEvent) {
      if (refs.floating.current?.contains(event.target as Element | null)) {
        return;
      }

      if (window.getSelection()?.isCollapsed) {
        setIsOpen(false);
      }
    }

    const selectableArea = selectableAreaRef.current;
    if (selectableArea) {
      selectableArea.addEventListener("mouseup", handleMouseUp);
      selectableArea.addEventListener("mousedown", handleMouseDown);
    }

    return () => {
      if (selectableArea) {
        selectableArea.removeEventListener("mouseup", handleMouseUp);
        selectableArea.removeEventListener("mousedown", handleMouseDown);
      }
    };
  }, [refs]);


  function getSelectedHtml(): HTMLElement[] | null {
    const selection = window.getSelection();
    // console.log("selection", selection);
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const startContainer = range.startContainer;
      const endContainer = range.endContainer;

      if (startContainer && endContainer) {
        const startElement = startContainer.nodeType === Node.ELEMENT_NODE
          ? startContainer as HTMLElement
          : startContainer.parentElement;
        const endElement = endContainer.nodeType === Node.ELEMENT_NODE
          ? endContainer as HTMLElement
          : endContainer.parentElement;
        // console.log("startContainer", startContainer);
        // console.log("endContainer", endContainer);
        // console.log("startElement", startElement);
        // console.log("endElement", endElement);
        if (startElement && endElement) {
          const elements: HTMLElement[] = [];
          const collectElements = (element: HTMLElement | null) => {
            if (!element) return;
            // do not include element with class ReactModalPortal
            if (element.id.startsWith('qid')) {
              elements.push(element);
            }
            // if (element === endElement) {
            //   console.log("element === endElement");
            //   return;
            // }
            if (element.isSameNode(endElement) || element.contains(endElement)) {
              // console.log("XXXXXXXXXX element is equal to or contains endElement");
              return;
            }
            if (element.nextElementSibling) {
              collectElements(element.nextElementSibling as HTMLElement);
            } else {
              let parent: HTMLElement | null = element.parentElement;
              while (parent && !parent.nextElementSibling) {
                parent = parent.parentElement;
              }
              if (parent && parent.nextElementSibling) {
                collectElements(parent.nextElementSibling as HTMLElement);
              }
            }
          };
          collectElements(startElement);
          return elements //.map(element => element.outerHTML).join('');
        }
      }
    }
    return null;
  }


  const addClassToElement = (htmlString: string, className: string, listElementsId: string[]) => {
    // for all id in listElements, add the class className to the element
    for (const id of listElementsId) {
      const element = document.getElementById(id);
      if (element) {
        element.classList.add(className);
      }
    }
  };

  const removeClassToElement = (htmlString: string, className: string, listElementsId: string[]) => {
    // for all id in listElements, add the class className to the element
    for (const id of listElementsId) {
      const element = document.getElementById(id);
      if (element) {
        element.classList.remove(className);
      }
    }
  };

  function recordQuestion() {
    // console.log("recordQuestion");
    let listElements = getSelectedHtml()
    // console.log("listElements", listElements);

    // Update HTMLContent and replace each elt of listElements with a span with a class
    if (listElements) {
      // console.log("listElements");
      const listElementsId = listElements.map(elementst => elementst.id);
      // if listElementsId is empty or a value is '', return
      if (listElementsId.length === 0 || listElementsId.some(id => id === '')) {
        // console.log("listElementsId", listElementsId);
        // console.log("No element selected");
        alert.showAlert('Element selected outside the document area. Please select another area.');
        return;
      }

      let newKey = 1;
      if (Object.keys(listQuestions).length > 0) {
        // console.log("listQuestions", listQuestions);
        const maxKey = Math.max(...Object.keys(listQuestions).map(Number));
        // console.log("maxKey", maxKey);
        newKey = maxKey + 1;
      }

      // Check if all the listElementsId are contained into one item of listQuestions
      const isAllIdsContained = Object.values(listQuestions).some(array =>
        listElementsId.every(id => array.includes(id))
      );
      //console.log("isAllIdsContained", isAllIdsContained);

      // DELETION HIHGLIGHTS
      if (isAllIdsContained) {
        // get the key of the listQuestions that contains all the listElementsId
        const matchingKeys = Object.entries(listQuestions)
          .filter(([key, array]) => listElementsId.every(id => array.includes(id)))
          .map(([key]) => Number(key));
        //console.log("matchingKeys", matchingKeys);
        for (const key of matchingKeys) {
          // Remove the class from the elements
          removeClassToElement(htmlContent, 'highlightQuestion', listQuestions[key]);
          // Remove the key from the listQuestions
          delete listQuestions[key];
        }
        // Remove the class from the elements
        // removeClassToElement(htmlContent, 'highlightQuestion',listElementsId);
        return;
      }

      // Check listElementId no id already in listQuestions
      const isIdUnique = listElementsId.every(id =>
        !Object.values(listQuestions).some(array => array.includes(id))
      );
      // console.log("isIdUnique", isIdUnique);
      if (!isIdUnique) {
        // console.log("Cannot create Question, already a question in this area. Please select another area.");
        alert.showAlert('Cannot create Question, already a question in this area. Please select another area.');
        return;
      }

      // Create new Question in listQuestions
      // Add the id of the new question to the list of questions
      listQuestions[newKey] = listElementsId;
      setListQuestions({ ...listQuestions });

      // Add the class to the element
      addClassToElement(htmlContent, 'highlightQuestion', listElementsId);
      //console.log("newHTMLContent",newHTMLContent);

    }

  }


  function getModalConfirmation(modalBool: boolean, setModal: Function, callBackFunction: Function, text: string) {
    Modal.setAppElement('#root');

    return (
      <Modal
        isOpen={modalBool}
        style={customReactModalStyles}
      >
        <ThemeProvider theme={studioTheme}>
          <Flex direction={"column"} gap="20px">
            <Heading >{text}</Heading>
            <Flex direction={"row"} alignItems="space-between">
              <Button onClick={() => { callBackFunction(); setModal(false); }}>Yes</Button><Button onClick={() => setModal(false)}>No</Button>
            </Flex>
          </Flex>
        </ThemeProvider>
      </Modal>);
  }


  async function generateQuestions() {
    // console.log("generateQuestions");
    setIsLoadingButton(true);
    // CALL API to generate questions from html
    // params: listQuestions = [], rfpId = id, type= "toanswer_generate"
    try {
      // Fetch data from the amplify graphql API table RfpFile
      const res = await API.graphql<GraphQLQuery<ProcessHtmlRfpDocumentQuery>>(
        { query: queries.processHtmlRfpDocument, variables: { rfpId: id, type: "toanswer_generate", listQuestions: [] } } // toanswer_q_list
      );
      // once completed with success navigate to page
      if (res.data?.processHtmlRfpDocument?.success) {
        navigate("/batch/" + id);
        return 1
      }
      alert.showAlert('Question generation failed.');
    } catch (error) {
      console.error('Error saving html questions:', error);
      alert.showAlert('Error saving html questions.');
    }
    setIsLoadingButton(true);
  }


  // function that gets the html text from a tag id
  function getHtmlFromTagId(tagId: string) {
    const element = document.getElementById(tagId);
    // remove class and id from the element
    if (element) {
      element.classList.remove('highlightQuestion');
      element.removeAttribute('id');
      return element.outerHTML;
    }
    return "";
  }


  async function uploadQuestions() {
    // console.log("uploadQuestions");
    setIsLoadingButton(true);
    // Convert listQuestions (Record<number, string[]>) to variable listQuestionsToUpload ({question: text}[])
    // listQuestions contains the list of tag id for each question, we need to convert it to a text concatenated with a breakline
    let listQuestionsToUpload = [];
    for (const key in listQuestions) {
      const question = listQuestions[key].map(tagId => getHtmlFromTagId(tagId)).join("<br>");
      listQuestionsToUpload.push({ question: convertImagePathBeforeSaving(question), answer: '' });
    }

    // if listQuestionsToUpload is empty, return
    if (listQuestionsToUpload.length === 0) {
      alert.showAlert('No question selected.');
      setIsLoadingButton(false);
      return;
    }
    // console.log("listQuestionsToUpload", listQuestionsToUpload);
    // setIsLoadingButton(false);
    // return 1;

    // CALL API to generate questions from html
    // params: listQuestions = [], rfpId = id, type= "toanswer_generate"
    try {
      // Fetch data from the amplify graphql API table RfpFile
      const res = await API.graphql<GraphQLQuery<ProcessHtmlRfpDocumentQuery>>(
        { query: queries.processHtmlRfpDocument, variables: { rfpId: id, type: "toanswer_q_list", listQuestions: listQuestionsToUpload } } // toanswer_q_list
      );
      // console.log("res", res.data?.processHtmlRfpDocument);
      // once completed with success navigate to page
      if (res.data?.processHtmlRfpDocument?.success) {
        navigate("/batch/" + id);
        return 1
      }
      alert.showAlert('Question generation failed.');
    } catch (error) {
      console.error('Error saving html questions:', error);
      alert.showAlert('Error saving html questions.');
    }
    setIsLoadingButton(false);
  }


  // Function to test if a string starts with "#.#" or a letter [a-zA-Z]
  function testStringStart(str: string) {
    // Regex pattern to match the string starting with "#.#" or [a-zA-Z]
    const pattern = /^(?:\d+\.?|[a-zA-Z][\).]? )/;
    // Test the pattern against the provided string
    return pattern.test(str);
  }


  function findSimilarTags() {
    // console.log("findSimilarTags");
    let listElements = getSelectedHtml()
    // console.log("listElements", listElements);

    // Find tag and content of first element.
    if (listElements && listElements.length > 0) {
      // console.log(listElements[0])
      const firstElement = listElements[0];
      const tag = firstElement.tagName;
      const content = firstElement.textContent;
      // console.log("tag", tag);
      // console.log("content", content);

      // If tag equals h1, h2, h3, h4, h5, h6, ul, li then search for similar tags
      if (["H1", "H2", "H3", "H4", "H5", "H6", "LI"].includes(tag)) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const elements = doc.querySelectorAll(tag);
        const similarElements: string[] = [];

        elements.forEach(element => {
          // Check if the element contains any child elements of the same tag
          if (element.querySelectorAll(tag).length === 0) {
            similarElements.push(element.id);
          }
        });

        let listNewQuestions: Record<number, string[]> = {};
        similarElements.forEach((id, index) => {
          listNewQuestions[index + 1] = [id];
        });

        // REMOVE PREVIOUS LISTQUESTIONS
        const matchingKeys = Object.entries(listQuestions)
          .map(([key]) => Number(key));
        for (const key of matchingKeys) {
          removeClassToElement(htmlContent, 'highlightQuestion', listQuestions[key]);
        }

        setListQuestions({ ...listNewQuestions });
        addClassToElement(htmlContent, 'highlightQuestion', similarElements);
      }
      else if (tag === "P" || tag === "STRONG") {
        // in the htmlContent, find all the tags with the same tag and content
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const elements = doc.querySelectorAll(tag);
        const boolPatternDetected = testStringStart(content || "");

        const similarElements: string[] = [];
        if (boolPatternDetected) {
          elements.forEach(element => {
            if (testStringStart(element.textContent || "")) {
              similarElements.push(element.id);
            }
          });
        }
        else if (elements.length < 15) {
          elements.forEach(element => {
            similarElements.push(element.id);
          });
        } else {
          // console.log("#1 The system could not identify similar tags");
          alert.showAlert('The system could not identify similar tags');
        }
        // console.log("similarElements", similarElements);

        // Create new Question in listQuestions
        // Add the id of the new question to the list of questions
        let listNewQuestions: Record<number, string[]> = {};
        similarElements.forEach((id, index) => {
          listNewQuestions[index + 1] = [id];
        });

        // REMOVE PREVIOUS LISTQUESTIONS 
        const matchingKeys = Object.entries(listQuestions)
          .map(([key]) => Number(key));
        for (const key of matchingKeys) {
          removeClassToElement(htmlContent, 'highlightQuestion', listQuestions[key]);
        }

        //console.log("listQuestions", listQuestions);
        setListQuestions({ ...listNewQuestions });
        // Add the class to the element
        addClassToElement(htmlContent, 'highlightQuestion', similarElements);
      }
      else {
        // console.log("#2 The system could not identify similar tags");
        alert.showAlert('The system could not identify similar tags');
      }
    }
    else {
      alert.showAlert('No element selected');
    }

  }


  return (
    <Page title="Doc Highlights">
      <Flex gap="20px" direction={"column"}>
        <Breadcrumbs
          items={[
            {
              href: '/',
              label: 'Home',
            },
            {
              href: '/batch',
              label: 'batch',
            },
            {
              href: '/batch/' + id,
              label: documentData?.name,
            }
          ]}
        />
        {getModalConfirmation(modalConfirmationSimilarIsOpen, setModalConfirmationSimilarIsOpen, findSimilarTags, "This will generate questions based on similar elements. Previously highlighed questions will be erased. Are you sure you want to continue? ")}
        {getModalConfirmation(modalConfirmationGenerateIsOpen, setModalConfirmationGenerateIsOpen, generateQuestions, "This will let the system generate questions from the text below. Any highlighted questions will be ignored. Are you sure you want to continue? ")}
        {getModalConfirmation(modalConfirmationSelectedIsOpen, setModalConfirmationSelectedIsOpen, uploadQuestions, "Please confirm that you want to save the highlighted questions.")}
        {Object.keys(listQuestions).map((key) => (
          <div id={"floating" + key} className="floating" key={key} style={{
            background: "grey",
            color: "white",
            padding: 4
          }}>
            Q{key}
            {/* <div id={"arrow" + key} className="arrow"></div> */}
          </div>
        ))}
        <snackbar.AlertComponent />
        <alert.AlertComponent />
        {isOpen && (
          <div
            ref={refs.setFloating}
            style={{
              ...floatingStyles,
              background: "grey",
              color: "white",
              padding: 4
            }}
            {...getFloatingProps()}
          >
            <Button size='small' onClick={() => recordQuestion()}>Add/Remove Question</Button> or <Button size='small' onClick={() => { setModalConfirmationSimilarIsOpen(true) }}>Find Similar Items</Button>
          </div>
        )}
        <Card>
          <Flex direction="column" alignItems="flex-start">
            <Heading level={4}>Document Item Selection</Heading>
            <Text
              variation="primary"
              as="p"
              lineHeight="1.5em"
              fontWeight={400}
              fontSize="1em"
              fontStyle="normal"
              textDecoration="none"
            >
              Use this page to extract questions from the document read by the system. You can highlight questions directly or let the system decide for you.
            </Text>
          </Flex>
        </Card>
        <Flex gap="20px" direction={"row"} alignItems="strech"  >
          <Card>
            <Flex direction="column" alignItems="flex-start" >
              <Heading level={5}>Highlight questions directly</Heading>
              <Text
                variation="primary"
                as="p"
                lineHeight="1.5em"
                fontWeight={400}
                fontSize="1em"
                fontStyle="normal"
                textDecoration="none"
              >
                Highlight questions in document below and click here to create items <Button isLoading={isLoadingButton} variation='primary' onClick={() => setModalConfirmationSelectedIsOpen(true)}>Save Selected</Button>
              </Text>
            </Flex>
          </Card>
          <Card>
            <Flex direction="column" alignItems="flex-start">
              <Heading level={5}>Or let the system decide for you</Heading>
              <Text
                variation="primary"
                as="p"
                lineHeight="1.5em"
                fontWeight={400}
                fontSize="1em"
                fontStyle="normal"
                textDecoration="none"
              >Click here to extract questions automatically <Button isLoading={isLoadingButton} variation='primary' onClick={() => setModalConfirmationGenerateIsOpen(true)}>Auto Generate</Button>
              </Text>
            </Flex>
          </Card>
        </Flex>
        <Flex justifyContent="center" alignItems="center" width="90%">
          <Card width="90%">
            <Flex justifyContent="left" alignItems="center" ref={selectableAreaRef}>
              {isLoading ? (<Placeholder size="large" height={"200px"} />) :
                (
                  <div>
                    {parse(htmlContent)}
                  </div>
                )
              }
            </Flex>
          </Card>
        </Flex>
      </Flex>
    </Page>
  );
}