/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateMessageAvatar = /* GraphQL */ `subscription OnCreateMessageAvatar(
  $filter: ModelSubscriptionMessageAvatarFilterInput
  $owner: String
) {
  onCreateMessageAvatar(filter: $filter, owner: $owner) {
    id
    msg
    type
    datetime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMessageAvatarSubscriptionVariables,
  APITypes.OnCreateMessageAvatarSubscription
>;
export const onUpdateMessageAvatar = /* GraphQL */ `subscription OnUpdateMessageAvatar(
  $filter: ModelSubscriptionMessageAvatarFilterInput
  $owner: String
) {
  onUpdateMessageAvatar(filter: $filter, owner: $owner) {
    id
    msg
    type
    datetime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMessageAvatarSubscriptionVariables,
  APITypes.OnUpdateMessageAvatarSubscription
>;
export const onDeleteMessageAvatar = /* GraphQL */ `subscription OnDeleteMessageAvatar(
  $filter: ModelSubscriptionMessageAvatarFilterInput
  $owner: String
) {
  onDeleteMessageAvatar(filter: $filter, owner: $owner) {
    id
    msg
    type
    datetime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMessageAvatarSubscriptionVariables,
  APITypes.OnDeleteMessageAvatarSubscription
>;
export const onCreateRfpFile = /* GraphQL */ `subscription OnCreateRfpFile($filter: ModelSubscriptionRfpFileFilterInput) {
  onCreateRfpFile(filter: $filter) {
    id
    name
    type
    s3Bucket
    s3Key
    status
    size
    nbQuestions
    datetime
    strategy {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      strategyRfpFilesId
      __typename
    }
    isExcluded
    items {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    rfpFileStrategyId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateRfpFileSubscriptionVariables,
  APITypes.OnCreateRfpFileSubscription
>;
export const onUpdateRfpFile = /* GraphQL */ `subscription OnUpdateRfpFile($filter: ModelSubscriptionRfpFileFilterInput) {
  onUpdateRfpFile(filter: $filter) {
    id
    name
    type
    s3Bucket
    s3Key
    status
    size
    nbQuestions
    datetime
    strategy {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      strategyRfpFilesId
      __typename
    }
    isExcluded
    items {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    rfpFileStrategyId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateRfpFileSubscriptionVariables,
  APITypes.OnUpdateRfpFileSubscription
>;
export const onDeleteRfpFile = /* GraphQL */ `subscription OnDeleteRfpFile($filter: ModelSubscriptionRfpFileFilterInput) {
  onDeleteRfpFile(filter: $filter) {
    id
    name
    type
    s3Bucket
    s3Key
    status
    size
    nbQuestions
    datetime
    strategy {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      strategyRfpFilesId
      __typename
    }
    isExcluded
    items {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    rfpFileStrategyId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteRfpFileSubscriptionVariables,
  APITypes.OnDeleteRfpFileSubscription
>;
export const onCreateRfpFileItem = /* GraphQL */ `subscription OnCreateRfpFileItem(
  $filter: ModelSubscriptionRfpFileItemFilterInput
) {
  onCreateRfpFileItem(filter: $filter) {
    id
    rfpFileId
    rfpFile {
      id
      name
      type
      s3Bucket
      s3Key
      status
      size
      nbQuestions
      datetime
      isExcluded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      rfpFileStrategyId
      __typename
    }
    question
    answer
    status
    faissId
    isExcluded
    datetime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateRfpFileItemSubscriptionVariables,
  APITypes.OnCreateRfpFileItemSubscription
>;
export const onUpdateRfpFileItem = /* GraphQL */ `subscription OnUpdateRfpFileItem(
  $filter: ModelSubscriptionRfpFileItemFilterInput
) {
  onUpdateRfpFileItem(filter: $filter) {
    id
    rfpFileId
    rfpFile {
      id
      name
      type
      s3Bucket
      s3Key
      status
      size
      nbQuestions
      datetime
      isExcluded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      rfpFileStrategyId
      __typename
    }
    question
    answer
    status
    faissId
    isExcluded
    datetime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateRfpFileItemSubscriptionVariables,
  APITypes.OnUpdateRfpFileItemSubscription
>;
export const onDeleteRfpFileItem = /* GraphQL */ `subscription OnDeleteRfpFileItem(
  $filter: ModelSubscriptionRfpFileItemFilterInput
) {
  onDeleteRfpFileItem(filter: $filter) {
    id
    rfpFileId
    rfpFile {
      id
      name
      type
      s3Bucket
      s3Key
      status
      size
      nbQuestions
      datetime
      isExcluded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      rfpFileStrategyId
      __typename
    }
    question
    answer
    status
    faissId
    isExcluded
    datetime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteRfpFileItemSubscriptionVariables,
  APITypes.OnDeleteRfpFileItemSubscription
>;
export const onCreateStrategy = /* GraphQL */ `subscription OnCreateStrategy($filter: ModelSubscriptionStrategyFilterInput) {
  onCreateStrategy(filter: $filter) {
    id
    name
    rfpFiles {
      id
      name
      type
      s3Bucket
      s3Key
      status
      size
      nbQuestions
      datetime
      isExcluded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      rfpFileStrategyId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    strategyRfpFilesId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateStrategySubscriptionVariables,
  APITypes.OnCreateStrategySubscription
>;
export const onUpdateStrategy = /* GraphQL */ `subscription OnUpdateStrategy($filter: ModelSubscriptionStrategyFilterInput) {
  onUpdateStrategy(filter: $filter) {
    id
    name
    rfpFiles {
      id
      name
      type
      s3Bucket
      s3Key
      status
      size
      nbQuestions
      datetime
      isExcluded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      rfpFileStrategyId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    strategyRfpFilesId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateStrategySubscriptionVariables,
  APITypes.OnUpdateStrategySubscription
>;
export const onDeleteStrategy = /* GraphQL */ `subscription OnDeleteStrategy($filter: ModelSubscriptionStrategyFilterInput) {
  onDeleteStrategy(filter: $filter) {
    id
    name
    rfpFiles {
      id
      name
      type
      s3Bucket
      s3Key
      status
      size
      nbQuestions
      datetime
      isExcluded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      rfpFileStrategyId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    strategyRfpFilesId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteStrategySubscriptionVariables,
  APITypes.OnDeleteStrategySubscription
>;
export const onCreateFaqItem = /* GraphQL */ `subscription OnCreateFaqItem($filter: ModelSubscriptionFaqItemFilterInput) {
  onCreateFaqItem(filter: $filter) {
    id
    question
    answer
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFaqItemSubscriptionVariables,
  APITypes.OnCreateFaqItemSubscription
>;
export const onUpdateFaqItem = /* GraphQL */ `subscription OnUpdateFaqItem($filter: ModelSubscriptionFaqItemFilterInput) {
  onUpdateFaqItem(filter: $filter) {
    id
    question
    answer
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFaqItemSubscriptionVariables,
  APITypes.OnUpdateFaqItemSubscription
>;
export const onDeleteFaqItem = /* GraphQL */ `subscription OnDeleteFaqItem($filter: ModelSubscriptionFaqItemFilterInput) {
  onDeleteFaqItem(filter: $filter) {
    id
    question
    answer
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFaqItemSubscriptionVariables,
  APITypes.OnDeleteFaqItemSubscription
>;
export const onCreateUserQuestion = /* GraphQL */ `subscription OnCreateUserQuestion(
  $filter: ModelSubscriptionUserQuestionFilterInput
) {
  onCreateUserQuestion(filter: $filter) {
    id
    requestId
    question
    answer
    documents {
      id
      rfpFileId
      rfpFileName
      question
      answer
      status
      isExcluded
      datetime
      score
      __typename
    }
    generationCompleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserQuestionSubscriptionVariables,
  APITypes.OnCreateUserQuestionSubscription
>;
export const onUpdateUserQuestion = /* GraphQL */ `subscription OnUpdateUserQuestion(
  $filter: ModelSubscriptionUserQuestionFilterInput
) {
  onUpdateUserQuestion(filter: $filter) {
    id
    requestId
    question
    answer
    documents {
      id
      rfpFileId
      rfpFileName
      question
      answer
      status
      isExcluded
      datetime
      score
      __typename
    }
    generationCompleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserQuestionSubscriptionVariables,
  APITypes.OnUpdateUserQuestionSubscription
>;
export const onDeleteUserQuestion = /* GraphQL */ `subscription OnDeleteUserQuestion(
  $filter: ModelSubscriptionUserQuestionFilterInput
) {
  onDeleteUserQuestion(filter: $filter) {
    id
    requestId
    question
    answer
    documents {
      id
      rfpFileId
      rfpFileName
      question
      answer
      status
      isExcluded
      datetime
      score
      __typename
    }
    generationCompleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserQuestionSubscriptionVariables,
  APITypes.OnDeleteUserQuestionSubscription
>;
export const onCreateUserQuestionCompliance = /* GraphQL */ `subscription OnCreateUserQuestionCompliance(
  $filter: ModelSubscriptionUserQuestionComplianceFilterInput
) {
  onCreateUserQuestionCompliance(filter: $filter) {
    id
    msg
    requestId
    warnings {
      extracted_data
      description
      __typename
    }
    generationCompleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserQuestionComplianceSubscriptionVariables,
  APITypes.OnCreateUserQuestionComplianceSubscription
>;
export const onUpdateUserQuestionCompliance = /* GraphQL */ `subscription OnUpdateUserQuestionCompliance(
  $filter: ModelSubscriptionUserQuestionComplianceFilterInput
) {
  onUpdateUserQuestionCompliance(filter: $filter) {
    id
    msg
    requestId
    warnings {
      extracted_data
      description
      __typename
    }
    generationCompleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserQuestionComplianceSubscriptionVariables,
  APITypes.OnUpdateUserQuestionComplianceSubscription
>;
export const onDeleteUserQuestionCompliance = /* GraphQL */ `subscription OnDeleteUserQuestionCompliance(
  $filter: ModelSubscriptionUserQuestionComplianceFilterInput
) {
  onDeleteUserQuestionCompliance(filter: $filter) {
    id
    msg
    requestId
    warnings {
      extracted_data
      description
      __typename
    }
    generationCompleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserQuestionComplianceSubscriptionVariables,
  APITypes.OnDeleteUserQuestionComplianceSubscription
>;
export const onCreateRfpToAnswerFile = /* GraphQL */ `subscription OnCreateRfpToAnswerFile(
  $filter: ModelSubscriptionRfpToAnswerFileFilterInput
) {
  onCreateRfpToAnswerFile(filter: $filter) {
    id
    name
    type
    s3Bucket
    s3Key
    status
    size
    nbQuestions
    datetime
    items {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateRfpToAnswerFileSubscriptionVariables,
  APITypes.OnCreateRfpToAnswerFileSubscription
>;
export const onUpdateRfpToAnswerFile = /* GraphQL */ `subscription OnUpdateRfpToAnswerFile(
  $filter: ModelSubscriptionRfpToAnswerFileFilterInput
) {
  onUpdateRfpToAnswerFile(filter: $filter) {
    id
    name
    type
    s3Bucket
    s3Key
    status
    size
    nbQuestions
    datetime
    items {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateRfpToAnswerFileSubscriptionVariables,
  APITypes.OnUpdateRfpToAnswerFileSubscription
>;
export const onDeleteRfpToAnswerFile = /* GraphQL */ `subscription OnDeleteRfpToAnswerFile(
  $filter: ModelSubscriptionRfpToAnswerFileFilterInput
) {
  onDeleteRfpToAnswerFile(filter: $filter) {
    id
    name
    type
    s3Bucket
    s3Key
    status
    size
    nbQuestions
    datetime
    items {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteRfpToAnswerFileSubscriptionVariables,
  APITypes.OnDeleteRfpToAnswerFileSubscription
>;
export const onCreateRfpToAnswerFileItem = /* GraphQL */ `subscription OnCreateRfpToAnswerFileItem(
  $filter: ModelSubscriptionRfpToAnswerFileItemFilterInput
) {
  onCreateRfpToAnswerFileItem(filter: $filter) {
    id
    rfpToAnswerFileId
    rfpToAnswerFile {
      id
      name
      type
      s3Bucket
      s3Key
      status
      size
      nbQuestions
      datetime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    question
    answer
    datetime
    documents
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateRfpToAnswerFileItemSubscriptionVariables,
  APITypes.OnCreateRfpToAnswerFileItemSubscription
>;
export const onUpdateRfpToAnswerFileItem = /* GraphQL */ `subscription OnUpdateRfpToAnswerFileItem(
  $filter: ModelSubscriptionRfpToAnswerFileItemFilterInput
) {
  onUpdateRfpToAnswerFileItem(filter: $filter) {
    id
    rfpToAnswerFileId
    rfpToAnswerFile {
      id
      name
      type
      s3Bucket
      s3Key
      status
      size
      nbQuestions
      datetime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    question
    answer
    datetime
    documents
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateRfpToAnswerFileItemSubscriptionVariables,
  APITypes.OnUpdateRfpToAnswerFileItemSubscription
>;
export const onDeleteRfpToAnswerFileItem = /* GraphQL */ `subscription OnDeleteRfpToAnswerFileItem(
  $filter: ModelSubscriptionRfpToAnswerFileItemFilterInput
) {
  onDeleteRfpToAnswerFileItem(filter: $filter) {
    id
    rfpToAnswerFileId
    rfpToAnswerFile {
      id
      name
      type
      s3Bucket
      s3Key
      status
      size
      nbQuestions
      datetime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    question
    answer
    datetime
    documents
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteRfpToAnswerFileItemSubscriptionVariables,
  APITypes.OnDeleteRfpToAnswerFileItemSubscription
>;
export const onCreateComplianceCheck = /* GraphQL */ `subscription OnCreateComplianceCheck {
  onCreateComplianceCheck {
    warnings {
      extracted_data
      description
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateComplianceCheckSubscriptionVariables,
  APITypes.OnCreateComplianceCheckSubscription
>;
