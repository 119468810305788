/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Text, useBreakpointValue } from "@aws-amplify/ui-react";
import MyIconSet from "./MyIconSet";
export default function NavBarHeader(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        RFP: {},
        "Amplify Mark": {},
        AccountDropdownContainer: {},
        MyIconSet: {},
        FrameMenu: {},
        "Frame 443": {},
        "Frame 321": {},
        NavBarHeader: {},
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        RFP: {},
        "Amplify Mark": {},
        AccountDropdownContainer: { display: "none" },
        MyIconSet: { width: "39px", height: "40px" },
        FrameMenu: {
          direction: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          display: "flex",
        },
        "Frame 443": { width: "unset" },
        "Frame 321": {},
        NavBarHeader: {
          width: "334px",
          height: "unset",
          justifyContent: "space-between",
          padding: "16px 15px 16px 15px",
        },
      },
      variantValues: { breakpoint: "small" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="40px"
      direction="row"
      width="1399px"
      height="62px"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="16px 32px 16px 32px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...getOverrideProps(overrides, "NavBarHeader")}
      {...rest}
    >
      <Flex
        gap="32px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 321")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="30px"
          justifyContent="space-between"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Amplify Mark")}
        >
          <Text
            fontFamily="Inter"
            fontSize="32px"
            fontWeight="800"
            color="rgba(149,4,4,1)"
            lineHeight="48px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="RFP"
            {...getOverrideProps(overrides, "RFP")}
          ></Text>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          width="274px"
          height="40px"
          justifyContent="flex-end"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 443")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "AccountDropdownContainer")}
          ></Flex>
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="none"
            {...getOverrideProps(overrides, "FrameMenu")}
          >
            <MyIconSet
              width="24px"
              height="24px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              type="menu"
              {...getOverrideProps(overrides, "MyIconSet")}
            ></MyIconSet>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
