/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import {
  Badge,
  Button,
  Flex,
  Text,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
import DownloadIcon from "./DownloadIcon";
export default function FileListRow(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        FrameButton: {},
        FileText: {},
        FileCol: {},
        DownloadIcon: {},
        "Frame 439": {},
        FrameFile: {},
        StatusBadge: {},
        StatusCol: {},
        SizeText: {},
        SizeCol: {},
        NbQuestionsText: {},
        NbQuestionsCol: {},
        UpdatedText: {},
        UpdatedCol: {},
        FrameMetadata: {},
        "Frame 442": {},
        FileListRow: {},
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        FrameButton: {},
        FileText: {},
        FileCol: {},
        DownloadIcon: {},
        "Frame 439": {},
        FrameFile: {},
        StatusBadge: {},
        StatusCol: {},
        SizeText: {},
        SizeCol: {},
        NbQuestionsText: {},
        NbQuestionsCol: {},
        UpdatedText: {},
        UpdatedCol: {},
        FrameMetadata: {
          shrink: "0",
          overflow: "hidden",
          alignSelf: "stretch",
        },
        "Frame 442": {
          gap: "5px",
          direction: "column",
          height: "90px",
          justifyContent: "center",
          alignItems: "flex-start",
        },
        FileListRow: {
          gap: "5px",
          justifyContent: "flex-start",
          padding: "9px 9px 9px 9px",
        },
      },
      variantValues: { breakpoint: "small" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="1058px"
      height="unset"
      justifyContent="space-between"
      alignItems="center"
      overflow="hidden"
      position="relative"
      border="1px SOLID rgba(250,250,250,1)"
      borderRadius="10px"
      padding="9px 39px 9px 39px"
      backgroundColor="rgba(250,250,250,1)"
      display="flex"
      {...getOverrideProps(overrides, "FileListRow")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 442")}
      >
        <Flex
          gap="5px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "FrameFile")}
        >
          <Flex
            gap="5px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(250,250,250,1)"
            display="flex"
            {...getOverrideProps(overrides, "FrameButton")}
          ></Flex>
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="10px 0px 10px 0px"
            backgroundColor="rgba(250,250,250,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame 439")}
          >
            <Flex
              gap="0"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "FileCol")}
            >
              <Button
                width="unset"
                height="unset"
                shrink="0"
                size="small"
                isDisabled={false}
                variation="link"
                children="FileName"
                {...getOverrideProps(overrides, "FileText")}
              ></Button>
            </Flex>
            <DownloadIcon
              width="24px"
              height="24px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "DownloadIcon")}
            ></DownloadIcon>
          </Flex>
        </Flex>
        <Flex
          gap="100px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="space-between"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "FrameMetadata")}
        >
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "StatusCol")}
          >
            <Badge
              width="unset"
              height="unset"
              shrink="0"
              size="default"
              variation="success"
              {...getOverrideProps(overrides, "StatusBadge")}
            ></Badge>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "SizeCol")}
          >
            <Text
              fontFamily="Inter"
              fontSize="12px"
              fontWeight="400"
              color="rgba(48,64,80,1)"
              lineHeight="18px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="- Mb"
              {...getOverrideProps(overrides, "SizeText")}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "NbQuestionsCol")}
          >
            <Text
              fontFamily="Inter"
              fontSize="12px"
              fontWeight="400"
              color="rgba(48,64,80,1)"
              lineHeight="18px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="# Questions"
              {...getOverrideProps(overrides, "NbQuestionsText")}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "UpdatedCol")}
          >
            <Text
              fontFamily="Inter"
              fontSize="12px"
              fontWeight="400"
              color="rgba(48,64,80,1)"
              lineHeight="18px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="LastUpdated"
              {...getOverrideProps(overrides, "UpdatedText")}
            ></Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
