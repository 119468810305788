import { Alert, AlertVariations, Flex } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";

export const useAlert = ({ variation }: { variation: AlertVariations }) => {
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertVariation, setAlertVariation] = useState(variation);

  const showAlert = (text: string, newVariation?: AlertVariations) => {
    setAlertText(text);
    setIsAlertVisible(true);
    if (newVariation) {
      setAlertVariation(newVariation);
    }
  };
  
  // useEffect(() => {
  //   if (isAlertVisible) {
  //     const timeoutID = setTimeout(() => {
  //       setIsAlertVisible(false);
  //     }, 35000);
  //     return () => {
  //       clearTimeout(timeoutID);
  //     };
  //   }
  // }, [isAlertVisible]);

  const alert = () => (
    <Flex
      direction="column"
      justifyContent={"center"}
      alignItems={"center"}
      display={isAlertVisible ? "flex" : "none"}
      position={"fixed"}
      bottom={"1rem"}
      left={"0px"}
      right={"0px"}
      width={"100%"}
      style={{ zIndex: 2 }}
    >
      <Alert isDismissible onDismiss={() => setIsAlertVisible(false)} variation={alertVariation}>
        {alertText}
      </Alert>
    </Flex>
  );
  
  return { showAlert, AlertComponent: alert };
};
