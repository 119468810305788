import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { useAlert } from '../hooks/useAlert';
import { useParams } from 'react-router-dom';
import { SelectField, Flex, View, Collection, Card, Text, Heading, Button, ButtonGroup, TextAreaField, Breadcrumbs, Badge, Placeholder, Accordion } from '@aws-amplify/ui-react';
import Page from '../components/Page';
import { formatFileSize, formatNbQuestions, formatDateISO, copyToClipboard, getIsExcludedText, getIsExcludedVariation, customReactSmallModalStyles, customReactModalStyles, editorKey, tinymceConfig, convertResponseToHtml, convertImagePathBeforeSaving } from '../utils';
//import DocumentLayout from '../ui-components/DocumentLayout';
import ToAnswerItemLayout from '../ui-components/ToAnswerItemLayout';
import RfpItemLayout from '../ui-components/RfpItemLayout';
import Number from '../ui-components/Number';
import Modal from 'react-modal';
import { studioTheme } from '../ui-components';
import { ThemeProvider } from '@aws-amplify/ui-react';
// Html processing
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import parse from 'html-react-parser';
// GraphQL imports
import { generateClient } from 'aws-amplify/api';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
// import * as subscriptions from '../graphql/subscriptions';
import { GraphQLQuery } from '@aws-amplify/api';
import { Strategy, ListStrategiesQuery, OnUpdateUserQuestionSubscriptionVariables, OnUpdateUserQuestionSubscription, RemoveQuestionKnowledgeBaseQuery, AddBackQuestionKnowledgeBaseQuery, DeleteQuestionQuery, UpdateQuestionKnowledgeBaseQuery, ListRfpFileItemsQuery, GetRfpToAnswerFileQuery, DeleteRfpDocumentToAnswerQuery, DeleteRfpDocumentToAnswerItemQuery, ResponsePresignedGetQuery, ListRfpToAnswerFileItemsQuery, GetRfpQuestionResponseQuery, OnCreateUserQuestionSubscriptionVariables, UserQuestion, ResponseDocumentsScore } from '../API';
import { GraphQLSubscription } from '@aws-amplify/api';
import * as subscriptions from '../graphql/subscriptions';
import { OnCreateUserQuestionSubscription } from '../API';
import DownloadIcon from '../ui-components/DownloadIcon';
import MyIcon from '../ui-components/MyIcon';
import MyIconSet from '../ui-components/MyIconSet';


export default function BatchDocument() {
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const editorRfpItemRef = useRef<TinyMCEEditor | null>(null);
  const editorRefQuestion = useRef<TinyMCEEditor | null>(null);
  const alert = useAlert({ variation: 'error' });
  const snackbar = useAlert({ variation: 'success' });
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [documentData, setDocumentData] = useState<any>(null);
  const [rfpItems, setRfpItems] = useState<any>();
  const [rfpItemsLoading, setRfpItemsLoading] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalBatchConfirmatonDeleteIsOpen, setModalBatchConfirmatonDeleteIsOpen] = useState(false);
  const [modalBatchFileDeleteConfirmatonIsOpen, setModalBatchFileDeleteConfirmatonIsOpen] = useState(false);
  const [modalBatchConfirmatonAddIsOpen, setModalBatchConfirmatonAddIsOpen] = useState(false);
  const [modalBatchConfirmatonEditIsOpen, setModalBatchConfirmatonEditIsOpen] = useState(false);
  const [deleteBatchQuestionId, setDeleteBatchQuestionId] = useState<string>("");
  const [editBatchQuestionId, setEditBatchQuestionId] = useState<string>("");
  const [questionBatchValue, setQuestionBatchValue] = useState<string>("");
  const [answerBatchValue, setAnswerBatchValue] = useState<string>("");
  // For existing question management
  const [modalConfirmatonDeleteIsOpen, setModalConfirmatonDeleteIsOpen] = useState(false);
  const [modalConfirmatonRemoveIsOpen, setModalConfirmatonRemoveIsOpen] = useState(false);
  const [modalConfirmatonEditIsOpen, setModalConfirmatonEditIsOpen] = useState(false);
  const [questionValue, setQuestionValue] = useState<string>("");
  const [answerValue, setAnswerValue] = useState<string>("");
  const [editQuestionId, setEditQuestionId] = useState<string>("");
  const [removeQuestionId, setRemoveQuestionId] = useState<string>("");
  const [deleteQuestionId, setDeleteQuestionId] = useState<string>("");
  const [removeQuestionIsExcluded, setRemoveQuestionIsExcluded] = useState<boolean>(false);
  const [selectedStrategy, setSelectedStrategy] = useState<string>("all");
  const [listStrategy, setListStrategy] = useState<any>(null);
  const [modalFullGenerationIsOpen, setModalFullGenerationIsOpen] = useState(false);
  const [modalSingleGenerationIsOpen, setModalSingleGenerationIsOpen] = useState(false);
  const [questionIdSelected, setQuestionIdSelected] = useState<string>("");
  const [questionSelected, setQuestionSelected] = useState<string>("");

  const API = generateClient();

  useEffect(() => {

    async function fetchAllRfpItems(id: string, nextToken: string | null = null): Promise<any> {
      let result = await API.graphql<GraphQLQuery<ListRfpToAnswerFileItemsQuery>>({
        query: queries.listRfpToAnswerFileItems, variables: {
          filter: {
            rfpToAnswerFileId: {
              eq: id
            }
          },
          nextToken
        }
      });

      if (result?.data?.listRfpToAnswerFileItems?.nextToken) {
        const nextResult = await fetchAllRfpItems(id, result.data.listRfpToAnswerFileItems.nextToken);
        // Combine result and nextResult
        if (nextResult.data.listRfpToAnswerFileItems.items) {
          result.data.listRfpToAnswerFileItems.items = result.data.listRfpToAnswerFileItems.items.concat(nextResult.data.listRfpToAnswerFileItems.items);
        }

      }

      return result;
    }
    async function fetchFiles() {
      try {
        // Fetch data from the amplify graphql API table RfpFile
        let file = await API.graphql<GraphQLQuery<GetRfpToAnswerFileQuery>>(
          { query: queries.getRfpToAnswerFile, variables: { id: id } }
        );
        setDocumentData(file.data?.getRfpToAnswerFile);

        if (!id) {
          return null;
        }
        const rfpItems = await fetchAllRfpItems(id);
        let litems = rfpItems.data?.listRfpToAnswerFileItems?.items;
        if (litems) {
          litems.sort((a: any, b: any) => {
            if (a !== null && b !== null) {
              if (a?.datetime > b?.datetime) {
                return 1;
              } else if (a?.datetime < b?.datetime) {
                return -1;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          });
        }
        const items = litems

        // record image with presigned url
        let presignedUrlDone: { [key: string]: string } = {};

        if (items) {
          const processItems = async () => {

            // GET SOURCES DETAILS ############################################################################################################
            // go thrhough each item and get details from documents
            const promises2 = items.map(async (item: any) => {
              if (item?.documents) {
                const sources = await getDetailsFromDocuments(item.documents);
                item.sources = sources;
              }
              else {
                item.sources = [];
              }
            });
            // Wait for all promises to resolve
            await Promise.all(promises2);

            // UPDATE IMAGE TAGS ############################################################################################################
            const promises = items.map(async (item: any) => {
              if (item.answer && item.answer.includes("@#@")) {
                item.answer = (item.answer).split("@#@")[0];
              }

              const newHtmlAnswer = await convertResponseToHtml(item.answer, presignedUrlDone, item.sources); // ,'processed-rfp');
              item.answer = newHtmlAnswer;
              const newHtmlQuestion = await convertResponseToHtml(item.question, presignedUrlDone, item.sources); // ,'processed-rfp');
              item.question = newHtmlQuestion;
            });
            // Wait for all promises to resolve
            await Promise.all(promises);

          };

          // Call the async function
          processItems().then(() => {
            // All promises have resolved, you can continue with the next lines of code here
            setRfpItems(rfpItems.data?.listRfpToAnswerFileItems?.items);

            // for each element of rfpItems create a new element with only id attribute and a new attribue isloading as false
            if (rfpItemsLoading === null) {
              setRfpItemsLoading(rfpItems.data?.listRfpToAnswerFileItems?.items?.map((item: any) => {
                return { id: item.id, isLoading: false };
              }));
            } else {
              setRfpItemsLoading(rfpItems.data?.listRfpToAnswerFileItems?.items?.map((item: any) => {
                const existingItem = rfpItemsLoading.find((loadingItem: any) => loadingItem.id === item.id);
                return {
                  id: item.id,
                  isLoading: false,
                  similarities: existingItem ? existingItem.similarities : null
                };
              }));
            }
          });
        }
        //setRfpItems(file.data?.getRfpFile?.items?.items);
      } catch (error) {
        console.error('Error fetching document data:', error);
      }
    }
    async function fetchCategories() {
      try {

        // Fetch data from the amplify graphql API table Strategy
        const allStrategy = await API.graphql<GraphQLQuery<ListStrategiesQuery>>(
          { query: queries.listStrategies }
        );
        setListStrategy(allStrategy.data?.listStrategies?.items)

      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    }
    fetchFiles();
    fetchCategories();
  }, [id]);


  function handleSelectChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setSelectedStrategy(event.target.value);
  }

  async function getDetailsFromDocuments(listIds: any) {
    // Fetch data from the amplify graphql API table RfpFile
    try {
      const ids = listIds.map((item: any) => ({ id: { eq: item } }));

      const filter = {
        or: ids
      }
      // Fetch data from the amplify graphql API table RfpFile
      const questions = await API.graphql<GraphQLQuery<ListRfpFileItemsQuery>>(
        { query: queries.listRfpFileItems, variables: { filter: filter } }
      );

      // ====================== Get Images
      // record image with presigned url
      let presignedUrlDone: { [key: string]: string } = {};

      // Assuming file.data?.getRfpFile?.items?.items is an array
      const items = questions.data?.listRfpFileItems?.items;

      if (items) {
        const processItems = async () => {
          const promises = items.map(async (item: any) => {
            const newHtml = await convertResponseToHtml(item.answer, presignedUrlDone, []);
            item.answer = newHtml;
          });

          // Wait for all promises to resolve
          await Promise.all(promises);

          // Return the new response
          return questions.data?.listRfpFileItems?.items;
        };
        // Call the async function and wait for it to complete
        const newResponse = await processItems();
        return newResponse;
      }
    } catch (error) {
      console.error('Error fetching document data:', error);
      return null;
    }
  }


  async function fetchAllRfpItems(id: string, nextToken: string | null = null): Promise<any> {
    let result = await API.graphql<GraphQLQuery<ListRfpToAnswerFileItemsQuery>>({
      query: queries.listRfpToAnswerFileItems, variables: {
        filter: {
          rfpToAnswerFileId: {
            eq: id
          }
        },
        nextToken
      }
    });

    if (result?.data?.listRfpToAnswerFileItems?.nextToken) {
      const nextResult = await fetchAllRfpItems(id, result.data.listRfpToAnswerFileItems.nextToken);
      // Combine result and nextResult
      if (nextResult.data.listRfpToAnswerFileItems.items) {
        result.data.listRfpToAnswerFileItems.items = result.data.listRfpToAnswerFileItems.items.concat(nextResult.data.listRfpToAnswerFileItems.items);
      }

    }

    return result;
  }

  async function updatePage() {
    try {
      // Fetch data from the amplify graphql API table RfpFile
      let file = await API.graphql<GraphQLQuery<GetRfpToAnswerFileQuery>>(
        { query: queries.getRfpToAnswerFile, variables: { id: id } }
      );
      setDocumentData(file.data?.getRfpToAnswerFile);
      // Fetch data from the amplify graphql API table RfpFile
      // const rfpItems = await API.graphql<GraphQLQuery<ListRfpToAnswerFileItemsQuery>>(
      //   { query: queries.listRfpToAnswerFileItems, variables: { rfpFileId: id } }
      // );

      // const rfpItems = await API.graphql<GraphQLQuery<ListRfpToAnswerFileItemsQuery>>({ query: queries.listRfpToAnswerFileItems, {
      //   filter: {
      //     rfpToAnswerFileId: {
      //       eq: id
      //     }
      //   }
      // }));

      if (!id) {
        return null;
      }

      const rfpItems = await fetchAllRfpItems(id);

      let litems = rfpItems.data?.listRfpToAnswerFileItems?.items;
      // let litems = null; // rfpItems.data?.listRfpToAnswerFileItems?.items;

      if (litems) {
        litems.sort((a: any, b: any) => {
          if (a !== null && b !== null) {
            if (a?.datetime > b?.datetime) {
              return 1;
            } else if (a?.datetime < b?.datetime) {
              return -1;
            } else {
              return 0;
            }
          }
          else {
            return 0;
          }
        });
      }
      const items = litems

      // record image with presigned url
      let presignedUrlDone: { [key: string]: string } = {};

      if (items) {
        const processItems = async () => {
          const promises = items.map(async (item: any) => {
            const newHtml = await convertResponseToHtml(item.answer, presignedUrlDone, item.documents); // ,'processed-rfp');
            item.answer = newHtml;
          });
          // Wait for all promises to resolve
          await Promise.all(promises);
          const promises2 = items.map(async (item: any) => {
            if (item?.documents) {
              const sources = await getDetailsFromDocuments(item.documents);
              item.sources = sources;
            }
            else {
              item.sources = [];
            }
          });
          // Wait for all promises to resolve
          await Promise.all(promises2);
        };

        // Call the async function
        processItems().then(() => {
          // All promises have resolved, you can continue with the next lines of code here
          setRfpItems(rfpItems.data?.listRfpToAnswerFileItems?.items);


          // for each element of rfpItems create a new element with only id attribute and a new attribue isloading as false
          if (rfpItemsLoading === null) {
            setRfpItemsLoading(rfpItems.data?.listRfpToAnswerFileItems?.items?.map((item: any) => {
              return { id: item.id, isLoading: false };
            }));
          } else {
            setRfpItemsLoading(rfpItems.data?.listRfpToAnswerFileItems?.items?.map((item: any) => {
              const existingItem = rfpItemsLoading.find((loadingItem: any) => loadingItem.id === item.id);
              return {
                id: item.id,
                isLoading: false,
                similarities: existingItem ? existingItem.similarities : null
              };
            }));
          }

        });
      }

    } catch (error) {
      console.error('Error fetching document data:', error);
    }
  }

  //#######################################################################################################################
  async function DownloadFile() {
    setIsLoading(true);
    const link = await API.graphql<GraphQLQuery<ResponsePresignedGetQuery>>(
      { query: queries.responsePresignedGet, variables: { objectName: documentData?.s3Key } }
    );
    if (link?.data?.responsePresignedGet !== null && link?.data?.responsePresignedGet !== undefined) {
      window.location.href = link?.data?.responsePresignedGet;
    }
    setIsLoading(false);
  }



  function deleteFileConfirmation() {
    setModalBatchFileDeleteConfirmatonIsOpen(true);
  }
  async function deleteFile() {
    if (id === "") {
      return;
    }
    setIsLoading(true);
    await API.graphql<GraphQLQuery<DeleteRfpDocumentToAnswerQuery>>(
      { query: queries.deleteRfpDocumentToAnswer, variables: { rfpId: id } }
    );
    setIsLoading(false);
    navigate("/batch");
  }


  function addBatchQuestionConfirmation() {
    setAnswerBatchValue("");
    setQuestionBatchValue("");
    setModalBatchConfirmatonAddIsOpen(true);
  }

  async function addBatchQuestion() {

    if (!editorRefQuestion.current) {
      return;
    }


    let answer = "";
    if (editorRef.current) {
      answer = editorRef.current.getContent();
    }
    setIsLoading(true);
    // if id is not string then exit
    if (typeof id !== 'string') {
      return;
    }
    await API.graphql(
      { query: mutations.createRfpToAnswerFileItem, variables: { input: { rfpToAnswerFileId: id, question: editorRefQuestion.current.getContent(), answer: answer, datetime: new Date().toISOString() } } });
    updatePage();
    setIsLoading(false);

  }


  //#######################################################################################################################
  function editBatchQuestionConfirmation(questionId: string, question: string, answer: string | null) {
    setEditBatchQuestionId(questionId);
    setQuestionBatchValue(question);
    if (answer) {
      setAnswerBatchValue(answer);
    }
    else {
      setAnswerBatchValue("");
    }
    setModalBatchConfirmatonEditIsOpen(true);
  }

  async function editBatchQuestion() {
    if (editBatchQuestionId === "") {
      return;
    }
    let newHtmlAnswer = "";
    let newHtmlQuestion = "";
    if (rfpItemsLoading.find((rfpItem: any) => rfpItem.id === editBatchQuestionId) != null) {
      setRfpItemsLoading(
        rfpItemsLoading.map((item: any) =>
          item.id === editBatchQuestionId ? { ...item, isLoading: true } : item
        )
      );
    }
    if (editorRef.current) {
      newHtmlAnswer = convertImagePathBeforeSaving(editorRef.current.getContent());
    }
    if (editorRefQuestion.current) {
      newHtmlQuestion = convertImagePathBeforeSaving(editorRefQuestion.current.getContent());

    }
    if (newHtmlQuestion === "") {
      if (rfpItemsLoading.find((rfpItem: any) => rfpItem.id === editBatchQuestionId) != null) {
        setRfpItemsLoading(
          rfpItemsLoading.map((item: any) =>
            item.id === editBatchQuestionId ? { ...item, isLoading: false } : item
          )
        );
      }
      return;
    }
    await API.graphql(
      {
        query: mutations.updateRfpToAnswerFileItem, variables: {
          input: {
            id: editBatchQuestionId,
            documents: rfpItems.find((rfpItem: any) => rfpItem.id === editBatchQuestionId).documents,
            question: newHtmlQuestion,
            answer: newHtmlAnswer,
            _version: rfpItems.find((rfpItem: any) => rfpItem.id === editBatchQuestionId)._version
          }
        }
      });

    updatePage();
    if (rfpItemsLoading.find((rfpItem: any) => rfpItem.id === editBatchQuestionId) != null) {
      setRfpItemsLoading(
        rfpItemsLoading.map((item: any) =>
          item.id === editBatchQuestionId ? { ...item, isLoading: false } : item
        )
      );
    }
  }


  function deleteBatchQuestionConfirmation(questionId: string) {
    setDeleteBatchQuestionId(questionId);
    setModalBatchConfirmatonDeleteIsOpen(true);
  }

  async function deleteBatchQuestion() {
    if (deleteBatchQuestionId === "") {
      return;
    }
    if (rfpItemsLoading.find((rfpItem: any) => rfpItem.id === deleteBatchQuestionId) != null) {
      setRfpItemsLoading(
        rfpItemsLoading.map((item: any) =>
          item.id === deleteBatchQuestionId ? { ...item, isLoading: true } : item
        )
      );
    }
    await API.graphql<GraphQLQuery<DeleteRfpDocumentToAnswerItemQuery>>(
      {
        query: queries.deleteRfpDocumentToAnswerItem, variables: {
          questionId: deleteBatchQuestionId
        }
      }
    );
    updatePage();
    if (rfpItemsLoading.find((rfpItem: any) => rfpItem.id === deleteBatchQuestionId) != null) {
      setRfpItemsLoading(
        rfpItemsLoading.map((item: any) =>
          item.id === deleteBatchQuestionId ? { ...item, isLoading: false } : item
        )
      );
    }
  }

  // function GenerateAll() {
  //   rfpItems?.forEach((item: any) => {
  //     generateAnswer(item.id, item.question);
  //   });
  // }

  async function GenerateAll() {
    const chunkSize = 20;
    if (rfpItems === null) {
      snackbar.showAlert(`No questions to generate`);
      return;
    }
    if (rfpItems.length == 0) {
      snackbar.showAlert(`No questions to generate`);
      return;
    }

    for (let i = 0; i < rfpItems.length; i += chunkSize) {
      const chunk = rfpItems.slice(i, i + chunkSize);
      snackbar.showAlert(`Generating questions ${i + 1} to ${i + chunk.length} out of ${rfpItems.length} - Do not leave or refresh page.`);
      console.log((`Generating questions ${i + 1} to ${i + chunk.length} out of ${rfpItems.length} - Do not leave or refresh page.`))
      await Promise.all(chunk.map((item: any) => generateAnswer(item.id, item.question, true)));
    }
    console.log("All answers generated")
    snackbar.showAlert(`All answers generated (${rfpItems.length})`);
  }

  async function generateAnswer(questionId: string, question: string, all: boolean = false) {
    console.log("Generate answer", { questionId, question, all })
    return new Promise<void>(async (resolve, reject) => {
      if (questionId === "" || question === "") {
        resolve();
        return;
      }
      if (rfpItemsLoading.find((rfpItem: any) => rfpItem.id === questionId) != null) {
        setRfpItemsLoading((prevRfpItemsLoading: any) =>
          prevRfpItemsLoading.map((item: any) =>
            item.id === questionId ? { ...item, isLoading: true } : item
          )
        );
      }
      try {
        //console.log( { msg: question, strategy: selectedStrategy })
        const responseGenerate = await API.graphql<GraphQLQuery<GetRfpQuestionResponseQuery>>(
          { query: queries.getRfpQuestionResponse, variables: { msg: question, strategy: selectedStrategy } }
        );
        let response = responseGenerate.data?.getRfpQuestionResponse

        const variables: OnCreateUserQuestionSubscriptionVariables = {
          filter: {
            // Only receive  messages where the correct requestId
            requestId: { eq: response }//response as string} //
          }
        }

        const createSub = API.graphql<GraphQLSubscription<OnCreateUserQuestionSubscription>>(
          { query: subscriptions.onCreateUserQuestion, variables }
        ).subscribe({
          next: ({ data }) => {
            // Stop receiving data updates from the subscription
            createSub.unsubscribe();
            console.log("Unsubscribe CreateSub: ", data?.onCreateUserQuestion)
            processResponse(data?.onCreateUserQuestion, questionId);
            if (data?.onCreateUserQuestion?.generationCompleted === true) {
              
              resolve();
              if (rfpItemsLoading.find((rfpItem: any) => rfpItem.id === questionId) != null) {
                setRfpItemsLoading((prevRfpItemsLoading: any) =>
                  prevRfpItemsLoading.map((item: any) =>
                    item.id === questionId ? { ...item, isLoading: false } : item
                  )
                );
              }
              // Add response to answer of rfpItems
              let documents: any = [];
              if (data?.onCreateUserQuestion.documents) {
                documents = data?.onCreateUserQuestion.documents.map((item: any) =>
                  item.id
                )
              }
              console.log("Save response to answer", { id: questionId, answer: data?.onCreateUserQuestion?.answer, _version: rfpItems.find((rfpItem: any) => rfpItem.id === questionId)._version })
              API.graphql(
                { query: mutations.updateRfpToAnswerFileItem, variables: { input: { id: questionId, documents: documents, answer: data?.onCreateUserQuestion?.answer, _version: rfpItems.find((rfpItem: any) => rfpItem.id === questionId)._version } } });
            }

          },
          error: (error) => console.error(error)
        });
        const variablesUpdate: OnUpdateUserQuestionSubscriptionVariables = {
          filter: {
            // Only receive  messages where the correct requestId
            requestId: { eq: response }//response as string} //
          }
        }
        const updateSub = API.graphql<GraphQLSubscription<OnUpdateUserQuestionSubscription>>(
          { query: subscriptions.onUpdateUserQuestion, variables: variablesUpdate }
        ).subscribe({
          next: ({ data }) => {
            // Stop receiving data updates from the subscription
            processResponse(data?.onUpdateUserQuestion, questionId);
            if (data?.onUpdateUserQuestion?.generationCompleted === true) {
              updateSub.unsubscribe();
              console.log("Generation completed for ", data?.onUpdateUserQuestion)
              resolve();
              if (rfpItemsLoading.find((rfpItem: any) => rfpItem.id === questionId) != null) {
                setRfpItemsLoading((prevRfpItemsLoading: any) =>
                  prevRfpItemsLoading.map((item: any) =>
                    item.id === questionId ? { ...item, isLoading: false } : item
                  )
                );
              }
              // Add response to answer of rfpItems
              let documents: any = [];
              if (data?.onUpdateUserQuestion.documents) {
                documents = data?.onUpdateUserQuestion.documents.map((item: any) =>
                  item.id
                )
              }
              //console.log("Save response to answer", { id: questionId, answer: data?.onUpdateUserQuestion?.answer, _version: rfpItems.find((rfpItem: any) => rfpItem.id === questionId)._version })
              API.graphql(
                { query: mutations.updateRfpToAnswerFileItem, variables: { input: { id: questionId, documents: documents, answer: data?.onUpdateUserQuestion?.answer, _version: rfpItems.find((rfpItem: any) => rfpItem.id === questionId)._version } } });

            }

          },
          error: (error) => console.error(error)
        });
        if (!all) {
          snackbar.showAlert('Working on it...');
        }
      }
      catch (error) {
        console.error('Error generating questions:', error);
        reject(error);
        alert.showAlert('Error generating a response');
      }
    });
  }


  async function processResponse(responseGenerate: UserQuestion | null | undefined, questionId: String) {
    let presignedUrlDone: { [key: string]: string } = {};

    if (responseGenerate === null || responseGenerate === undefined) {
      return;
    }
    let response = responseGenerate?.answer //responseGenerate.data?.onCreateUserQuestion?.answer;
    let documents: (ResponseDocumentsScore | null)[] | null | undefined = responseGenerate?.documents //responseGenerate.data?.onCreateUserQuestion?.documents;
    if (documents) {
      documents = await Promise.all(documents.map(async (item: any) => {
        const newItem = { ...item };
        newItem.answer = await convertResponseToHtml(item.answer, presignedUrlDone, []);
        return newItem as ResponseDocumentsScore;
      }));
    }
    // Remove from response text at end after "@#@"

    let sources = "";
    // Update image path if response if text
    if (typeof response === 'string') {
      if (response && response.includes("@#@")) {
        response = response.split("@#@")[0];
        sources = response.split("@#@")[1];
      }
      // ====================== Get Images
      // record image with presigned url
      const newHtml = await convertResponseToHtml(response, presignedUrlDone, documents);
      response = newHtml;

    }

    setRfpItems((prevRfpItems: any) => prevRfpItems.map((item: any) =>
      item.id === questionId ? { ...item, answer: response, sources: documents } : item
    ));

  }


  //#######################################################################################################################
  function getModalConfirmation(modalBool: boolean, setModal: Function, callBackFunction: Function) {
    Modal.setAppElement('#root');

    return (
      <Modal
        isOpen={modalBool}
        style={customReactModalStyles}
      >
        <ThemeProvider theme={studioTheme}>
          <Flex direction={"column"} gap="20px">
            <Heading >Are you sure you want to continue?</Heading>
            <Flex direction={"row"} alignItems="space-between">
              <Button onClick={() => { callBackFunction(); setModal(false); }}>Yes</Button><Button onClick={() => setModal(false)}>No</Button>
            </Flex>
          </Flex>
        </ThemeProvider>
      </Modal>);
  }

  //#######################################################################################################################

  function editQuestionConfirmation(questionId: string, question: string, answer: string) {
    setEditQuestionId(questionId);
    setQuestionValue(question);
    setAnswerValue(answer);
    setModalConfirmatonEditIsOpen(true);
  }

  async function editQuestion() {
    if (editQuestionId === "") {
      return;
    }
    if (editorRfpItemRef.current) {
      setIsLoading(true);
      const newHtml = convertImagePathBeforeSaving(editorRfpItemRef.current.getContent());
      await API.graphql<GraphQLQuery<UpdateQuestionKnowledgeBaseQuery>>(
        {
          query: queries.updateQuestionKnowledgeBase, variables: {
            questionId: editQuestionId,
            question: questionValue,
            answer: newHtml
          }
        }
      );
      updatePage();
      setIsLoading(false);
    }
    else {
      console.error("No editorRfpItemRef value");
      return;
    }
  }


  function deleteQuestionConfirmation(questionId: string) {
    setDeleteQuestionId(questionId);
    setModalConfirmatonDeleteIsOpen(true);
  }

  async function deleteQuestion() {
    if (deleteQuestionId === "") {
      return;
    }
    setIsLoading(true);
    await API.graphql<GraphQLQuery<DeleteQuestionQuery>>(
      {
        query: queries.deleteQuestion, variables: {
          questionId: deleteQuestionId
        }
      }
    );
    updatePage();
    setIsLoading(false);
  }

  function removeQuestionConfirmation(questionId: string, isExcluded: boolean) {
    setRemoveQuestionId(questionId);
    setRemoveQuestionIsExcluded(isExcluded);
    setModalConfirmatonRemoveIsOpen(true);
  }


  async function removeQuestionFromDB() {
    if (removeQuestionId === "") {
      return;
    }
    setIsLoading(true);
    if (removeQuestionIsExcluded) {
      await API.graphql<GraphQLQuery<AddBackQuestionKnowledgeBaseQuery>>(
        {
          query: queries.addBackQuestionKnowledgeBase, variables: {
            questionId: removeQuestionId
          }
        }
      );
    } else {
      await API.graphql<GraphQLQuery<RemoveQuestionKnowledgeBaseQuery>>(
        {
          query: queries.removeQuestionKnowledgeBase, variables: {
            questionId: removeQuestionId
          }
        }
      );
    }
    updatePage();
    setIsLoading(false);
  }


  function copyFullDoc() {
    try {
      // Go through each item and copy to clipboard
      let text = "";
      rfpItems?.forEach((item: any) => {
        text += item.question + "\n" + item.answer + "<br/><br/><hr/>";
      });
      copyToClipboard(text, snackbar, alert);

      //   const blobInput = new Blob([text], { type: 'text/html' });
      //   const clipboardItemInput = new ClipboardItem({ 'text/html': blobInput });
      //   navigator.clipboard.write([clipboardItemInput]);
      //   snackbar.showAlert('Copied to clipboard');
    } catch (e) {
      // Handle error with user feedback - "Copy failed!" kind of thing
      console.error(e);
      alert.showAlert('Error copying to clipboard');
    }
  }


  //#######################################################################################################################
  function getAnswerLayout(batchItem: any) {
    if (batchItem.answer) {
      return (<View width={'100%'}>
        {typeof batchItem.answer === 'string' ? parse(batchItem.answer) : ''}
        {batchItem.sources &&
          <Card variation="elevated">
            <Flex direction="column" justifyContent="space-between" alignItems="left">
              <Heading level={5}>Source documents</Heading>
              <Accordion.Container allowMultiple>
                {batchItem.sources.map((item: any, index: number) => (
                  <Accordion.Item value={"exp" + index} key={index}>
                    <Accordion.Trigger>
                      <Flex direction="column" gap="0rem" >
                        <Flex justifyContent="space-between" alignItems="center">
                          <Text variation="secondary" as="span">
                            {`${item.question}`}
                          </Text>
                        </Flex>
                        <Flex justifyContent="flex-start" alignItems="flex-start">
                          <Badge variation="info">{`${formatDateISO(item.datetime)}`}</Badge> <small>{`${item.rfpFileName ? item.rfpFileName : ""}`}</small>
                        </Flex>
                      </Flex>
                      <Accordion.Icon />
                    </Accordion.Trigger>
                    <Accordion.Content>
                      < RfpItemLayout
                        key={index}
                        overrides={
                          {
                            RfpItemLayout: {
                              width: "100%"
                            },
                            ButtonFile: {
                              children: item.rfpFileName ? item.rfpFileName : "File",
                              onClick: () => { navigate(`/documents/${item.rfpFileId}`); },
                            },
                            Question: { children: item.question },
                            Date: { children: formatDateISO(item.datetime) },
                            TextLayout: { children: (<View>{typeof item.answer === 'string' ? parse(item.answer) : ''}</View>) },
                            ButtonCopy: { // Copy to clipboard
                              onClick: () => copyToClipboard(item.question + "\n" + item.answer, snackbar, alert)
                            },
                            ButtonEdit: { // Edit
                              onClick: () => editQuestionConfirmation(item.id, item.question, item.answer), isLoading: isLoading
                            },
                            ButtonRemove: { // Update Knowledge base
                              children: item.isExcluded ? "Add to Search" : "Remove from Search",
                              onClick: () => removeQuestionConfirmation(item.id, item.isExcluded), isLoading: isLoading
                            },
                            // DELETE
                            ButtonDelete: { onClick: () => deleteQuestionConfirmation(item.id), isLoading: isLoading },

                            "StatusBadge": {
                              "children": getIsExcludedText(item.isExcluded),
                              "variation": getIsExcludedVariation(item.isExcluded)
                            }
                          }
                        }
                      />
                    </Accordion.Content>
                  </Accordion.Item>
                ))}
              </Accordion.Container>
            </Flex>
          </Card>
        }
      </View>)
    }
    return (<View>No answer generated yet</View>)

  }


  //#######################################################################################################################
  function getBatchModalForm(text: string, modalBool: boolean, setModal: Function, callBackFunction: Function, editId: string, questionCurrentValue: string, answerCurrentValue: string) {
    Modal.setAppElement('#root');

    return (
      <Modal
        isOpen={modalBool}
        style={customReactModalStyles}
      >
        <ThemeProvider theme={studioTheme}>
          <Flex direction={"column"} gap="20px" style={{ maxHeight: '90vh', overflowY: 'auto' }}>
            <Heading >{text}</Heading>
            <Flex direction={"column"} alignItems="space-between">
              <Text >Question</Text>
              <Editor
                id="editor-id-question"
                onInit={(evt, editor) => editorRefQuestion.current = editor}
                apiKey={editorKey}
                init={{ ...tinymceConfig(editId, "processed"), height: 200 }}
                initialValue={questionCurrentValue}
              />

              <Flex className="test" direction={"column"}>
                <Text >Answer</Text>
                <Editor
                  id="editor-id"
                  onInit={(evt, editor) => editorRef.current = editor}
                  apiKey={editorKey}
                  init={tinymceConfig(editId, "processed")}
                  initialValue={answerCurrentValue}
                />
              </Flex>
            </Flex>
            <Flex direction={"row"} alignItems="space-between">
              <Button variation='primary' onClick={() => { callBackFunction(); setModal(false); }}>Save</Button><Button variation='link' onClick={() => setModal(false)}>Cancel</Button>
            </Flex>
          </Flex>
        </ThemeProvider>
      </Modal>);
  }


  //#######################################################################################################################
  function getModalForm(text: string, modalBool: boolean, setModal: Function, callBackFunction: Function, editId: string, questionCurrentValue: string, answerCurrentValue: string, setQuestionCurrentValue: Function) {
    Modal.setAppElement('#root');

    return (
      <Modal
        isOpen={modalBool}
        style={customReactModalStyles}
      >
        <ThemeProvider theme={studioTheme}>
          <Flex direction={"column"} gap="20px" style={{ maxHeight: '90vh', overflowY: 'auto' }}>
            <Heading >{text}</Heading>
            <Flex direction={"column"} alignItems="space-between">
              <Text >Question</Text>
              <TextAreaField
                placeholder="..."
                label=""
                errorMessage="There is an error"
                rows={2}
                onChange={(event) => setQuestionCurrentValue(event.target.value)}
                defaultValue={questionCurrentValue}
              />

              <Flex className="test" direction={"column"}>
                <Text >Answer</Text>
                <Editor
                  id="editor-id-answer"
                  onInit={(evt, editor) => editorRfpItemRef.current = editor}
                  apiKey={editorKey}
                  init={tinymceConfig(editId, "processed")}
                  initialValue={answerCurrentValue}
                />
              </Flex>
            </Flex>
            <Flex direction={"row"} alignItems="space-between">
              <Button variation='primary' onClick={() => { callBackFunction(); setModal(false); }}>Save</Button><Button variation='link' onClick={() => setModal(false)}>Cancel</Button>
            </Flex>
          </Flex>
        </ThemeProvider>
      </Modal>);
  }


  //#######################################################################################################################
  function getModalFullGeneration(modalBool: boolean, setModal: Function, callBackFunction: Function) {
    Modal.setAppElement('#root');

    return (
      <Modal
        isOpen={modalBool}
        style={customReactSmallModalStyles}
      >
        <ThemeProvider theme={studioTheme}>
          <Flex direction={"column"} gap="20px" style={{ maxHeight: '90vh', overflowY: 'auto' }}>
            <Heading >Full Doc Generation</Heading>
            <Flex direction={"column"} alignItems="space-between">
              <Text >Are you sure you want to generate all answers? If yes please select a category for the source documents.</Text>
              <SelectField
                label="Select a Category"
                value={selectedStrategy}
                onChange={handleSelectChange}
                maxWidth={200}
              >
                <option value="all">All</option>
                {listStrategy?.map((strategy: Strategy, index: number) => (
                  <option key={index} value={strategy.id}>{strategy.name}</option>
                ))}
              </SelectField>
            </Flex>
            <Flex direction={"row"} alignItems="space-between">
              <Button variation='primary' onClick={() => { callBackFunction(); setModal(false); }}>Generate All</Button><Button variation='link' onClick={() => setModal(false)}>Cancel</Button>
            </Flex>
          </Flex>
        </ThemeProvider>
      </Modal>);
  }

  //#######################################################################################################################
  function getModalSingleGeneration(modalBool: boolean, setModal: Function) {
    Modal.setAppElement('#root');

    return (
      <Modal
        isOpen={modalBool}
        style={customReactSmallModalStyles}
      >
        <ThemeProvider theme={studioTheme}>
          <Flex direction={"column"} gap="20px" style={{ maxHeight: '90vh', overflowY: 'auto' }}>
            <Heading >Generate a single Answer</Heading>
            <Flex direction={"column"} alignItems="space-between">
              <Text >Are you sure you want to generate a single answer? <br />This will overwrite the previous answer if any. If yes please select a category for the source documents.</Text>
              <SelectField
                label="Select a Category"
                value={selectedStrategy}
                onChange={handleSelectChange}
                maxWidth={200}
              >
                <option value="all">All</option>
                {listStrategy?.map((strategy: Strategy, index: number) => (
                  <option key={index} value={strategy.id}>{strategy.name}</option>
                ))}
              </SelectField>
            </Flex>
            <Flex direction={"row"} alignItems="space-between">
              <Button variation='primary' onClick={() => { generateAnswer(questionIdSelected, questionSelected); setModal(false); }}>Generate All</Button><Button variation='link' onClick={() => setModal(false)}>Cancel</Button>
            </Flex>
          </Flex>
        </ThemeProvider>
      </Modal>);
  }

  const handleSingleGenerationClick = (id: string, question: string) => {
    setQuestionIdSelected(id);
    setQuestionSelected(question);
    setModalSingleGenerationIsOpen(true);
  };

  return (
    <Page title="Document Batch">

      <Flex gap="20px" direction={"column"}>
        {getModalConfirmation(modalBatchFileDeleteConfirmatonIsOpen, setModalBatchFileDeleteConfirmatonIsOpen, deleteFile)}
        {getModalConfirmation(modalBatchConfirmatonDeleteIsOpen, setModalBatchConfirmatonDeleteIsOpen, deleteBatchQuestion)}
        {getBatchModalForm("Edit Question", modalBatchConfirmatonEditIsOpen, setModalBatchConfirmatonEditIsOpen, editBatchQuestion, editBatchQuestionId, questionBatchValue, answerBatchValue)}
        {getBatchModalForm("Add Question", modalBatchConfirmatonAddIsOpen, setModalBatchConfirmatonAddIsOpen, addBatchQuestion, "", "", "")}
        {getModalConfirmation(modalConfirmatonDeleteIsOpen, setModalConfirmatonDeleteIsOpen, deleteQuestion)}
        {getModalConfirmation(modalConfirmatonRemoveIsOpen, setModalConfirmatonRemoveIsOpen, removeQuestionFromDB)}
        {getModalForm("Edit Question", modalConfirmatonEditIsOpen, setModalConfirmatonEditIsOpen, editQuestion, editQuestionId, questionValue, answerValue, setQuestionValue)}
        {getModalFullGeneration(modalFullGenerationIsOpen, setModalFullGenerationIsOpen, GenerateAll)}
        {getModalSingleGeneration(modalSingleGenerationIsOpen, setModalSingleGenerationIsOpen)}

        <Breadcrumbs
          items={[
            {
              href: '/',
              label: 'Home',
            },
            {
              href: '/batch',
              label: 'batch',
            },
            {
              href: '/batch/' + id,
              label: documentData?.name,
            }
          ]}
        />
        <snackbar.AlertComponent />
        <alert.AlertComponent />
        <Card>
          <Flex direction="column" alignItems="flex-start">
            <Heading level={4}>RFP - {documentData?.name}</Heading>
            <Flex direction="row" alignItems="flex-start">
              <Badge >Date: {formatDateISO(documentData?.datetime)}</Badge>
            </Flex>
            <Text
              variation="primary"
              as="p"
            >
              Find below the list of questions extracted from this document.
            </Text>
          </Flex>
        </Card>
        <Flex direction={{ base: 'column', medium: 'row' }} alignItems="space-between">
          <Flex direction='row'  >
            <Number overrides={{ Title: { children: "Size" }, Text: { children: formatFileSize(documentData?.size) } }} />
            <Number overrides={{ Title: { children: "Questions" }, Text: { children: formatNbQuestions(documentData?.nbQuestions) } }} />
          </Flex>
          <Card   >
            <Flex direction={{ base: 'column', medium: 'row' }} alignItems="space-between">
              <Button variation="primary" onClick={() => setModalFullGenerationIsOpen(true)} >Generate All Replies</Button>
              <ButtonGroup>
                <Button onClick={() => DownloadFile()} isLoading={isLoading}><DownloadIcon /> Download</Button>
                <Button onClick={() => deleteFileConfirmation()} isLoading={isLoading} size="small" variation='link'><MyIcon type="delete" /> Delete</Button>
                <Button onClick={() => addBatchQuestionConfirmation()} isLoading={isLoading} size="small" variation='link'><MyIconSet type="plus" /> Question</Button>
              </ButtonGroup>
              <ButtonGroup>
                <Button onClick={() => copyFullDoc()} isLoading={isLoading}>Copy to Clipboad</Button>
              </ButtonGroup>
            </Flex>
          </Card>
        </Flex>
        <Flex justifyContent="center" alignItems="center" width="100%">
          <Card width={"100%"}>
            {rfpItems ? (
              <Collection
                items={rfpItems}
                type="list"
                direction="column"
                gap="20px"
                wrap="wrap"
                isPaginated
                itemsPerPage={10}
                isSearchable
                searchPlaceholder="Type to search..."
                searchFilter={(regions, keyword) =>
                  (regions as any).question.toLowerCase().includes(keyword.toLowerCase()) || (regions as any).answer.toLowerCase().includes(keyword.toLowerCase())
                }
              >
                {(item: any, index: number) => (
                  <ToAnswerItemLayout
                    key={index}
                    overrides={
                      {
                        ToAnswerItemLayout: {
                          width: "100%"
                        },
                        TitleLayout: { children: (<View>{typeof item.question === 'string' ? parse(item.question) : ''}</View>) },
                        Date: { children: formatDateISO(item.datetime) },
                        TextLayout: { children: getAnswerLayout(item) },
                        ButtonCopy: { // Copy to clipboard
                          onClick: () => copyToClipboard(item.question + "\n" + item.answer, snackbar, alert)
                        },
                        ButtonEdit: { // Edit
                          onClick: () => editBatchQuestionConfirmation(item.id, item.question, item.answer),
                          isLoading: rfpItemsLoading.find((rfpItem: any) => rfpItem.id === item.id)?.isLoading || false
                        },
                        ButtonDelete: {
                          onClick: () => deleteBatchQuestionConfirmation(item.id),
                          isLoading: rfpItemsLoading.find((rfpItem: any) => rfpItem.id === item.id)?.isLoading || false
                        },
                        ButtonGenerate: { // Edit
                          onClick: () => handleSingleGenerationClick(item.id, item.question),
                          //onClick: () => generateAnswer(item.id, item.question),
                          isLoading: rfpItemsLoading.find((rfpItem: any) => rfpItem.id === item.id)?.isLoading || false
                        },
                      }
                    }
                  />
                )}
              </Collection>
            ) : (
              <Placeholder size="large" height={"200px"} />
            )}
          </Card>
        </Flex>
      </Flex>
    </Page>
  );
}
