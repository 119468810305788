import { Navigate, useRoutes } from 'react-router-dom';

// layouts
import DashboardLayout from './layouts/dashboard';
//
import DashboardApp from './pages/DashboardApp';
import NotFound from './pages/Page404';

import Documents from './pages/Documents';
import Document from './pages/Document';
import Generate from './pages/Generate';
import Questions from './pages/Questions';
import Help from './pages/Help';
import Search from './pages/Search';
import Batch from './pages/Batch';
import BatchDocuments from './pages/BatchDocuments';
import DocumentHighlights from './pages/DocumentHighlights';

import ScrollToTop from './components/ScrollToTop';

// ----------------------------------------------------------------------

export default function Router() {
  const routing = useRoutes([
    {
      path: '',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Navigate to="/dashboard" /> },
        {
          path: 'dashboard',
          element: <DashboardApp />
        },
        {
          path: 'documents/:id',
          element: <Document />
        },        
        {
          path: 'documents',
          element: <Documents />
        },
        {
          path: 'questions',
          element: <Questions />
        },        
        {
          path: 'generate',
          element: <Generate />
        },        
        {
          path: 'help',
          element: <Help />
        },        
        {
          path: 'batch',
          element: <Batch />
        },
        {
          path: 'batch/:id',
          element: <BatchDocuments />
        },        
        {
          path: 'search',
          element: <Search />
        },
        {
          path: 'batch-extract/:id',
          element: <DocumentHighlights />
        },  
        {
          path: 'highlights',
          element: <DocumentHighlights />
        },        
        { path: '404', element: <NotFound /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" /> }
  ]);

  return (
    <>
      <ScrollToTop />
      {routing}
    </>
  );
}