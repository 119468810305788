/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getMessageAvatar = /* GraphQL */ `query GetMessageAvatar($id: ID!) {
  getMessageAvatar(id: $id) {
    id
    msg
    type
    datetime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMessageAvatarQueryVariables,
  APITypes.GetMessageAvatarQuery
>;
export const listMessageAvatars = /* GraphQL */ `query ListMessageAvatars(
  $filter: ModelMessageAvatarFilterInput
  $limit: Int
  $nextToken: String
) {
  listMessageAvatars(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      msg
      type
      datetime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMessageAvatarsQueryVariables,
  APITypes.ListMessageAvatarsQuery
>;
export const syncMessageAvatars = /* GraphQL */ `query SyncMessageAvatars(
  $filter: ModelMessageAvatarFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMessageAvatars(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      msg
      type
      datetime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMessageAvatarsQueryVariables,
  APITypes.SyncMessageAvatarsQuery
>;
export const getRfpFile = /* GraphQL */ `query GetRfpFile($id: ID!) {
  getRfpFile(id: $id) {
    id
    name
    type
    s3Bucket
    s3Key
    status
    size
    nbQuestions
    datetime
    strategy {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      strategyRfpFilesId
      __typename
    }
    isExcluded
    items {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    rfpFileStrategyId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRfpFileQueryVariables,
  APITypes.GetRfpFileQuery
>;
export const listRfpFiles = /* GraphQL */ `query ListRfpFiles(
  $filter: ModelRfpFileFilterInput
  $limit: Int
  $nextToken: String
) {
  listRfpFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      type
      s3Bucket
      s3Key
      status
      size
      nbQuestions
      datetime
      isExcluded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      rfpFileStrategyId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRfpFilesQueryVariables,
  APITypes.ListRfpFilesQuery
>;
export const syncRfpFiles = /* GraphQL */ `query SyncRfpFiles(
  $filter: ModelRfpFileFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncRfpFiles(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      type
      s3Bucket
      s3Key
      status
      size
      nbQuestions
      datetime
      isExcluded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      rfpFileStrategyId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncRfpFilesQueryVariables,
  APITypes.SyncRfpFilesQuery
>;
export const getRfpFileItem = /* GraphQL */ `query GetRfpFileItem($id: ID!) {
  getRfpFileItem(id: $id) {
    id
    rfpFileId
    rfpFile {
      id
      name
      type
      s3Bucket
      s3Key
      status
      size
      nbQuestions
      datetime
      isExcluded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      rfpFileStrategyId
      __typename
    }
    question
    answer
    status
    faissId
    isExcluded
    datetime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRfpFileItemQueryVariables,
  APITypes.GetRfpFileItemQuery
>;
export const listRfpFileItems = /* GraphQL */ `query ListRfpFileItems(
  $filter: ModelRfpFileItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listRfpFileItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      rfpFileId
      question
      answer
      status
      faissId
      isExcluded
      datetime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRfpFileItemsQueryVariables,
  APITypes.ListRfpFileItemsQuery
>;
export const syncRfpFileItems = /* GraphQL */ `query SyncRfpFileItems(
  $filter: ModelRfpFileItemFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncRfpFileItems(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      rfpFileId
      question
      answer
      status
      faissId
      isExcluded
      datetime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncRfpFileItemsQueryVariables,
  APITypes.SyncRfpFileItemsQuery
>;
export const rfpFileItemsByRfpFileIdAndQuestion = /* GraphQL */ `query RfpFileItemsByRfpFileIdAndQuestion(
  $rfpFileId: ID!
  $question: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelRfpFileItemFilterInput
  $limit: Int
  $nextToken: String
) {
  rfpFileItemsByRfpFileIdAndQuestion(
    rfpFileId: $rfpFileId
    question: $question
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      rfpFileId
      question
      answer
      status
      faissId
      isExcluded
      datetime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RfpFileItemsByRfpFileIdAndQuestionQueryVariables,
  APITypes.RfpFileItemsByRfpFileIdAndQuestionQuery
>;
export const rfpFileItemsByFaissId = /* GraphQL */ `query RfpFileItemsByFaissId(
  $faissId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelRfpFileItemFilterInput
  $limit: Int
  $nextToken: String
) {
  rfpFileItemsByFaissId(
    faissId: $faissId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      rfpFileId
      question
      answer
      status
      faissId
      isExcluded
      datetime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RfpFileItemsByFaissIdQueryVariables,
  APITypes.RfpFileItemsByFaissIdQuery
>;
export const getStrategy = /* GraphQL */ `query GetStrategy($id: ID!) {
  getStrategy(id: $id) {
    id
    name
    rfpFiles {
      id
      name
      type
      s3Bucket
      s3Key
      status
      size
      nbQuestions
      datetime
      isExcluded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      rfpFileStrategyId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    strategyRfpFilesId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStrategyQueryVariables,
  APITypes.GetStrategyQuery
>;
export const listStrategies = /* GraphQL */ `query ListStrategies(
  $filter: ModelStrategyFilterInput
  $limit: Int
  $nextToken: String
) {
  listStrategies(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      strategyRfpFilesId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStrategiesQueryVariables,
  APITypes.ListStrategiesQuery
>;
export const syncStrategies = /* GraphQL */ `query SyncStrategies(
  $filter: ModelStrategyFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncStrategies(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      strategyRfpFilesId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncStrategiesQueryVariables,
  APITypes.SyncStrategiesQuery
>;
export const getFaqItem = /* GraphQL */ `query GetFaqItem($id: ID!) {
  getFaqItem(id: $id) {
    id
    question
    answer
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFaqItemQueryVariables,
  APITypes.GetFaqItemQuery
>;
export const listFaqItems = /* GraphQL */ `query ListFaqItems(
  $filter: ModelFaqItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listFaqItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      question
      answer
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFaqItemsQueryVariables,
  APITypes.ListFaqItemsQuery
>;
export const syncFaqItems = /* GraphQL */ `query SyncFaqItems(
  $filter: ModelFaqItemFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFaqItems(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      question
      answer
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFaqItemsQueryVariables,
  APITypes.SyncFaqItemsQuery
>;
export const getUserQuestion = /* GraphQL */ `query GetUserQuestion($id: ID!) {
  getUserQuestion(id: $id) {
    id
    requestId
    question
    answer
    documents {
      id
      rfpFileId
      rfpFileName
      question
      answer
      status
      isExcluded
      datetime
      score
      __typename
    }
    generationCompleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserQuestionQueryVariables,
  APITypes.GetUserQuestionQuery
>;
export const listUserQuestions = /* GraphQL */ `query ListUserQuestions(
  $filter: ModelUserQuestionFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      requestId
      question
      answer
      generationCompleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserQuestionsQueryVariables,
  APITypes.ListUserQuestionsQuery
>;
export const syncUserQuestions = /* GraphQL */ `query SyncUserQuestions(
  $filter: ModelUserQuestionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUserQuestions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      requestId
      question
      answer
      generationCompleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncUserQuestionsQueryVariables,
  APITypes.SyncUserQuestionsQuery
>;
export const getUserQuestionCompliance = /* GraphQL */ `query GetUserQuestionCompliance($id: ID!) {
  getUserQuestionCompliance(id: $id) {
    id
    msg
    requestId
    warnings {
      extracted_data
      description
      __typename
    }
    generationCompleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserQuestionComplianceQueryVariables,
  APITypes.GetUserQuestionComplianceQuery
>;
export const listUserQuestionCompliances = /* GraphQL */ `query ListUserQuestionCompliances(
  $filter: ModelUserQuestionComplianceFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserQuestionCompliances(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      msg
      requestId
      generationCompleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserQuestionCompliancesQueryVariables,
  APITypes.ListUserQuestionCompliancesQuery
>;
export const syncUserQuestionCompliances = /* GraphQL */ `query SyncUserQuestionCompliances(
  $filter: ModelUserQuestionComplianceFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUserQuestionCompliances(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      msg
      requestId
      generationCompleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncUserQuestionCompliancesQueryVariables,
  APITypes.SyncUserQuestionCompliancesQuery
>;
export const getRfpToAnswerFile = /* GraphQL */ `query GetRfpToAnswerFile($id: ID!) {
  getRfpToAnswerFile(id: $id) {
    id
    name
    type
    s3Bucket
    s3Key
    status
    size
    nbQuestions
    datetime
    items {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRfpToAnswerFileQueryVariables,
  APITypes.GetRfpToAnswerFileQuery
>;
export const listRfpToAnswerFiles = /* GraphQL */ `query ListRfpToAnswerFiles(
  $filter: ModelRfpToAnswerFileFilterInput
  $limit: Int
  $nextToken: String
) {
  listRfpToAnswerFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      type
      s3Bucket
      s3Key
      status
      size
      nbQuestions
      datetime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRfpToAnswerFilesQueryVariables,
  APITypes.ListRfpToAnswerFilesQuery
>;
export const syncRfpToAnswerFiles = /* GraphQL */ `query SyncRfpToAnswerFiles(
  $filter: ModelRfpToAnswerFileFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncRfpToAnswerFiles(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      type
      s3Bucket
      s3Key
      status
      size
      nbQuestions
      datetime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncRfpToAnswerFilesQueryVariables,
  APITypes.SyncRfpToAnswerFilesQuery
>;
export const getRfpToAnswerFileItem = /* GraphQL */ `query GetRfpToAnswerFileItem($id: ID!) {
  getRfpToAnswerFileItem(id: $id) {
    id
    rfpToAnswerFileId
    rfpToAnswerFile {
      id
      name
      type
      s3Bucket
      s3Key
      status
      size
      nbQuestions
      datetime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    question
    answer
    datetime
    documents
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRfpToAnswerFileItemQueryVariables,
  APITypes.GetRfpToAnswerFileItemQuery
>;
export const listRfpToAnswerFileItems = /* GraphQL */ `query ListRfpToAnswerFileItems(
  $filter: ModelRfpToAnswerFileItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listRfpToAnswerFileItems(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      rfpToAnswerFileId
      question
      answer
      datetime
      documents
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRfpToAnswerFileItemsQueryVariables,
  APITypes.ListRfpToAnswerFileItemsQuery
>;
export const syncRfpToAnswerFileItems = /* GraphQL */ `query SyncRfpToAnswerFileItems(
  $filter: ModelRfpToAnswerFileItemFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncRfpToAnswerFileItems(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      rfpToAnswerFileId
      question
      answer
      datetime
      documents
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncRfpToAnswerFileItemsQueryVariables,
  APITypes.SyncRfpToAnswerFileItemsQuery
>;
export const rfpToAnswerFileItemsByRfpToAnswerFileId = /* GraphQL */ `query RfpToAnswerFileItemsByRfpToAnswerFileId(
  $rfpToAnswerFileId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelRfpToAnswerFileItemFilterInput
  $limit: Int
  $nextToken: String
) {
  rfpToAnswerFileItemsByRfpToAnswerFileId(
    rfpToAnswerFileId: $rfpToAnswerFileId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      rfpToAnswerFileId
      question
      answer
      datetime
      documents
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RfpToAnswerFileItemsByRfpToAnswerFileIdQueryVariables,
  APITypes.RfpToAnswerFileItemsByRfpToAnswerFileIdQuery
>;
export const addQuestionKnowledgeBase = /* GraphQL */ `query AddQuestionKnowledgeBase(
  $rfpId: String
  $question: String
  $answer: String
) {
  addQuestionKnowledgeBase(rfpId: $rfpId, question: $question, answer: $answer)
}
` as GeneratedQuery<
  APITypes.AddQuestionKnowledgeBaseQueryVariables,
  APITypes.AddQuestionKnowledgeBaseQuery
>;
export const deleteFile = /* GraphQL */ `query DeleteFile($rfpId: String) {
  deleteFile(rfpId: $rfpId)
}
` as GeneratedQuery<
  APITypes.DeleteFileQueryVariables,
  APITypes.DeleteFileQuery
>;
export const deleteQuestion = /* GraphQL */ `query DeleteQuestion($questionId: String) {
  deleteQuestion(questionId: $questionId)
}
` as GeneratedQuery<
  APITypes.DeleteQuestionQueryVariables,
  APITypes.DeleteQuestionQuery
>;
export const queryDeleteStrategy = /* GraphQL */ `query QueryDeleteStrategy($strategyId: String) {
  queryDeleteStrategy(strategyId: $strategyId) {
    deleted
    result
    __typename
  }
}
` as GeneratedQuery<
  APITypes.QueryDeleteStrategyQueryVariables,
  APITypes.QueryDeleteStrategyQuery
>;
export const getRfpQuestionResponse = /* GraphQL */ `query GetRfpQuestionResponse(
  $msg: String
  $sessionMsg: [SessionInput]
  $strategy: String
) {
  getRfpQuestionResponse(
    msg: $msg
    sessionMsg: $sessionMsg
    strategy: $strategy
  )
}
` as GeneratedQuery<
  APITypes.GetRfpQuestionResponseQueryVariables,
  APITypes.GetRfpQuestionResponseQuery
>;
export const getRfpResponseComplianceCheck = /* GraphQL */ `query GetRfpResponseComplianceCheck($msg: String) {
  getRfpResponseComplianceCheck(msg: $msg)
}
` as GeneratedQuery<
  APITypes.GetRfpResponseComplianceCheckQueryVariables,
  APITypes.GetRfpResponseComplianceCheckQuery
>;
export const getRfpPastResponsesFromPrompt = /* GraphQL */ `query GetRfpPastResponsesFromPrompt($msg: String, $strategy: String) {
  getRfpPastResponsesFromPrompt(msg: $msg, strategy: $strategy) {
    result
    documents {
      id
      rfpFileId
      rfpFileName
      question
      answer
      status
      isExcluded
      datetime
      score
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRfpPastResponsesFromPromptQueryVariables,
  APITypes.GetRfpPastResponsesFromPromptQuery
>;
export const removeQuestionKnowledgeBase = /* GraphQL */ `query RemoveQuestionKnowledgeBase($questionId: String) {
  removeQuestionKnowledgeBase(questionId: $questionId)
}
` as GeneratedQuery<
  APITypes.RemoveQuestionKnowledgeBaseQueryVariables,
  APITypes.RemoveQuestionKnowledgeBaseQuery
>;
export const updateQuestionKnowledgeBase = /* GraphQL */ `query UpdateQuestionKnowledgeBase(
  $questionId: String
  $question: String
  $answer: String
) {
  updateQuestionKnowledgeBase(
    questionId: $questionId
    question: $question
    answer: $answer
  )
}
` as GeneratedQuery<
  APITypes.UpdateQuestionKnowledgeBaseQueryVariables,
  APITypes.UpdateQuestionKnowledgeBaseQuery
>;
export const addBackQuestionKnowledgeBase = /* GraphQL */ `query AddBackQuestionKnowledgeBase($questionId: String) {
  addBackQuestionKnowledgeBase(questionId: $questionId)
}
` as GeneratedQuery<
  APITypes.AddBackQuestionKnowledgeBaseQueryVariables,
  APITypes.AddBackQuestionKnowledgeBaseQuery
>;
export const responsePresignedGet = /* GraphQL */ `query ResponsePresignedGet($objectName: String) {
  responsePresignedGet(objectName: $objectName)
}
` as GeneratedQuery<
  APITypes.ResponsePresignedGetQueryVariables,
  APITypes.ResponsePresignedGetQuery
>;
export const processNewDocument = /* GraphQL */ `query ProcessNewDocument(
  $key: String
  $strategy: String
  $dateDocument: String
) {
  processNewDocument(
    key: $key
    strategy: $strategy
    dateDocument: $dateDocument
  )
}
` as GeneratedQuery<
  APITypes.ProcessNewDocumentQueryVariables,
  APITypes.ProcessNewDocumentQuery
>;
export const processRfpDocumentToAnswer = /* GraphQL */ `query ProcessRfpDocumentToAnswer($key: String) {
  processRfpDocumentToAnswer(key: $key)
}
` as GeneratedQuery<
  APITypes.ProcessRfpDocumentToAnswerQueryVariables,
  APITypes.ProcessRfpDocumentToAnswerQuery
>;
export const deleteRfpDocumentToAnswer = /* GraphQL */ `query DeleteRfpDocumentToAnswer($rfpId: String) {
  deleteRfpDocumentToAnswer(rfpId: $rfpId)
}
` as GeneratedQuery<
  APITypes.DeleteRfpDocumentToAnswerQueryVariables,
  APITypes.DeleteRfpDocumentToAnswerQuery
>;
export const responsePresignedPut = /* GraphQL */ `query ResponsePresignedPut($docId: String, $target: String) {
  responsePresignedPut(docId: $docId, target: $target)
}
` as GeneratedQuery<
  APITypes.ResponsePresignedPutQueryVariables,
  APITypes.ResponsePresignedPutQuery
>;
export const uploadNewImage = /* GraphQL */ `query UploadNewImage($key: String) {
  uploadNewImage(key: $key) {
    message
    status
    image
    presignedUrl
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UploadNewImageQueryVariables,
  APITypes.UploadNewImageQuery
>;
export const deleteRfpDocumentToAnswerItem = /* GraphQL */ `query DeleteRfpDocumentToAnswerItem($questionId: String) {
  deleteRfpDocumentToAnswerItem(questionId: $questionId)
}
` as GeneratedQuery<
  APITypes.DeleteRfpDocumentToAnswerItemQueryVariables,
  APITypes.DeleteRfpDocumentToAnswerItemQuery
>;
export const updateRfpDocumentToAnswerItem = /* GraphQL */ `query UpdateRfpDocumentToAnswerItem(
  $questionId: String
  $question: String
  $answer: String
) {
  updateRfpDocumentToAnswerItem(
    questionId: $questionId
    question: $question
    answer: $answer
  )
}
` as GeneratedQuery<
  APITypes.UpdateRfpDocumentToAnswerItemQueryVariables,
  APITypes.UpdateRfpDocumentToAnswerItemQuery
>;
export const checkQuestionForSimilarity = /* GraphQL */ `query CheckQuestionForSimilarity($questionId: String) {
  checkQuestionForSimilarity(questionId: $questionId) {
    result
    documents {
      id
      rfpFileId
      rfpFileName
      question
      answer
      status
      isExcluded
      datetime
      score
      __typename
    }
    maxScore
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CheckQuestionForSimilarityQueryVariables,
  APITypes.CheckQuestionForSimilarityQuery
>;
export const getRfpHtmlDocument = /* GraphQL */ `query GetRfpHtmlDocument($rfpId: String, $type: String) {
  getRfpHtmlDocument(rfpId: $rfpId, type: $type) {
    success
    html
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRfpHtmlDocumentQueryVariables,
  APITypes.GetRfpHtmlDocumentQuery
>;
export const processHtmlRfpDocument = /* GraphQL */ `query ProcessHtmlRfpDocument(
  $rfpId: String
  $listQuestions: [QuestionInput]
  $type: String
) {
  processHtmlRfpDocument(
    rfpId: $rfpId
    listQuestions: $listQuestions
    type: $type
  ) {
    success
    message
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProcessHtmlRfpDocumentQueryVariables,
  APITypes.ProcessHtmlRfpDocumentQuery
>;
