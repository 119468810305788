/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createMessageAvatar = /* GraphQL */ `mutation CreateMessageAvatar(
  $input: CreateMessageAvatarInput!
  $condition: ModelMessageAvatarConditionInput
) {
  createMessageAvatar(input: $input, condition: $condition) {
    id
    msg
    type
    datetime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMessageAvatarMutationVariables,
  APITypes.CreateMessageAvatarMutation
>;
export const updateMessageAvatar = /* GraphQL */ `mutation UpdateMessageAvatar(
  $input: UpdateMessageAvatarInput!
  $condition: ModelMessageAvatarConditionInput
) {
  updateMessageAvatar(input: $input, condition: $condition) {
    id
    msg
    type
    datetime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMessageAvatarMutationVariables,
  APITypes.UpdateMessageAvatarMutation
>;
export const deleteMessageAvatar = /* GraphQL */ `mutation DeleteMessageAvatar(
  $input: DeleteMessageAvatarInput!
  $condition: ModelMessageAvatarConditionInput
) {
  deleteMessageAvatar(input: $input, condition: $condition) {
    id
    msg
    type
    datetime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMessageAvatarMutationVariables,
  APITypes.DeleteMessageAvatarMutation
>;
export const createRfpFile = /* GraphQL */ `mutation CreateRfpFile(
  $input: CreateRfpFileInput!
  $condition: ModelRfpFileConditionInput
) {
  createRfpFile(input: $input, condition: $condition) {
    id
    name
    type
    s3Bucket
    s3Key
    status
    size
    nbQuestions
    datetime
    strategy {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      strategyRfpFilesId
      __typename
    }
    isExcluded
    items {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    rfpFileStrategyId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRfpFileMutationVariables,
  APITypes.CreateRfpFileMutation
>;
export const updateRfpFile = /* GraphQL */ `mutation UpdateRfpFile(
  $input: UpdateRfpFileInput!
  $condition: ModelRfpFileConditionInput
) {
  updateRfpFile(input: $input, condition: $condition) {
    id
    name
    type
    s3Bucket
    s3Key
    status
    size
    nbQuestions
    datetime
    strategy {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      strategyRfpFilesId
      __typename
    }
    isExcluded
    items {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    rfpFileStrategyId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRfpFileMutationVariables,
  APITypes.UpdateRfpFileMutation
>;
export const deleteRfpFile = /* GraphQL */ `mutation DeleteRfpFile(
  $input: DeleteRfpFileInput!
  $condition: ModelRfpFileConditionInput
) {
  deleteRfpFile(input: $input, condition: $condition) {
    id
    name
    type
    s3Bucket
    s3Key
    status
    size
    nbQuestions
    datetime
    strategy {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      strategyRfpFilesId
      __typename
    }
    isExcluded
    items {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    rfpFileStrategyId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRfpFileMutationVariables,
  APITypes.DeleteRfpFileMutation
>;
export const createRfpFileItem = /* GraphQL */ `mutation CreateRfpFileItem(
  $input: CreateRfpFileItemInput!
  $condition: ModelRfpFileItemConditionInput
) {
  createRfpFileItem(input: $input, condition: $condition) {
    id
    rfpFileId
    rfpFile {
      id
      name
      type
      s3Bucket
      s3Key
      status
      size
      nbQuestions
      datetime
      isExcluded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      rfpFileStrategyId
      __typename
    }
    question
    answer
    status
    faissId
    isExcluded
    datetime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRfpFileItemMutationVariables,
  APITypes.CreateRfpFileItemMutation
>;
export const updateRfpFileItem = /* GraphQL */ `mutation UpdateRfpFileItem(
  $input: UpdateRfpFileItemInput!
  $condition: ModelRfpFileItemConditionInput
) {
  updateRfpFileItem(input: $input, condition: $condition) {
    id
    rfpFileId
    rfpFile {
      id
      name
      type
      s3Bucket
      s3Key
      status
      size
      nbQuestions
      datetime
      isExcluded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      rfpFileStrategyId
      __typename
    }
    question
    answer
    status
    faissId
    isExcluded
    datetime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRfpFileItemMutationVariables,
  APITypes.UpdateRfpFileItemMutation
>;
export const deleteRfpFileItem = /* GraphQL */ `mutation DeleteRfpFileItem(
  $input: DeleteRfpFileItemInput!
  $condition: ModelRfpFileItemConditionInput
) {
  deleteRfpFileItem(input: $input, condition: $condition) {
    id
    rfpFileId
    rfpFile {
      id
      name
      type
      s3Bucket
      s3Key
      status
      size
      nbQuestions
      datetime
      isExcluded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      rfpFileStrategyId
      __typename
    }
    question
    answer
    status
    faissId
    isExcluded
    datetime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRfpFileItemMutationVariables,
  APITypes.DeleteRfpFileItemMutation
>;
export const createStrategy = /* GraphQL */ `mutation CreateStrategy(
  $input: CreateStrategyInput!
  $condition: ModelStrategyConditionInput
) {
  createStrategy(input: $input, condition: $condition) {
    id
    name
    rfpFiles {
      id
      name
      type
      s3Bucket
      s3Key
      status
      size
      nbQuestions
      datetime
      isExcluded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      rfpFileStrategyId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    strategyRfpFilesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStrategyMutationVariables,
  APITypes.CreateStrategyMutation
>;
export const updateStrategy = /* GraphQL */ `mutation UpdateStrategy(
  $input: UpdateStrategyInput!
  $condition: ModelStrategyConditionInput
) {
  updateStrategy(input: $input, condition: $condition) {
    id
    name
    rfpFiles {
      id
      name
      type
      s3Bucket
      s3Key
      status
      size
      nbQuestions
      datetime
      isExcluded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      rfpFileStrategyId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    strategyRfpFilesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStrategyMutationVariables,
  APITypes.UpdateStrategyMutation
>;
export const deleteStrategy = /* GraphQL */ `mutation DeleteStrategy(
  $input: DeleteStrategyInput!
  $condition: ModelStrategyConditionInput
) {
  deleteStrategy(input: $input, condition: $condition) {
    id
    name
    rfpFiles {
      id
      name
      type
      s3Bucket
      s3Key
      status
      size
      nbQuestions
      datetime
      isExcluded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      rfpFileStrategyId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    strategyRfpFilesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStrategyMutationVariables,
  APITypes.DeleteStrategyMutation
>;
export const createFaqItem = /* GraphQL */ `mutation CreateFaqItem(
  $input: CreateFaqItemInput!
  $condition: ModelFaqItemConditionInput
) {
  createFaqItem(input: $input, condition: $condition) {
    id
    question
    answer
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFaqItemMutationVariables,
  APITypes.CreateFaqItemMutation
>;
export const updateFaqItem = /* GraphQL */ `mutation UpdateFaqItem(
  $input: UpdateFaqItemInput!
  $condition: ModelFaqItemConditionInput
) {
  updateFaqItem(input: $input, condition: $condition) {
    id
    question
    answer
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFaqItemMutationVariables,
  APITypes.UpdateFaqItemMutation
>;
export const deleteFaqItem = /* GraphQL */ `mutation DeleteFaqItem(
  $input: DeleteFaqItemInput!
  $condition: ModelFaqItemConditionInput
) {
  deleteFaqItem(input: $input, condition: $condition) {
    id
    question
    answer
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFaqItemMutationVariables,
  APITypes.DeleteFaqItemMutation
>;
export const createUserQuestion = /* GraphQL */ `mutation CreateUserQuestion(
  $input: CreateUserQuestionInput!
  $condition: ModelUserQuestionConditionInput
) {
  createUserQuestion(input: $input, condition: $condition) {
    id
    requestId
    question
    answer
    documents {
      id
      rfpFileId
      rfpFileName
      question
      answer
      status
      isExcluded
      datetime
      score
      __typename
    }
    generationCompleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserQuestionMutationVariables,
  APITypes.CreateUserQuestionMutation
>;
export const updateUserQuestion = /* GraphQL */ `mutation UpdateUserQuestion(
  $input: UpdateUserQuestionInput!
  $condition: ModelUserQuestionConditionInput
) {
  updateUserQuestion(input: $input, condition: $condition) {
    id
    requestId
    question
    answer
    documents {
      id
      rfpFileId
      rfpFileName
      question
      answer
      status
      isExcluded
      datetime
      score
      __typename
    }
    generationCompleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserQuestionMutationVariables,
  APITypes.UpdateUserQuestionMutation
>;
export const deleteUserQuestion = /* GraphQL */ `mutation DeleteUserQuestion(
  $input: DeleteUserQuestionInput!
  $condition: ModelUserQuestionConditionInput
) {
  deleteUserQuestion(input: $input, condition: $condition) {
    id
    requestId
    question
    answer
    documents {
      id
      rfpFileId
      rfpFileName
      question
      answer
      status
      isExcluded
      datetime
      score
      __typename
    }
    generationCompleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserQuestionMutationVariables,
  APITypes.DeleteUserQuestionMutation
>;
export const createUserQuestionCompliance = /* GraphQL */ `mutation CreateUserQuestionCompliance(
  $input: CreateUserQuestionComplianceInput!
  $condition: ModelUserQuestionComplianceConditionInput
) {
  createUserQuestionCompliance(input: $input, condition: $condition) {
    id
    msg
    requestId
    warnings {
      extracted_data
      description
      __typename
    }
    generationCompleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserQuestionComplianceMutationVariables,
  APITypes.CreateUserQuestionComplianceMutation
>;
export const updateUserQuestionCompliance = /* GraphQL */ `mutation UpdateUserQuestionCompliance(
  $input: UpdateUserQuestionComplianceInput!
  $condition: ModelUserQuestionComplianceConditionInput
) {
  updateUserQuestionCompliance(input: $input, condition: $condition) {
    id
    msg
    requestId
    warnings {
      extracted_data
      description
      __typename
    }
    generationCompleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserQuestionComplianceMutationVariables,
  APITypes.UpdateUserQuestionComplianceMutation
>;
export const deleteUserQuestionCompliance = /* GraphQL */ `mutation DeleteUserQuestionCompliance(
  $input: DeleteUserQuestionComplianceInput!
  $condition: ModelUserQuestionComplianceConditionInput
) {
  deleteUserQuestionCompliance(input: $input, condition: $condition) {
    id
    msg
    requestId
    warnings {
      extracted_data
      description
      __typename
    }
    generationCompleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserQuestionComplianceMutationVariables,
  APITypes.DeleteUserQuestionComplianceMutation
>;
export const createRfpToAnswerFile = /* GraphQL */ `mutation CreateRfpToAnswerFile(
  $input: CreateRfpToAnswerFileInput!
  $condition: ModelRfpToAnswerFileConditionInput
) {
  createRfpToAnswerFile(input: $input, condition: $condition) {
    id
    name
    type
    s3Bucket
    s3Key
    status
    size
    nbQuestions
    datetime
    items {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRfpToAnswerFileMutationVariables,
  APITypes.CreateRfpToAnswerFileMutation
>;
export const updateRfpToAnswerFile = /* GraphQL */ `mutation UpdateRfpToAnswerFile(
  $input: UpdateRfpToAnswerFileInput!
  $condition: ModelRfpToAnswerFileConditionInput
) {
  updateRfpToAnswerFile(input: $input, condition: $condition) {
    id
    name
    type
    s3Bucket
    s3Key
    status
    size
    nbQuestions
    datetime
    items {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRfpToAnswerFileMutationVariables,
  APITypes.UpdateRfpToAnswerFileMutation
>;
export const deleteRfpToAnswerFile = /* GraphQL */ `mutation DeleteRfpToAnswerFile(
  $input: DeleteRfpToAnswerFileInput!
  $condition: ModelRfpToAnswerFileConditionInput
) {
  deleteRfpToAnswerFile(input: $input, condition: $condition) {
    id
    name
    type
    s3Bucket
    s3Key
    status
    size
    nbQuestions
    datetime
    items {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRfpToAnswerFileMutationVariables,
  APITypes.DeleteRfpToAnswerFileMutation
>;
export const createRfpToAnswerFileItem = /* GraphQL */ `mutation CreateRfpToAnswerFileItem(
  $input: CreateRfpToAnswerFileItemInput!
  $condition: ModelRfpToAnswerFileItemConditionInput
) {
  createRfpToAnswerFileItem(input: $input, condition: $condition) {
    id
    rfpToAnswerFileId
    rfpToAnswerFile {
      id
      name
      type
      s3Bucket
      s3Key
      status
      size
      nbQuestions
      datetime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    question
    answer
    datetime
    documents
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRfpToAnswerFileItemMutationVariables,
  APITypes.CreateRfpToAnswerFileItemMutation
>;
export const updateRfpToAnswerFileItem = /* GraphQL */ `mutation UpdateRfpToAnswerFileItem(
  $input: UpdateRfpToAnswerFileItemInput!
  $condition: ModelRfpToAnswerFileItemConditionInput
) {
  updateRfpToAnswerFileItem(input: $input, condition: $condition) {
    id
    rfpToAnswerFileId
    rfpToAnswerFile {
      id
      name
      type
      s3Bucket
      s3Key
      status
      size
      nbQuestions
      datetime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    question
    answer
    datetime
    documents
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRfpToAnswerFileItemMutationVariables,
  APITypes.UpdateRfpToAnswerFileItemMutation
>;
export const deleteRfpToAnswerFileItem = /* GraphQL */ `mutation DeleteRfpToAnswerFileItem(
  $input: DeleteRfpToAnswerFileItemInput!
  $condition: ModelRfpToAnswerFileItemConditionInput
) {
  deleteRfpToAnswerFileItem(input: $input, condition: $condition) {
    id
    rfpToAnswerFileId
    rfpToAnswerFile {
      id
      name
      type
      s3Bucket
      s3Key
      status
      size
      nbQuestions
      datetime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    question
    answer
    datetime
    documents
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRfpToAnswerFileItemMutationVariables,
  APITypes.DeleteRfpToAnswerFileItemMutation
>;
export const postResponseComplianceCheck = /* GraphQL */ `mutation PostResponseComplianceCheck($msg: String) {
  postResponseComplianceCheck(msg: $msg) {
    warnings {
      extracted_data
      description
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.PostResponseComplianceCheckMutationVariables,
  APITypes.PostResponseComplianceCheckMutation
>;
