/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon, View } from "@aws-amplify/ui-react";
export default function DownloadIcon(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="24px"
      height="24px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "DownloadIcon")}
      {...rest}
    >
      <View
        width="24px"
        height="24px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "download")}
      >
        <Icon
          width="16px"
          height="16px"
          viewBox={{ minX: 0, minY: 0, width: 16, height: 16 }}
          paths={[
            {
              d: "M1 12C1 11.4477 0.552285 11 0 11C-0.552285 11 -1 11.4477 -1 12L1 12ZM3 16L3 17L3 16ZM13 16L13 17L13 16ZM16 13L17 13L16 13ZM17 12C17 11.4477 16.5523 11 16 11C15.4477 11 15 11.4477 15 12L17 12ZM12.7071 8.70711C13.0976 8.31658 13.0976 7.68342 12.7071 7.29289C12.3166 6.90237 11.6834 6.90237 11.2929 7.29289L12.7071 8.70711ZM8 12L7.29289 12.7071C7.68342 13.0976 8.31658 13.0976 8.70711 12.7071L8 12ZM4.70711 7.29289C4.31658 6.90237 3.68342 6.90237 3.29289 7.29289C2.90237 7.68342 2.90237 8.31658 3.29289 8.70711L4.70711 7.29289ZM9 0C9 -0.552285 8.55229 -1 8 -1C7.44772 -1 7 -0.552285 7 0L9 0ZM-1 12L-1 13L1 13L1 12L-1 12ZM-1 13C-1 14.0609 -0.578573 15.0783 0.171573 15.8284L1.58579 14.4142C1.21071 14.0391 1 13.5304 1 13L-1 13ZM0.171573 15.8284C0.921719 16.5786 1.93913 17 3 17L3 15C2.46957 15 1.96086 14.7893 1.58579 14.4142L0.171573 15.8284ZM3 17L13 17L13 15L3 15L3 17ZM13 17C14.0609 17 15.0783 16.5786 15.8284 15.8284L14.4142 14.4142C14.0391 14.7893 13.5304 15 13 15L13 17ZM15.8284 15.8284C16.5786 15.0783 17 14.0609 17 13L15 13C15 13.5304 14.7893 14.0391 14.4142 14.4142L15.8284 15.8284ZM17 13L17 12L15 12L15 13L17 13ZM11.2929 7.29289L7.29289 11.2929L8.70711 12.7071L12.7071 8.70711L11.2929 7.29289ZM8.70711 11.2929L4.70711 7.29289L3.29289 8.70711L7.29289 12.7071L8.70711 11.2929ZM9 12L9 0L7 0L7 12L9 12Z",
              stroke: "rgba(149,4,4,1)",
              fillRule: "nonzero",
              strokeLinejoin: "round",
              strokeWidth: 2,
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="16.67%"
          bottom="16.67%"
          left="16.67%"
          right="16.67%"
          {...getOverrideProps(overrides, "Vector")}
        ></Icon>
      </View>
    </View>
  );
}
