import { useEffect, useState, useRef } from 'react';
import { useAlert } from '../hooks/useAlert';
import { Badge, Flex, Button, Card, Text, Heading, Accordion, TextAreaField, Breadcrumbs, SelectField, View, Alert } from '@aws-amplify/ui-react';
import Page from '../components/Page';
import { formatDateISO, copyToClipboard, getIsExcludedText, getIsExcludedVariation, customReactModalStyles, editorKey, tinymceConfig, convertResponseToHtml, convertImagePathBeforeSaving } from '../utils';
import RfpItemLayout from '../ui-components/RfpItemLayout';
import QuestionLayout from '../ui-components/QuestionLayout';
import Modal from 'react-modal';
import { studioTheme } from '../ui-components';
import { ThemeProvider } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import parse from 'html-react-parser';
import { generateClient } from 'aws-amplify/api';

import * as queries from '../graphql/queries';
import { GraphQLQuery } from '@aws-amplify/api';
import { CheckQuestionForSimilarityQuery, Strategy, ListRfpFileItemsQuery, AddBackQuestionKnowledgeBaseQuery, DeleteQuestionQuery, RemoveQuestionKnowledgeBaseQuery, UpdateQuestionKnowledgeBaseQuery, ListStrategiesQuery, GetRfpPastResponsesFromPromptQuery } from '../API';



export default function Questions() {
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const navigate = useNavigate();
  const alert = useAlert({ variation: 'error' });
  const snackbar = useAlert({ variation: 'success' });
  const [responseData, setResponseData] = useState<any>();
  const [questionRequestedValue, setQuestionRequestedValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteQuestionId, setDeleteQuestionId] = useState<string | null | undefined>("");
  const [removeQuestionId, setRemoveQuestionId] = useState<string | null | undefined>("");
  const [removeQuestionIsExcluded, setRemoveQuestionIsExcluded] = useState<boolean | null | undefined>(false);
  const [editQuestionId, setEditQuestionId] = useState<string | null | undefined>("");
  const [answerValue, setAnswerValue] = useState<string | null | undefined>("");
  const [questionValue, setQuestionValue] = useState<string | null | undefined>("");
  const [modalConfirmatonDeleteIsOpen, setModalConfirmatonDeleteIsOpen] = useState(false);
  const [modalConfirmatonRemoveIsOpen, setModalConfirmatonRemoveIsOpen] = useState(false);
  const [modalConfirmatonEditIsOpen, setModalConfirmatonEditIsOpen] = useState(false);
  const [selectedStrategy, setSelectedStrategy] = useState<string>("all");
  const [listStrategy, setListStrategy] = useState<any>(null);
  const [rfpItemsLoading, setRfpItemsLoading] = useState<any>([]);
  const API = generateClient();

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter' && !isLoading) {
      sendMessage()
    }
  }

  useEffect(() => {
    async function fetchCategories() {
      try {
        const allStrategy = await API.graphql<GraphQLQuery<ListStrategiesQuery>>(
          { query: queries.listStrategies }
        );
        setListStrategy(allStrategy.data?.listStrategies?.items)

      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    }
    fetchCategories();
  }, []);


  async function refreshQuestions() {
    // Fetch data from the amplify graphql API table RfpFile
    try {
      const ids = responseData.documents.map((item: any) => ({ id: { eq: item.id } }));
      const filter = {
        or: ids
      }
      // Fetch data from the amplify graphql API table RfpFile
      const questions = await API.graphql<GraphQLQuery<ListRfpFileItemsQuery>>(
        { query: queries.listRfpFileItems, variables: { filter: filter } }
      );
      // record image with presigned url
      let presignedUrlDone: { [key: string]: string } = {};

      // Assuming file.data?.getRfpFile?.items?.items is an array
      const sortedItems = questions.data?.listRfpFileItems?.items;
      // Sort items with same order as ids
      const items = sortedItems?.sort((a, b) => ids.findIndex((id: any) => id.id.eq === a?.id) - ids.findIndex((id: any) => id.id.eq === b?.id));


      if (items) {
        const processItems = async () => {
          const promises = items.map(async (item: any) => {
            const newHtml = await convertResponseToHtml(item.answer, presignedUrlDone, []);
            item.answer = newHtml;
          });
          // Wait for all promises to resolve
          await Promise.all(promises);
        };

        // Call the async function
        processItems().then(() => {
          // All promises have resolved, you can continue with the next lines of code here
          setResponseData({ ...responseData, documents: questions.data?.listRfpFileItems?.items })
          // const rfpItemsWithIsLoading = questions.data?.listRfpFileItems?.items?.map((item: any) => {
          //   return { id: item.id, isLoading: false, similarities: null };
          // });
          const rfpItemsWithIsLoading = questions.data?.listRfpFileItems?.items?.map((item: any) => {
            const existingItem = rfpItemsLoading.find((loadingItem: any) => loadingItem.id === item.id);
            return {
              id: item.id,
              isLoading: false,
              similarities: existingItem ? existingItem.similarities : null
            };
          });
          setRfpItemsLoading(rfpItemsWithIsLoading);
        });
      }
    } catch (error) {
      console.error('Error fetching document data:', error);
    }
  }


  async function refreshQuestionsFromResponse(response: any) {
    // Fetch data from the amplify graphql API table RfpFile
    try {
      let presignedUrlDone: { [key: string]: string } = {};
      const items = response.documents
      if (items) {
        const processItems = async () => {
          const promises = items.map(async (item: any) => {
            const newHtml = await convertResponseToHtml(item.answer, presignedUrlDone,[]);
            item.answer = newHtml;
          });
          // Wait for all promises to resolve
          await Promise.all(promises);
        };

        // Call the async function
        processItems().then(() => {
          // All promises have resolved, you can continue with the next lines of code here
          const newResponse = { ...response, documents: response.documents }
          setResponseData(newResponse)
          // const rfpItemsWithIsLoading = response.documents?.map((item: any) => {
          //   return { id: item.id, isLoading: false, similarities: null };
          // });
          const rfpItemsWithIsLoading = response.documents?.map((item: any) => {
            const existingItem = rfpItemsLoading.find((loadingItem: any) => loadingItem.id === item.id);
            return {
              id: item.id,
              isLoading: false,
              similarities: existingItem ? existingItem.similarities : null
            };
          });
          setRfpItemsLoading(rfpItemsWithIsLoading);
        });
      }
    } catch (error) {
      console.error('Error fetching document data:', error);
    }
  }

  // sendMessage function that send the message to the graphql api
  const sendMessage = async () => {
    setIsLoading(true);
    if (questionRequestedValue !== '') {
      try {
        const responseGenerate = await API.graphql<GraphQLQuery<GetRfpPastResponsesFromPromptQuery>>(
          { query: queries.getRfpPastResponsesFromPrompt, variables: { msg: questionRequestedValue, strategy: selectedStrategy } }
        );
        let response = responseGenerate.data?.getRfpPastResponsesFromPrompt?.result;
        const documents = responseGenerate.data?.getRfpPastResponsesFromPrompt?.documents;

        //console.log(responseGenerate.data?.getRfpPastResponsesFromPrompt);

        // Add the message to the 1st place in list of messages in documentData
        const isoDate = new Date().toISOString();
        const newResponse = { question: questionRequestedValue, answer: response, documents: documents, datetime: isoDate }
        setResponseData(newResponse)
        refreshQuestionsFromResponse(newResponse);
      }
      catch (error) {
        console.error('Error generating questions:', error);
        alert.showAlert('Error generating a response');
      }

    }
    setIsLoading(false);
  };


  //#######################################################################################################################
  function getModalConfirmation(modalBool: boolean, setModal: Function, callBackFunction: Function) {
    Modal.setAppElement('#root');
    return (
      <Modal
        isOpen={modalBool}
        style={customReactModalStyles}
      >
        <ThemeProvider theme={studioTheme}>
          <Flex direction={"column"} gap="20px">
            <Heading >Are you sure you want to continue?</Heading>
            <Flex direction={"row"} alignItems="space-between">
              <Button onClick={() => { callBackFunction(); setModal(false); }}>Yes</Button><Button onClick={() => setModal(false)}>No</Button>
            </Flex>
          </Flex>
        </ThemeProvider>
      </Modal>);
  }

  //#######################################################################################################################
  function getModalForm(text: string, modalBool: boolean, setModal: Function, callBackFunction: Function) {
    Modal.setAppElement('#root');
    return (
      <Modal
        isOpen={modalBool}
        style={customReactModalStyles}
      >
        <ThemeProvider theme={studioTheme}>
          <Flex direction={"column"} gap="20px" style={{ maxHeight: '90vh', overflowY: 'auto' }}>
            <Heading >{text}</Heading>
            <Flex direction={"column"} alignItems="space-between">
              <Text >Question</Text>
              <TextAreaField
                placeholder="..."
                label=""
                errorMessage="There is an error"
                rows={2}
                onChange={(event) => setQuestionValue(event.target.value)}
                defaultValue={questionValue as string}
              />
              <Flex className="test" direction={"column"}>
                <Text >Answer</Text>
                <Editor
                  onInit={(evt, editor) => editorRef.current = editor}
                  apiKey={editorKey}
                  init={tinymceConfig(editQuestionId as String, "processed")}
                  initialValue={answerValue as string}
                />
              </Flex>
            </Flex>
            <Flex direction={"row"} alignItems="space-between">
              <Button variation='primary' onClick={() => { callBackFunction(); setModal(false); }}>Save</Button><Button variation='link' onClick={() => setModal(false)}>Cancel</Button>
            </Flex>
          </Flex>
        </ThemeProvider>
      </Modal>);
  }


  function getSimilarityFrame(questionId: string) {
    // if not questionId key in questionId then return null
    if (!(rfpItemsLoading.find((rfpItem: any) => rfpItem.id === questionId)?.similarities)) {
      return (<View>
        <Button isLoading={rfpItemsLoading.find((rfpItem: any) => rfpItem.id === questionId)?.isLoading || false} variation='link' onClick={() => { checkSimilarity(questionId) }}> Check for Similar Questions</Button>
      </View>)
    }

    let jsxAlert = (<Alert width="100%" variation="info" hasIcon={true} heading="No significant similarities">
      <Button isLoading={rfpItemsLoading.find((rfpItem: any) => rfpItem.id === questionId)?.isLoading || false} variation='link' onClick={() => { checkSimilarity(questionId) }}>Check Again</Button>
    </Alert>);

    if (rfpItemsLoading.find((rfpItem: any) => rfpItem.id === questionId).similarities.maxScore > 0.7) {
      jsxAlert = (<Alert width="100%" variation="error" hasIcon={true} heading="Similar questions detected">
        <Button isLoading={rfpItemsLoading.find((rfpItem: any) => rfpItem.id === questionId)?.isLoading || false} variation='link' onClick={() => { checkSimilarity(questionId) }}>Check Again</Button>
      </Alert>)
    }

    return (<View width="100%">
      {jsxAlert}
      <Accordion.Container allowMultiple>

        <Accordion.Item value="1">
          <Accordion.Trigger>
            {rfpItemsLoading.find((rfpItem: any) => rfpItem.id === questionId).similarities.result}
            <Accordion.Icon />
          </Accordion.Trigger>
          <Accordion.Content>
            {rfpItemsLoading.find((rfpItem: any) => rfpItem.id === questionId).similarities.documents.map((item: any, index: number) => (

              <Accordion.Item value={"exp" + index}>
                <Accordion.Trigger>
                  <Flex direction="column" gap="0rem" >
                    <Flex justifyContent="space-between" alignItems="center">
                      <Text variation="secondary" as="span">
                        {`${item.question}`}
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Badge variation="info">{`${formatDateISO(item.datetime)}`}</Badge> <small>{`${item.rfpFile?.name ? item.rfpFile.name : ""}`}</small>
                    </Flex>
                  </Flex>
                  <Accordion.Icon />
                </Accordion.Trigger>
                <Accordion.Content>
                  < RfpItemLayout
                    key={index}
                    overrides={
                      {
                        RfpItemLayout: {
                          width: "100%"
                        },
                        ButtonFile: {
                          children: item.rfpFile?.name ? item.rfpFile.name : "File",
                          onClick: () => { navigate(`/documents/${item.rfpFileId}`); },
                        },
                        Question: { children: item.question },
                        Date: { children: formatDateISO(item.datetime) },
                        TextLayout: { children: (<View>{typeof item.answer === 'string' ? parse(item.answer) : ''}</View>) },
                        ButtonCopy: { // Copy to clipboard
                          onClick: () => { copyToClipboard(item.question + "\n" + item.answer, snackbar, alert) }
                        },
                        ButtonEdit: { // Edit
                          onClick: () => editQuestionConfirmation(item.id, item.question, item.answer), isLoading: isLoading
                        },
                        ButtonRemove: { // Update Knowledge base
                          children: item.isExcluded ? "Add to Search" : "Remove from Search",
                          onClick: () => removeQuestionConfirmation(item.id, item.isExcluded), isLoading: isLoading
                        },
                        // DELETE
                        ButtonDelete: { onClick: () => deleteQuestionConfirmation(item.id), isLoading: isLoading },
                        "StatusBadge": {
                          "children": getIsExcludedText(item.isExcluded),
                          "variation": getIsExcludedVariation(item.isExcluded)
                        }
                      }
                    }
                  />
                </Accordion.Content>
              </Accordion.Item>
            ))}
          </Accordion.Content>
        </Accordion.Item>
      </Accordion.Container>
    </View>
    )
  }

  async function checkSimilarity(questionId: string) {
    let presignedUrlDone: { [key: string]: string } = {};

    rfpItemsLoading.find((rfpItem: any) => rfpItem.id === questionId).isLoading = true;
    setRfpItemsLoading([...rfpItemsLoading]);
    // Fetch data from the amplify graphql API 
    const similarities = await API.graphql<GraphQLQuery<CheckQuestionForSimilarityQuery>>(
      { query: queries.checkQuestionForSimilarity, variables: { questionId: questionId } }
    );
    // Add signed url if any image in document.
    if (similarities.data?.checkQuestionForSimilarity?.documents) {
      const promises = similarities.data?.checkQuestionForSimilarity?.documents.map(async (item: any) => {
        const newHtml = await convertResponseToHtml(item.answer, presignedUrlDone,[]);
        item.answer = newHtml;
      });
      // Wait for all promises to resolve
      await Promise.all(promises);
    }
    // Record
    rfpItemsLoading.find((rfpItem: any) => rfpItem.id === questionId).similarities = similarities.data?.checkQuestionForSimilarity;
    rfpItemsLoading.find((rfpItem: any) => rfpItem.id === questionId).isLoading = false;
    setRfpItemsLoading([...rfpItemsLoading]);
    return 1
  }

  function editQuestionConfirmation(questionId: string | null | undefined, question: string | null | undefined, answer: string | null | undefined) {
    setEditQuestionId(questionId);
    setQuestionValue(question);
    setAnswerValue(answer);
    setModalConfirmatonEditIsOpen(true);
  }


  async function editQuestion() {
    if (editQuestionId === "") {
      return;
    }
    if (editorRef.current) {
      setIsLoading(true);
      const newHtml = convertImagePathBeforeSaving(editorRef.current.getContent());

      await API.graphql<GraphQLQuery<UpdateQuestionKnowledgeBaseQuery>>(
        {
          query: queries.updateQuestionKnowledgeBase, variables: {
            questionId: editQuestionId,
            question: questionValue,
            answer: newHtml // editorRef.current.getContent()
          }
        }
      );
      refreshQuestions();
      setIsLoading(false);
    }
    else {
      //console.log("No editorRef value");
      return;
    }
  }


  function deleteQuestionConfirmation(questionId: string | null | undefined) {
    setDeleteQuestionId(questionId);
    setModalConfirmatonDeleteIsOpen(true);
  }


  async function deleteQuestion() {
    if (deleteQuestionId === "") {
      return;
    }
    setIsLoading(true);
    await API.graphql<GraphQLQuery<DeleteQuestionQuery>>(
      {
        query: queries.deleteQuestion, variables: {
          questionId: deleteQuestionId
        }
      }
    );
    refreshQuestions();
    setIsLoading(false);
  }


  function removeQuestionConfirmation(questionId: string | null | undefined, isExcluded: boolean | null | undefined) {
    setRemoveQuestionId(questionId);
    setRemoveQuestionIsExcluded(isExcluded);
    setModalConfirmatonRemoveIsOpen(true);
  }


  async function removeQuestionFromDB() {
    if (removeQuestionId === "") {
      return;
    }
    setIsLoading(true);
    if (removeQuestionIsExcluded) {
      await API.graphql<GraphQLQuery<AddBackQuestionKnowledgeBaseQuery>>(
        {
          query: queries.addBackQuestionKnowledgeBase, variables: {
            questionId: removeQuestionId
          }
        }
      );
    } else {
      await API.graphql<GraphQLQuery<RemoveQuestionKnowledgeBaseQuery>>(
        {
          query: queries.removeQuestionKnowledgeBase, variables: {
            questionId: removeQuestionId
          }
        }
      );
    }
    refreshQuestions();
    setIsLoading(false);
  }


  function handleSelectChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setSelectedStrategy(event.target.value);
  }


  return (
    <Page title="Questions">
      <Flex gap="20px" direction={"column"}>
        <Breadcrumbs
          items={[
            {
              href: '/',
              label: 'Home',
            },
            {
              href: '/questions',
              label: 'Questions',
            }
          ]}
        />
        <snackbar.AlertComponent />
        <alert.AlertComponent />
        <Card>
          <Flex direction="column" alignItems="flex-start">
            <Heading level={4}>Search answered Questions</Heading>
            <Text
              variation="primary"
              as="p"

            >
              Semantic search to find past RFP questions and their document.
            </Text>
            <SelectField
              label="Select a Category"
              value={selectedStrategy}
              onChange={handleSelectChange}
            >
              <option value="all">All</option>
              {listStrategy?.map((strategy: Strategy, index: number) => (
                <option key={index} value={strategy.id}>{strategy.name}</option>
              ))}
            </SelectField>
          </Flex>
        </Card>
        <QuestionLayout
          overrides={{
            QuestionLayout: {
              width: "100%"
            },
            TextInputEditor: {
              width: "100%",
              height: "10%",
            },
            TextField: {
              placeholder: "Search for content...",
              value: questionRequestedValue,
              onChange: (event) => setQuestionRequestedValue((event.target as HTMLInputElement).value),
              onKeyDown: (event) => handleKeyDown(event)
            },
            PublishButton: {
              isLoading: isLoading,
              isDisabled: isLoading,
              onClick: () => sendMessage(),
              children: "Search"

            },
          }}
        />
        {getModalConfirmation(modalConfirmatonDeleteIsOpen, setModalConfirmatonDeleteIsOpen, deleteQuestion)}
        {getModalConfirmation(modalConfirmatonRemoveIsOpen, setModalConfirmatonRemoveIsOpen, removeQuestionFromDB)}
        {getModalForm("Edit Question", modalConfirmatonEditIsOpen, setModalConfirmatonEditIsOpen, editQuestion)}
        <Flex direction="column" justifyContent="center" alignItems="center" width="100%" >
          {responseData && <Card width={"100%"}>
            <Flex direction="column" width="100%" >
              <Card variation="elevated">
                <Flex direction="column" justifyContent="space-between" alignItems="left">
                  <Heading level={5}>Documents found</Heading>
                  <Accordion.Container allowMultiple>
                    {responseData.documents.map((item: any, index: number) => (
                      <Accordion.Item value={"exp" + index}>
                        <Accordion.Trigger>
                          <Flex direction="column" gap="0rem" >
                            <Flex justifyContent="space-between" alignItems="center">
                              <Text variation="secondary" as="span">
                                {`${item.question}`}
                              </Text>
                            </Flex>
                            <Flex justifyContent="space-between" alignItems="center">
                              <Badge variation="info">{`${formatDateISO(item.datetime)}`}</Badge> <small>{`${item.rfpFile?.name ? item.rfpFile.name : ""}`}</small>
                            </Flex>
                          </Flex>
                          <Accordion.Icon />
                        </Accordion.Trigger>
                        <Accordion.Content>
                          < RfpItemLayout
                            key={index}
                            overrides={
                              {
                                RfpItemLayout: {
                                  width: "100%"
                                },
                                ButtonFile: {
                                  children: item.rfpFile?.name ? item.rfpFile.name : "File",
                                  onClick: () => { navigate(`/documents/${item.rfpFileId}`); },
                                },
                                Question: { children: item.question },
                                Date: { children: formatDateISO(item.datetime) },
                                TextLayout: { children: (<View>{typeof item.answer === 'string' ? parse(item.answer) : ''}</View>) },
                                ButtonCopy: { // Copy to clipboard
                                  onClick: () => copyToClipboard(item.question + "\n" + item.answer, snackbar, alert)
                                },
                                ButtonEdit: { // Edit
                                  onClick: () => editQuestionConfirmation(item.id, item.question, item.answer), isLoading: isLoading
                                },
                                ButtonRemove: { // Update Knowledge base
                                  children: item.isExcluded ? "Add to Search" : "Remove from Search",
                                  onClick: () => removeQuestionConfirmation(item.id, item.isExcluded), isLoading: isLoading
                                },
                                // DELETE
                                ButtonDelete: { onClick: () => deleteQuestionConfirmation(item.id), isLoading: isLoading },

                                "StatusBadge": {
                                  "children": getIsExcludedText(item.isExcluded),
                                  "variation": getIsExcludedVariation(item.isExcluded)
                                },
                                // CHECK SIMILARITY
                                "FrameSimilarity": { children: getSimilarityFrame(item.id) },
                              }
                            }
                          />
                        </Accordion.Content>
                      </Accordion.Item>
                    ))}
                  </Accordion.Container>
                </Flex>
              </Card>
            </Flex>
          </Card>
          }
        </Flex>
      </Flex>
    </Page>
  );
}