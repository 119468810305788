import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAlert } from '../hooks/useAlert';
import { formatFileSize, formatNbQuestions, formatDate, customReactModalStyles } from '../utils';
// UI
import { Flex, useBreakpointValue, BadgeVariations, Breadcrumbs, Menu, MenuButton, MenuItem } from '@aws-amplify/ui-react';
import { Collection, Card, Heading, Text, Button, Placeholder } from '@aws-amplify/ui-react';
// components
import Page from '../components/Page';
import { StorageManager } from '@aws-amplify/ui-react-storage';
import Modal from 'react-modal';
import { studioTheme } from '../ui-components';
import { ThemeProvider } from '@aws-amplify/ui-react';
import FileListRow from '../ui-components/FileListRow';
import MyIcon from '../ui-components/MyIcon';
// GraphQL imports
import { generateClient } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';

import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/subscriptions';
import { GraphQLQuery, GraphQLSubscription } from '@aws-amplify/api';
import { ListRfpToAnswerFilesQuery, DeleteRfpDocumentToAnswerQuery, ResponsePresignedGetQuery, ListStrategiesQuery, OnCreateRfpToAnswerFileSubscription, OnUpdateRfpToAnswerFileSubscription, ProcessRfpDocumentToAnswerQuery } from '../API';


export default function Batch() {
  const snackbar = useAlert({ variation: 'success' });
  const navigate = useNavigate();
  const [files, setFiles] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>("");
  const [selectedStrategy, setSelectedStrategy] = useState<string>("");
  const [listStrategy, setListStrategy] = useState<any>(null);

  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
  }) as 'small' | 'medium' | undefined;

  const [modalConfirmatonIsOpen, setModalConfirmatonIsOpen] = useState(false);
  const [modalAddDocIsOpen, setModalAddDocIsOpen] = useState(false);
  const [addFileIsLoading, setAddFileIsLoading] = useState(false);
  const [uploads, setUploads] = useState<any>({});

  const API = generateClient();

  useEffect(() => {
    async function fetchFiles() {
      //console.log("fetching files")
      try {
        // Fetch data from the amplify graphql API table RfpFile
        const allFiles = await API.graphql<GraphQLQuery<ListRfpToAnswerFilesQuery>>(
          { query: queries.listRfpToAnswerFiles }
        );
        setFiles(allFiles.data?.listRfpToAnswerFiles?.items);

        // Fetch data from the amplify graphql API table Strategy
        const allStrategy = await API.graphql<GraphQLQuery<ListStrategiesQuery>>(
          { query: queries.listStrategies }
        );
        setListStrategy(allStrategy.data?.listStrategies?.items)

        // Subscribe to creation of File
        API.graphql<GraphQLSubscription<OnCreateRfpToAnswerFileSubscription>>(
          { query: subscriptions.onCreateRfpToAnswerFile }
        ).subscribe({
          next: ({ data }) => refreshFiles(),
          error: (error) => console.warn(error)
        });

        // Subscribe to update of File
        API.graphql<GraphQLSubscription<OnUpdateRfpToAnswerFileSubscription>>(
          { query: subscriptions.onUpdateRfpToAnswerFile }
        ).subscribe({
          next: ({ data }) => refreshFiles(),
          error: (error) => console.warn(error)
        });


      } catch (error) {
        console.error('Error fetching document data:', error);
      }
    }

    fetchFiles();
  }, []);


  async function refreshFiles() {
    try {
      // Fetch data from the amplify graphql API table RfpFile
      const allFiles = await API.graphql<GraphQLQuery<ListRfpToAnswerFilesQuery>>(
        { query: queries.listRfpToAnswerFiles }
      );
      setFiles(allFiles.data?.listRfpToAnswerFiles?.items);
    } catch (error) {
      console.error('Error fetching document data:', error);
    }
  }


  function navigateToFile(filePath: string, status: string): void {
    if (status === "PROCESSED") {
      navigate(`/batch/${filePath}`);
      return;
    }
    if (status === "EXTRACTED") {
      navigate(`/batch-extract/${filePath}`);
      return;
    }
    navigate(`/batch/${filePath}`);

  }


  function deleteFileConfirmation(rfpId: string) {
    setDeleteId(rfpId);
    setModalConfirmatonIsOpen(true);
  }


  async function deleteFile() {
    if (deleteId === "") {
      return;
    }
    setIsLoading(true);
    await API.graphql<GraphQLQuery<DeleteRfpDocumentToAnswerQuery>>(
      { query: queries.deleteRfpDocumentToAnswer, variables: { rfpId: deleteId } }
    );
    // Fetch data from the amplify graphql API table RfpFile
    const allFiles = await API.graphql<GraphQLQuery<ListRfpToAnswerFilesQuery>>(
      { query: queries.listRfpToAnswerFiles }
    );
    setFiles(allFiles.data?.listRfpToAnswerFiles?.items);
    setIsLoading(false);
  }


  //#######################################################################################################################
  //## MODAL CONFIRMATION
  //#######################################################################################################################
  function closeModalConfirmation() {
    setModalConfirmatonIsOpen(false);
  }
  function getModalConfirmation(modalBool: boolean, callBackFunction: Function) {
    Modal.setAppElement('#root');
    return (
      <Modal
        isOpen={modalBool}
        style={customReactModalStyles}
      >
        <ThemeProvider theme={studioTheme}>
          <Flex direction={"column"} gap="20px">
            <Heading >Are you sure you want to continue?</Heading>
            <Flex direction={"row"} alignItems="space-between">
              <Button onClick={() => { callBackFunction(); closeModalConfirmation(); }}>Yes</Button><Button onClick={() => closeModalConfirmation()}>No</Button>
            </Flex>
          </Flex>
        </ThemeProvider>
      </Modal>);
  }

  //#######################################################################################################################
  //## MODAL Add/Update Strategy
  //#######################################################################################################################
  async function handleNewDocSubmit() {
    try {
      console.log("handleNewDocSubmit");
      if (Object.keys(uploads)[0] === null || Object.keys(uploads)[0] === undefined) {
        console.log("No file uploaded");
        return;
      }
      const user = await fetchAuthSession();
      await API.graphql<GraphQLQuery<ProcessRfpDocumentToAnswerQuery>>(
        { query: queries.processRfpDocumentToAnswer, variables: { key: `${user.identityId}/${Object.keys(uploads)[0]}` } }
      );
      refreshFiles();
      setModalAddDocIsOpen(false);
      snackbar.showAlert('Document currently being processed. List refreshed automatically.', 'success');
    } catch (error) {
      console.error('Error submitting document data:', error);
      snackbar.showAlert(`Error submitting document: ${error}`, 'error');
    }
  }

  // function handleSelectChange(event: React.ChangeEvent<HTMLSelectElement>) {
  //   setSelectedStrategy(event.target.value);
  // }


  function getModalAddDoc() {
    Modal.setAppElement('#root');
    return (
      <Modal
        isOpen={modalAddDocIsOpen}
        style={customReactModalStyles}
      >
        <ThemeProvider theme={studioTheme}>
          <Flex as="form" direction={{ base: 'column', medium: 'column' }} >
            <Heading level={4}>Add a new RFP</Heading>
            <Flex direction={{ base: 'column' }} >
              <Text>
                Only upload word documents (.docx). The document will be processed and the questions extracted.
              </Text>
            </Flex>
            {/* <SelectField
                isRequired={true}
                label="Select a Category"
                value={selectedStrategy}
                onChange={handleSelectChange}
              >
                {listStrategy?.map((strategy: Strategy, index: number) => (
                  <option key={index} value={strategy.id}>{strategy.name}</option>
                ))}
            </SelectField> */}
            <StorageManager
              acceptedFileTypes={['.pdf', '.docx']}
              accessLevel="protected"
              maxFileCount={1}
              onUploadError={(error, { key }) => {
                console.log("error upload:" + error);
              }}
              onUploadSuccess={({ key }) => {
                if (key === null || key === undefined) {
                  return;
                }
                setUploads((prevFiles: any) => {
                  return {
                    ...prevFiles,
                    [key]: {
                      status: 'success',
                    }
                  }
                });
              }}
            />

            <Flex direction={"row"} justifyContent="flex-end">
              <Button isLoading={addFileIsLoading} isDisabled={addFileIsLoading} variation="primary" onClick={() => { setAddFileIsLoading(true); handleNewDocSubmit(); setAddFileIsLoading(false); }}>Process File</Button>
              <Button isDisabled={addFileIsLoading} variation="link" onClick={() => setModalAddDocIsOpen(false)}>cancel</Button>
            </Flex>
          </Flex>

        </ThemeProvider>
      </Modal>);
  }

  function getStatusVariation(status: string): BadgeVariations {
    switch (status) {
      case "PROCESSING":
        return "warning";
      case "PROCESSED":
        return "success";
      case "EXTRACTED":
        return "success";
      default:
        return "warning";
    }
  }

  async function DownloadFile(s3Key: string | undefined | null) {
    if (s3Key === null || s3Key === undefined) {
      return;
    }
    const link = await API.graphql<GraphQLQuery<ResponsePresignedGetQuery>>(
      { query: queries.responsePresignedGet, variables: { objectName: s3Key } }
    );
    if (link?.data?.responsePresignedGet !== null && link?.data?.responsePresignedGet !== undefined) {
      window.location.href = link?.data?.responsePresignedGet;
    }
  }


  return (
    <Page title="Batch">
      <snackbar.AlertComponent />
      {getModalConfirmation(modalConfirmatonIsOpen, deleteFile)}
      {getModalAddDoc()}
      <Flex gap="20px" direction={"column"}>
        <Breadcrumbs
          items={[
            {
              href: '/',
              label: 'Home',
            },
            {
              href: '/batch',
              label: 'batch',
            }
          ]}
        />
        <Card>
          <Flex direction="column" alignItems="flex-start">
            <Heading level={4}>Batch Generation</Heading>
            <Text
              variation="primary"
              as="p"
            >
              Upload a RFP document that needs to be answered. Once uploaded the algorithm will extract the questions and generate answers.
            </Text>
            <Flex direction="row" alignItems="flex-start">
              <Button isLoading={isLoading} isDisabled={isLoading} variation="primary" onClick={() => { setUploads({}); setModalAddDocIsOpen(true) }}>Upload Doc</Button>
            </Flex>
          </Flex>
        </Card>
        <Flex justifyContent="center" alignItems="center" width="100%">
          <Card width={"100%"}>
            {files !== null ? (
              <Collection
                items={files}
                type="list"
                direction="column"
                gap="7px"
                wrap="wrap"
                isPaginated
                itemsPerPage={12}
                isSearchable
                searchPlaceholder="Type to search..."
                searchFilter={(regions, keyword) =>
                  (regions as any).name.toLowerCase().includes(keyword.toLowerCase())
                }
              >
                {(item: any, index: number) => (
                  < FileListRow
                    key={index}
                    breakpoint={breakpointHook}
                    overrides={
                      {
                        FileListRow: {
                          width: "100%"
                        },
                        "FrameFile": { "minWidth": "50%" },
                        "FileText": { "children": `${item.name}`, "onClick": () => navigateToFile(item.id, item.status) },
                        "TypeText": { "children": item.type },
                        "StatusBadge": { "children": item.status, "variation": getStatusVariation(item.status) },
                        "SizeText": { "children": formatFileSize(item.size) },
                        "NbQuestionsText": { "children": formatNbQuestions(item.nbQuestions) },
                        "UpdatedText": { "children": formatDate(item.updatedAt) },
                        "FrameButton": {
                          children: (
                            <Menu
                              trigger={
                                <MenuButton variation="link" size="small" padding={0}>
                                  <MyIcon type={"more_vert"} />
                                </MenuButton>
                              }
                            >
                              <MenuItem isDisabled={isLoading} onClick={() => navigateToFile(item.id, item.status)}><MyIcon type={"edit"} overrides={{ MyIcon: { color: "white" } }} />View/Edit</MenuItem>
                              <MenuItem isDisabled={isLoading} onClick={() => deleteFileConfirmation(item.id)}><MyIcon type={"delete"} />Delete</MenuItem>
                            </Menu>
                          )
                        },
                        "DownloadIcon": { className: "custom-btn", "onClick": () => DownloadFile(item.s3Key) }
                      }
                    } />
                )}
              </Collection>
            ) : (
              <Placeholder size="large" height={"200px"} />
            )}
          </Card>
        </Flex>
      </Flex >
    </Page >
  );
}
