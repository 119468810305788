import { useEffect, useState } from 'react';
import { Flex, Card, Text, Heading, Breadcrumbs, Accordion, Placeholder } from '@aws-amplify/ui-react';
import Page from '../components/Page';
// GraphQL imports
import { generateClient } from 'aws-amplify/api';

import * as queries from '../graphql/queries';
import { GraphQLQuery } from '@aws-amplify/api';
import { ListFaqItemsQuery } from '../API';
import parse from 'html-react-parser';

export default function Help() {

  const [FaqItems, setFaqItems] = useState<any>(null);
  const API = generateClient();

  useEffect(() => {
    async function fetchFiles() {
      try {
        // Fetch data from the amplify graphql API table RfpFile
        const faq = await API.graphql<GraphQLQuery<ListFaqItemsQuery>>(
          { query: queries.listFaqItems }
        );
        setFaqItems(faq.data?.listFaqItems?.items);
      } catch (error) {
        console.error('Error fetching FAQ:', error);
      }
    }

    fetchFiles();
  }, []);


  return (
    <Page title="Help">
      <Flex gap="20px" direction={"column"}>
        <Breadcrumbs
          items={[
            {
              href: '/',
              label: 'Home',
            },
            {
              href: '/help',
              label: 'help',
            }
          ]}
        />
        <Card>
          <Flex direction="column" alignItems="flex-start">
            <Heading level={4}>FAQ List</Heading>
            <Text
              variation="primary"
              as="p"
              lineHeight="1.5em"
              fontWeight={400}
              fontSize="1em"
              fontStyle="normal"
              textDecoration="none"
            >
              Find below the list of most frequently asked questions.
            </Text>
          </Flex>
        </Card>
        <Flex justifyContent="center" alignItems="center" width="100%">
          {FaqItems !== null ? (
            <Card width={"100%"}>
              <Flex direction="column" justifyContent="space-between" alignItems="left">
                {FaqItems && FaqItems.length > 0 ? (
                  <Accordion
                    items={
                      FaqItems.map((item: any, index: number) => (
                        {
                          trigger: item.question,
                          value: "exp" + index,
                          content: (<Text variation="secondary"
                            as="p"
                            lineHeight="1.5em"
                            fontWeight={400}
                            fontSize="1em"
                            fontStyle="normal"
                            textDecoration="none"
                          >
                            {parse(item.answer)}
                          </Text>)
                        }
                      ))
                    } />
                ) : (
                  <div>
                    No FAQ items found.
                  </div>
                )}
              </Flex>
            </Card>
          ) : (
            <Card width={"100%"}>
              <Placeholder size="large" height={"200px"} />
            </Card>
          )}
        </Flex>
      </Flex>
    </Page>
  );
}