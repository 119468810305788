import React from 'react';
import { View } from '@aws-amplify/ui-react';
import { Helmet } from 'react-helmet';
import { forwardRef } from 'react';

interface PageProps {
  children?: React.ReactNode;
  title?: string;
}

const Page = forwardRef<HTMLDivElement, PageProps>(({ children, title = '', ...other }, ref) => (
  <View ref={ref} {...other}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {children}
  </View>
));

export default Page;