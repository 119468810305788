// UI Framework
import { Flex } from '@aws-amplify/ui-react';
import Page from '../components/Page';
import HomeCards from '../ui-components/HomeCards';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const navigate = useNavigate();

  return (
    <Page title="Home">
      <Flex justifyContent="center" alignItems="center">
        <HomeCards overrides={{
          // "Frame 63": { direction: { large: 'row', base: 'column' } },
          Button38692268: { onClick: () => navigate("/documents") },
          Button38692304: { onClick: () => navigate("/generate") }
        }} />
      </Flex>
    </Page>
  );
}