import { ThemeProvider, Authenticator, Flex, Heading, View, Image, Text, useTheme} from '@aws-amplify/ui-react';
import {studioTheme} from './ui-components';

// routes
import Router from './routes';

import { Amplify } from 'aws-amplify'
import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="RFP logo"
          src="/android-chrome-192x192.png"
        />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; All Rights Reserved - Charles Malafosse 2023
        </Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Sign in 
        </Heading>
      );
    }
  }
};

export default function App() {
  return (
    <ThemeProvider theme={studioTheme}>
      <Flex
        height="100vh"
        alignItems="center"
        justifyContent="center"
      >    
        <Authenticator hideSignUp={true} components={components}>
          <Router />  
        </Authenticator>    
      </Flex>
    </ThemeProvider>

  );
}


// function App() {
//   return (
//     <Flex
//       direction={{ base: 'column', large: 'row' }}
//       maxWidth="32rem"
//       padding="1rem"
//       width="100%"
//     >
//       <ScrollToTop />
//       <Router />      
//     </Flex>
//   );
// }

// export default withAuthenticator(App);
