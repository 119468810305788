/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Image, Text, View } from "@aws-amplify/ui-react";
export default function NotFoundCard(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="998px"
      height="605px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "NotFoundCard")}
      {...rest}
    >
      <View
        padding="0px 0px 0px 0px"
        width="253px"
        height="59px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="397px"
        left="134px"
        {...getOverrideProps(overrides, "Button38802522")}
      >
        <Text
          fontFamily="Roboto"
          fontSize="24px"
          fontWeight="400"
          color="rgba(255,255,255,1)"
          lineHeight="28.125px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="96.36px"
          height="26.65px"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="0px"
          left="1.7px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Return to Home page"
          {...getOverrideProps(overrides, "Text")}
        ></Text>
        <Button
          width="253px"
          height="43.77px"
          position="absolute"
          top="15.23px"
          left="0px"
          size="large"
          isDisabled={false}
          variation="primary"
          children="Return to Homepage"
          {...getOverrideProps(overrides, "Button38802528")}
        ></Button>
      </View>
      <Text
        fontFamily="Inter"
        fontSize="48px"
        fontWeight="700"
        color="rgba(0,0,0,1)"
        lineHeight="58.09090805053711px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="137px"
        left="72px"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Uh oh, Page not&#xA;found!"
        {...getOverrideProps(overrides, "Uh oh, Page not found!")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="24px"
        fontWeight="400"
        color="rgba(191,64,64,1)"
        lineHeight="36px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="377px"
        height="unset"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="264px"
        left="72px"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Don’t panic, it must be somewhere&#xA;else."
        {...getOverrideProps(
          overrides,
          "Don\u2019t panic, it must be somewhere else."
        )}
      ></Text>
      <Image
        width="498px"
        height="456px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="84px"
        left="468px"
        borderRadius="25px"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        {...getOverrideProps(overrides, "Illustration")}
      ></Image>
    </View>
  );
}
